export enum FilterType {
	AnniversaryDate,
	Date,
	Option,
	String,
	Number,
	Boolean,
	Subscription,
	Location
}
