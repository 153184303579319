export enum PermissionsTypes {
	ReadRates = 'read:rates',
	EditRates = 'edit:rates',
	ReadMarketing = 'read:marketing',
	EditMarketing = 'edit:marketing',
	ReadUsers = 'read:users',
	EditUsers = 'edit:users',
	ReadFinancials = 'read:financials',
	EditSettings = 'edit:settings',
	ReadSettings = 'read:settings',
	ReadPickup = 'read:pickup',
	EditBudgets = 'edit:budgets',
	ReadMessages = 'read:messages',
	EditMessages = 'edit:messages',
	ReadVoice = 'read:voice',
	EditVoice = 'edit:voice',
	ApproveVoice = "approve:voice"
}
