import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
  lang: "es",
  data: {
    TRANSLATOR: {
      SELECT: "Seleccione un idioma"
    },
    USER_PROFILE: {
      SETTINGS: "Configuración",
      HOTEL_OPTIONS: "Opciones y configuración del hotel",
      HELP: "Ayuda y soporte",
      HELP_GUID: "Complete la guía de ayuda y soporte",
      THEME: "Tema",
      LANGUAGE: "Idioma",
      SIGN_OUT: "Cerrar sesión",
      USERS: "Usuarios",
      MANAGE_USERS: "Gestione los usuarios y permisos del hotel"
    },
    CONNECTS: {
      MENU: {
        OVERVIEW: "Información general",
        GUESTS_SEGMENTS: "Huéspedes y segmentos",
        GUESTS: "Huéspedes",
        SEGMENTS: "Segmentos",
        LISTS: "Listas",
        CAMPAIGNS: "Campañas",
        CAMPAIGN_LIST: "Lista de campañas",
        TEMPLATES: "Plantillas",
        REPORTS: "Informes"
      },
      OVERVIEW: {
        TOTAL: "Número de perfiles",
        REACHABLE: "Perfiles disponibles",
        UNREACHABLE: "Perfiles no disponibles",
        EMAIL_COVERAGE: "Cobertura de correos",
        CAMPAIGN: "Ingresos por habitación de la campaña",
        YEAR: "Año a la fecha",
        ONE_TIME: "Ingresos por campaña única",
        LAST: "Últimos 5 ingresos",
        ALL: "Ver todos",
        LIFETIME: "Todos los ingresos",
        REVENUE: "Total de ingresos",
        ARRIVALS: "Llegadas",
        NUMBER_OF_GUESTS: "Número de huéspedes",
        ROOM_REVENUE: "Ingresos por habitación",
        NAME: "Nombre",
        NIGHTS: "Noches",
        LIFETIME_VALUE: "Valor de por vida",
        EMAIL: "Correo electrónico",
        DEPARTURES: "Salidas"
      },
      GUESTS: {
        ALL_GUESTS: "Todos los huéspedes",
        ARRIVALS: "Llegadas",
        HOUSE: "Hospedados",
        DEPARTURES: "Salidas",
        SEARCH_PLACEHOLDER: "Buscar por nombre, correo o referencia",
        DATE_PLACEHOLDER: "Seleccione las fechas",
        FILTERS: "Filtros",
        CHANGE_HOTEL: "Cambiar hotel",
        SEARCH: "Buscar",
        CLEAR: "Borrar",
        GUEST: "Huésped",
        NIGHTS: "Noches",
        ROOM_TYPE: "Tipo de habitación",
        REVENUE: "Ingresos",
        BOOKING: "Canal de reserva",
        RATE: "Código de la tarifa",
        EMAIL: "Correo electrónico",
        PREVIOUS: "Estadías anteriores",
        LIFETIME: "Valor de por vida",
        NOTES: "Notas",
        NO_RESULTS: "No se encontraron resultados",
        ITEMS: "Conceptos por página:"
      },
      SEGMENTS: {
        SEARCH_SEGMENTS: "Buscar por nombre de segmento",
        CREATE_SEGMENT: "Crear segmento de huéspedes",
        EDIT_SEGMENT: "Editar segmento de huéspedes",
        REFRESH: "Actualizar ahora",
        STATISTICS: "Última actualización de estadísticas",
        NO_RESULTS: "No se encontraron resultados",
        REACHABLE: "Contactar por correo",
        PROFILES: "Perfiles",
        AVARAGE_NIGHTS: "Noches en promedio",
        AVARAGE_RATE: "Tarifa media diaria",
        OR: "O",
        EXCLUDE_BOOKINGS: "Excluir las reservas con estadías futuras",
        VIEW_DETAILS: "Ver detalles",
        SELECT: "Seleccionar",
        PEOPLE_IN_SEGMENT: "personas en este segmento",
        TOTAL_LIFETIME: "gasto total de por vida",
        SEARCH_PLACEHOLDER: "Buscar nombre, correo o referencia",
        SEARCH: "Buscar",
        CLEAR: "Borrar",
        GUEST: "Huésped",
        EMAIL: "Correo electrónico",
        LIFETIME_VALUE: "Valor de por vida",
        LIFETIME_STAYS: "Estadías de por vida",
        TOTAL_NIGHTS: "Total de noches",
        CLOSE: "Cerrar",
        ITEMS: "Conceptos por página:",
        SEGMENT_NAME: "Nombre del segmento",
        SEGMENT_REQUIRED: "Nombre del segmento es obligatorio",
        CONTACT_LISTS: "Listas de contacto",
        ENTER_CONTACT: "Ingrese la lista de contacto...",
        PROFILES_SEGMENT: "Perfiles en este segmento",
        TOTAL_LIFETIME_UPPER: "Gasto total de por vida",
        REFRESH_STATICITCS: "Actualizar estadísticas",
        FIND_GUESTS: "Encontrar huéspedes que coinciden con estas condiciones",
        AND: "Y",
        SEARCH_VALUE: "Ingrese el valor de búsqueda",
        ENTER_DISTANCE: "Ingrese la distancia",
        IMPLICIT_CONSENT: "Consentimiento implícito (seguro)",
        EXPLICIT_CONSENT: "Consentimiento explícito (más seguro)",
        DOUBLE_CONSENT: "Consentimiento por suscripción doble (el más seguro)",
        ENTER_DATE: "Ingrese las fechas",
        START_DATE: "Fecha inicial",
        END_DATE: "Fecha final",
        START_DAYS: "Días iniciales",
        END_DAYS: "Días finales",
        LAST_DAYS: "Últimos días",
        EXCLUDE_GUESTS: "Excluir huéspedes con estadías futuras",
        CORRECT_FILTERS: "Corrija los errores en los filtros del segmento",
        CANCEL: "Cancelar",
        SAVE: "Guardar",
        BETWEEN_DATES: "entre las fechas",
        BETWEEN_FUTURE: "entre los días futuros",
        DAYS: "días",
        BETWEEN_PAST: "entre los días anteriores",
        LAST: "en los últimos",
        EQUAL: "igual a",
        NOT_EQUAL: "no igual a",
        GREATER: "mayor que",
        LESS: "menor que",
        KM: 'km',
        CONTAINS: "incluye",
        NOT_CONTAINS: "no incluye",
        IS_EQUAL: "es igual a",
        IS_NOT_EQUAL: "no es igual a",
        LEAST: "tiene al menos",
        ANNIVERSARY: "Aniversario",
        BADGE: "Insignia",
        BIRTHDAY: "Cumpleaños",
        COMPANY: "Compañía",
        EMAIL_ADDRESS: "Correo electrónico",
        GENDER: "Género",
        LANGUAGE: "Idioma",
        LIFETIME_ROOM: "Gastos por habitación de por vida",
        LOCATION: "Ubicación",
        TOTAL_NUMBER_NIGHTS: "Total # de noches",
        TOTAL_NUMBER_STAYS: "Total # de estadías",
        SUBSCRIPTION: "Estado de la suscripción",
        BOOKING: "Canal de reserva",
        BOOKING_DATE: "Fecha de la reserva",
        BOOKING_LEAD: "Plazo de entrega de la reserva",
        CHECK_IN_DATE: "Fecha de llegada",
        CHECK_OUT_DATE: "Fecha de salida",
        LENGTH: "Duración de la estadía",
        MARKET: "Código del mercado",
        RATE: "Código de la tarifa",
        ROOM: "Código de la habitación",
        RATE_AVARAGE: "Promedio de la tarifa",
        SOURCE: "Fuente de negocio",
        STAY: "Fecha de la estadía",
        DEMOGRAPHICS: "Grupo demográfico",
        MARKETING: "Marketing por correo",
        STAY_DATA: "Datos de estadía",
        IMPORT_GUESTS: "Importar huéspedes",
        ADD_LIST: "Añadir lista",
        HOTEL_NAME: "Nombre del hotel",
        GROUPWIDE: "En todos los grupos",
        NAME: "Nombre",
        GUESTS: "Huéspedes",
        EDIT_LIST: "Editar lista",
        UPDATE_LIST: "Actualizar lista",
        LIST_NAME: "Nombre de la lista",
        ROOM_REQUIRED: "Nombre del tipo de habitación es obligatorio",
        SELECT_HOTEL: "Seleccionar hotel",
        SELECT_DATA: "Seleccionar datos",
        SELECT_LIST: "Seleccionar lista",
        PLEASE_SELECT_LIST: "Seleccione una lista",
        SUBSCRIPTION_STATUS: "Estado de la suscripción",
        UNSUBSCRIBED: "No está suscrito",
        IMPLICIT: "Implícito",
        EXPLICIT: "Explícito",
        DOUBLE_OPT_IN: "Consentimiento doble",
        PLEASE_SELECT_SUBSCRIPTION: "Seleccione un estado de suscripción",
        PLEASE_UPLOAD_FILE: "Cargue un archivo",
        DOWNLOAD_EXAMPLE: "Descargar archivo .csv de muestra",
        NEXT: "Siguiente",
        COMPLETE_MAPPINGS: "Complete los mapeados",
        BACK: "Volver",
        IMPORT: "Importar",
        ERROR_UPLOADING: "Lo sentimos. Ha habido un error al cargar los huéspedes",
        UPLOAD_FILE: "Cargar archivo",
        NO_FILE: "No ha seleccionado un archivo"
      },
      GUESTS_PROFILE: {
        LEAD_TIME: "Tiempo de respuesta",
        LIFETIME_STAYS: "Estadías de por vida",
        LIFETIME_NIGHTS: "Noches de por vida",
        LIFETIME_SPEND: "Gasto de por vida",
        STATUS: "Estado",
        HOUSE: "Hospedado",
        LAST_STAY: "Últimado estadía",
        NEXT_STAY: "Próxima estadía",
        NIGHTS: "Noches",
        TODAY: "Hoy",
        PERSONAL: "Información personal",
        TITLE: "Título",
        FIRST_NAME: "Nombres",
        LAST_NAME: "Apellidos",
        GENDER: "Género",
        LANGUAGE: "Idioma",
        COMPANY: "Compañía",
        JOB_TITLE: "Título laboral",
        ADDRESS_1: "Dirección",
        ADDRESS_2: "Dirección 2",
        CITY_COUNTY: "Ciudad, Estado",
        POST_CODE: "Código postal",
        COUNTRY: "País",
        OTHER: "Otros",
        BIRTHDAY: "Cumpleaños",
        ANNIVERSARY: "Aniversario",
        PROFILE_NOTES: "Notas del perfil",
        EMAIL: "Correo electrónico",
        SUBSCRIPTION_STATUS: "Estado de suscripción",
        BADGES: "Insignias",
        CONTACT_LISTS: "Listas de contacto",
        BOOKING_NOTES: "Notas de la reserva",
        LOYALTY: "Fidelización",
        FIRST_STAY: "Primera estadía con grupo",
        LIFETIME_VALID_STAYS: "Estadías válidas de por vida",
        LIFETIME_CANCELLED: "Estadías canceladas de por vida",
        LIFETIME_VALID_NIGHTS: "Noches válidas de por vida",
        CURRENT_STAY: "Estadía actual",
        NO_STAYS: "No hay futuras estadías",
        CHECK_IN: "Llegada",
        CHECK_OUT: "Salida",
        OF_NIGHTS: "# de noches",
        BOOKING_REF: "Referencia de reserva",
        HOTEL: 'Hotel',
        ROOM_TYPE: "Tipo de habitación",
        RATE: "Tarifa",
        CHANNEL: "Canal",
        GUESTS: "Huéspedes",
        ROOM: "Habitación",
        BOOKING_DATE: "Fecha de la reserva",
        NIGTH: "noche",
        ALL_STAYS: "Todas las estadías",
        CANCELLED: "Cancelada",
        IN_HOUSE: "Hospedado",
        GUEST_MARKETING: "Marketing para huéspedes",
        DATE_SENT: "Fecha de envío",
        EVENT_DATE: "Fecha del evento",
        CAMPAIGN: "Campaña",
        PENDING_REQUESTS: "Solicitudes pendientes",
        DATE: "Fecha",
        READ_ON: "Leído en",
        REQUESTS: "Solicitudes",
        TYPE: "Tipo",
        READ: "Leído",
        UNREAD: "No leído",
        ADD_BADGE: "Añadir insignia",
        BADGE: "Insignia",
        CANCEL: "Cancelar",
        SAVE: "Guardar",
        GUEST_EMAIL: "Correo del huésped",
        ENTER_EMAIL: "Escriba la nueva dirección de correo de este perfil.",
        NOTE_PROFILE: "Recuerde que pueden pasar varios minutos antes de que se actualice el nuevo correo en el perfil del huésped",
        EMAIL_REQUIRED: "Correo es obligatorio.",
        INVALID_EMAIL: "Dirección de correo no válida.",
        EDIT_STATUS: "Editar estado de suscripción",
        PLEASE_CONFIRM: "Confirme el cambio en el estado de suscripción del perfil de este huésped",
        UNSUBSCRIBE: "Cancelar suscripción",
        NOTE_UNDO: "Nota: No puede deshacer esta acción. Sólo el huésped puede volver a suscribirse.",
        PROFILE: "Perfil",
        STAYS: "Estadías",
        ENGAGEMENT: "Interacciones",
        IMPLICIT: "Consentimiento implícito",
        EXPLICIT: "Consentimiento explícito",
        DOUBLE: "Consentimiento doble",
        UNSUBSCRIBED: "No está suscrito"
      },
      REPORTS: {
        ONE_TIME: "Una sola vez",
        STATS: "Estadísticas",
        REVENUE_DETAIL: "Datos de ingresos",
        AUTOMATED: "Automático",
        SEARCH_CAMPAIGN_NAME: "Buscar por nombre de campaña",
        SEARCH: "Buscar",
        CLEAR: "Borrar",
        CAMPAIGN_NAME: "Nombre de campaña",
        BOOKINGS: "Reservas",
        SENT: "Enviado",
        OPENED: "Abierto",
        CLICKED: "Hecho clic",
        LAST_MODIFIED: "Última modificación",
        REPORT: "Informe",
        PREVIEW: "Vista previa",
        NO_RESULTS: "No se encontraron resultados"
      },
      CREDITS: "Créditos de envío de correos",
      SUB_HEADER: {
        QUIT: "Salir",
        SAVE_QUIT: "Guardar y salir",
        SEND_TEST: "Enviar una prueba",
        SCHEDULE: "Programar",
        ACTIVATE: "Activar",
        CANCEL_SCHEDULE: "Cancelar programación",
        DEACTIVATE: "Desactivar",
        NEXT_STEP: "Siguiente paso"
      },
      CAMPAIGNS: {
        REVENUE: "Ingresos",
        SEARCH_PLACEHOLDER: "Buscar por nombre, correo o referencia",
        RESERVATIONS: "Reservas",
        SENT: "Enviado",
        OPENED: "Abierto",
        CLICKED: "Clics",
        DELIVERED: "Entregado",
        BOUNCES: "Rebotes",
        UNSUBSCRIBE: "Suscripción cancelada",
        SPAM_REPORTS: "Informes de spam",
        DELIVERY_REPORT: "Informe de entrega",
        DOWNLOAD_REPORT: "Descargar informe",
        DISPATCHED: "Enviado",
        GUEST: "Huésped",
        EMAIL: "Correo electrónico",
        STATUS: "Estado",
        NO_RESULTS: "No se encontraron resultados",
        ROOM_NIGHTS: "Noches de estadía",
        OPENS: "Abiertos",
        CLICKS: "Clics",
        TOP_CHANNELS: "Mejores canales",
        TIME_SINCE_LAST_STAY: "Tiempo desde última estadía",
        TOP_RATE_CODE_BY_CHANNEL: "Mejor código de tarifa por canal",
        RATE_CODE: "Código de tarifa",
        TOTAL: 'Total',
        BOOKING_LEAD_TIMES: "Plazos de entrega de reserva",
        RESERVATION_REPORT: "Informe de reservas",
        BOOK_DATE: "Fecha de reserva",
        SEND_DATE: "Fecha de envío",
        NIGHTS: "Noches",
        BOOKING_CHANNEL: "Canal de reserva",
        TOTAL_RATE: "Total de tarifa",
        REPORTS: "Informe de la campaña",
        ONE_TIME: "Una sola vez",
        AUTOMATED: "Automática",
        CREATE_CAMPAIGN: "Crear campaña",
        CREATE: "Crear",
        SEARCH_CAMPAIGN: "Buscar por nombre de campaña",
        SEARCH: "Buscar",
        CLEAR: "Borrar",
        ALL_STATUSES: "Todos los estados",
        DRAFT: "Borrador",
        SCHEDULED: "Programada",
        INACTIVE: "Inactiva",
        ACTIVE: "Activa",
        CAMPAIGN_NAME: "Nombre de la campaña",
        TYPE: "Tipo",
        DELIVERY: "Entrega",
        LAST_UPDATED: "Última actualización",
        REPORT: "Informe",
        CANCEL_SCHEDULE: "Cancelar programación",
        DEACTIVATE: "Desactivar",
        ACTIVATE: 'Activate',
        VIEW: "Ver",
        DUPLICATE: "Duplicar",
        DELETE: "Borrado",
        DELIVERING: "En entrega",
        SUSPENDED: "Suspendido",
        REMOVE_CAMPAIGN: "Borrar campaña",
        DO_YOU_REMOVE_CAMPAIGN: "¿Está seguro(a) que quiere borrar la campaña?",
        CANCEL_CAMPAIGN: "Cancelar campaña",
        DO_YOU_CANCEL_CAMPAIGN: "¿Está seguro(a) que quiere cancelar la campaña?",
        DEACTIVATE_CAMPAIGN: "Desactivar campaña",
        DO_YOU_DEACTIVATE_CAMPAIGN: "¿Está seguro(a) que quiere desactivar la campaña?",
        ACTIVATE_CAMPAIGN: "Activar campaña",
        DO_YOU_ACTIVATE_CAMPAIGN: "¿Está seguro(a) que quiere activar la campaña?",
        SETUP: "Configuración",
        DESIGN: "Diseño",
        SEGMENTS: "Segmentos",
        SCHEDULE: "Programación",
        CONFIRMATION: "Confirmación",
        SELECT: "Seleccione",
        ROOM_OFFERS: "Ofertas de habitación",
        CAMPAIGNS_DESCR: "Las campañas son diseñadas para aumentar los ingresos de habitación mediante ofertas y paquetes",
        EVENTS_ANNOUNCEMENTS: "Eventos y anuncios",
        NOTIFY_RECIPIENTS: "Para notificar a los destinatarios de eventos especiales en la propiedad",
        NEWSLETTER: "Boletín de noticias",
        CAMPAIGNS_UPDATE_DESCR: "Son campañas que presentan noticias o eventos del hotel y se envían con regularidad",
        MISS_YOU: "Te echamos de menos",
        CAMPAGINS_SENT: "Son campañas que se envían luego de las estadías para fomentar nuevas reservas",
        OTHER: "Otros",
        CREATE_CUSTOM: "Crear campaña personalizada",
        PRE_ARRIVAL: "Antes de la llegada",
        SEND_EMAIL: "Se envía un correo a los huéspedes antes de su llegada",
        WELCOME: "Bienvenida",
        SEND_EMAIL_CHECK_IN: "Se envía un correo a los huéspedes al hacer check-in",
        QUALIFICATION: "Calificación",
        SEND_EMAIL_SOON: "Se envía un correo a los huéspedes al ingresarlos a un segmento. Sólo se envía una vez",
        SENT_TO_GUESTS: "Se envía a los huéspedes luego de la estadía invitándoles a volver",
        OTA_WINBACK: "Obtención de clientes de OTA",
        SEND_CAMPAIGN: "Se envía a una campaña a los huéspedes que reservan por OTA 30 días después del check-out",
        BIRTHDAY: "Cumpleaños",
        SEND_SPECIAL: "Se envía una oferta especial para celebrar un cumpleaños o aniversario",
        DOUBLE_OPT: "Consentimiento doble",
        AUTOMATICALLY_SEND: "Se envía automáticamente un enlace de suscripción a los huéspedes que aceptan estar en una lista de contacto",
        CAMPAIGN_NAME_HINT: "Asigne un nombre interno a su campaña para organizar y encontrarla con facilidad en su cuenta.",
        CAMPAIGN_NAME_REQUIRED: "Nombre de la campaña es obligatorio",
        SUBJECT_LINE: "Asunto",
        SUBJECT_LINE_HINT: "Escriba un Asunto que describa con claridad el contenido de su correo. Será visible en la bandeja de entrada del destinatario y será lo primero que verán.",
        SUBJECT_REQUIRED: "Asunto es obligatorio",
        PREVIEW_LINE: "Vista previa",
        PREVIEW_LINE_HINT: "Escriba un texto corto (de unos 35 caracteres) con información general del correo. El texto se verá en la bandeja de entrada del destinatario, debajo del Asunto.",
        FROM_EMAIL: "Correo del remitente",
        FROM_EMAIL_HINT: "Seleccione la dirección de correo que verán sus destinatarios en la bandeja de entrada al recibir su campaña.",
        FROM_EMAIL_REQUIRED: "Correo del remitente es obligatorio",
        FROM_NAME: "Nombre del remitente",
        FROM_NAME_HINT: "Escriba un nombre (como el de su empresa) para que los destinatarios de la campaña lo reconozcan en su bandeja de entrada.",
        FROM_NAME_REQUIRED: "Nombre del remitente es obligatorio",
        MY_TEMPLATES: "Mis plantillas",
        TEMPLATE_GALLERY: "Galería de plantillas",
        DESIGN_TOOLS: "Herramientas de diseño",
        DRAG_DROP_EDITOR: "Editor de arrastrar y soltar",
        DRAG_DROP_EDITOR_DESC: "Aquí puede crear un correo adaptable para móviles que se verá bien en todo dispositivo o tamaño de pantalla",
        RICH_TEXT_EDITOR: "Editor de texto enriquecido",
        RICH_TEXT_EDITOR_DESC: "Use el editor de texto enriquecido para crear correos sencillos",
        PASTE_YOUR_CODE: "Pegue su código",
        PASTE_YOUR_CODE_DESC: "Copie y pegue su código de HTML.",
        RESET_EMAIL_DESIGN: "Borrar diseño del correo",
        EDIT_EMAIL_CONTENT: "Editar contenido del correo",
        LOAD_SAVED_VERSION: "Cargar una versión guardada",
        VERSION: "Versión",
        SAVE: "Guardar",
        SAVE_QUIT: "Guardar y salir",
        QUIT_WITHOUT_SAVING: "Salir sin guardar",
        HTML_EDITOR: "Editor de HMTL",
        ENTER_HTML: "Ingrese el HTML",
        CANCEL: "Cancelar",
        CHOOSE_DIFFERENT_SEGMENT: "Seleccione otro segmento",
        ADDITIONAL_OPTIONS: "Opciones adicionales",
        FILTER_PROPERTIES: "Propiedades del filtro",
        ALL_PROPERTIES: "Todas las propiedades",
        ENTER_PROPERTY_TO_FILTER: "Escriba la propiedad a filtrar...",
        ONLY_INCLUDE_GUESTS_WHO_HAVE: "Solo incluir huéspedes que",
        RECEIVED: "han recibido",
        NOT_RECEIVED: "no han recibido",
        OPENED_LOWER: "han abierto",
        NOT_OPENED: "no han abierto",
        CLICKED_LOWER: "han hehco clic",
        NOT_CLICKED: "no han hecho clic",
        BEEN_SENT: "se les ha enviado",
        NOT_SENT: "no se les ha enviado",
        ANY_EXISTING_CAMPAIGN_IN_LAST: "toda campaña existente en el último(a) o últimos",
        DAY: "día",
        WEEK: "semana",
        TWO_WEEKS: "dos semanas",
        MONTH: "mes",
        YEAR: "año",
        TWO_YEARS: "dos años",
        ENABLE_SAFE_MODE: "Activar modo seguro",
        BEST_PRACTICE: "Mejores prácticas de GDPR",
        GUESTS_MUST_HAVE: "Los huéspedes deben tener",
        IMPLICIT_CONSENT: "Consentimiento implícito",
        SAFE: "seguro",
        EXPLICIT_CONSENT: "Consentimiento explícito",
        SAFER: "más seguro",
        DOUBLE_OPT_IN_CONSENT: "Consentimiento y suscripción",
        SAFEST: "el más seguro",
        EXCLUDE_EMAIL_FUTURE_STAYS: "Excluir direcciones de correo con estadías futuras",
        REFRESH_STATISTICS: "Actualizar estadísticas",
        SELECT_SCHEDULE_OPTION: "Seleccione una opción de programación para su campaña:",
        SPECIFIC_DATE_TIME: "Una fecha y hora específica",
        IMMEDIATELY: "Inmediatamente",
        BASED_ON_GUEST_EVENT: "Según un evento del huésped",
        AT: "a las",
        HH: 'HH',
        MM: 'MM',
        ON: "en",
        HOUR_REQUIRED: "La hora es obligatoria",
        MINUTES_REQUIRED: "Los minutos son obligatorios",
        SELECT_DATE: "Seleccione una fecha",
        DATE_REQUIRED: "La fecha es obligatoria",
        DATE_MUST_BE_FUTURE: "La fecha debe ser futura",
        SELECT_DAYS: "Seleccione los días",
        DAYS_REQUIRED: "Los días son obligatorios",
        DAYS: "días",
        SELECT_EVENT: "Seleccione un evento",
        EVENT_REQUIRED: "Evento es obligatorio",
        SELECT_LIST: "Seleccione una lista",
        LIST_REQUIRED: "Lista es obligatoria",
        CAMPAIGN_READY: "¡Su campaña está lista para enviarse!",
        REVIEW_REPORT_BEFORE_SENDING: "Lea el siguiente informe antes de enviar su campaña",
        SUBJECT: "Asunto",
        FROM: "Remitente",
        ROOM_REVENUE_TRACKING: "Registro de ingresos de habitación",
        DOUBLE_OPT_IN_URL: "URL de consentimiento y suscripción",
        DOUBLE_OPT_IN_URL_HINT: "Esta es la URL al que se redirigirá al usuario en los flujos de consentimiento y suscripción a la vez.",
        DOUBLE_OPT_IN_URL_REQUIRED: "URL de consentimiento y suscripción es obligatoria",
        VALID_URL_REQUIRED: "Ingrese una URL válida",
        YOUR_DESIGN: "Su diseño",
        USED: "Ha usado la",
        CREATE_DESIGN: "para crear su diseño",
        EDIT_CONTENT: "Editar el contenido",
        ADDITIONAL_FILTERS: "Filtros adicionales",
        SEND_IMMEDIATELY: "Enviar de inmediato",
        SEND_TEST_EMAIL: "Enviar correo de prueba",
        ENTER_EMAILS: "Agregue los correos",
        ENTER_EMAILS_DESC: "Agregue la lista de direcciones de correo electrónico, una por línea",
        SEND_TEST: "Enviar correo de prueba",
        SCHEDULE_CAMPAIGN: "Programar campaña",
        SURE_DELIVERY: "¿Está seguro(a) que quiere programar el envío de su campaña?",
        CAMPAIGN_IN_LAST: " cualquier campaña en los últimos ",
        SEARCH_TEMPLATE_NAME: "Buscar por nombre de plantilla",
        CREATE_TEMPLATE: "Crear plantilla",
        EDIT_CAMPAIGN: "Editar campaña",
        TEMPLATE_NAME: "Nombre de la plantilla",
        EDIT_TEMPLATE: "Editar plantilla",
        MORE_OPTIONS: "Más opciones",
        TEMPLATE_NAME_HINT: "Asigne un nombre interno a su plantilla para organizar y encontrarla con facilidad en su cuenta.",
        TEMPLATE_NAME_REQUIRED: "Nombre de la plantilla es obligatorio",
        LIST_SELECTED: "Lista seleccionada",
        LISTS_SELECTED: "Listas seleccionadas",
        ALL_LISTS_SELECTED: "Todas las listas seleccionadas",
        BEFORE_CHECK_IN: "antes del check-in",
        AFTER_CHECK_IN: "luego del check-in",
        BEFORE_CHECK_OUT: "antes del check-out",
        AFTER_LAST_CHECK_OUT: "luego del último check-out",
        ON_CHECK_OUT: "en el check-out",
        ON_CHECK_IN: "en el check-in",
        ON_DAY_OF_CHECK_IN: "en la fecha de check-in",
        BEFORE_BIRTHDAY: "antes del cumpleaños",
        BEFORE_ANNIVERSARY: "antes del aniversario",
        ON_LIST_JOINED: "en la lista que se unió",
        AT_SPASE: "en ",
        UNLAYER_LANGUAGE: 'en-US',
        EDITOR_LANGUAGE: 'en'
      }
    },
    MENU: {
      NEW: "nuevo",
      ACTIONS: "Acciones",
      CREATE_POST: "Crear nueva publicación",
      PAGES: "Páginas",
      FEATURES: "Funciones",
      APPS: "Aplicaciones",
      HOME: "Inicio"
    },
    AUTH: {
      GENERAL: {
        OR: "O",
        SUBMIT_BUTTON: "Enviar",
        NO_ACCOUNT: "¿No tiene una cuenta?",
        SIGNUP_BUTTON: "Suscribirse",
        FORGOT_BUTTON: "Olvidé mi contraseña",
        BACK_BUTTON: "Volver",
        PRIVACY: "Privacidad",
        LEGAL: 'Legal',
        CONTACT: "Contacto"
      },
      LOGIN: {
        TITLE: "Cuenta de inicio de sesión",
        BUTTON: "Iniciar sesión"
      },
      FORGOT: {
        TITLE: "¿Ha olvidado su contraseña?",
        DESC: "Escriba su correo para cambiar su contraseña",
        SUCCESS: "Se ha cambiado la contraseña de su cuenta."
      },
      REGISTER: {
        TITLE: "Suscribirse",
        DESC: "Ingrese su información para crear su cuenta",
        SUCCESS: "Su cuenta ha sido registrada con éxito."
      },
      INPUT: {
        EMAIL: "Correo electrónico",
        FULLNAME: "Nombre",
        PASSWORD: "Contraseña",
        CONFIRM_PASSWORD: "Confirmar contraseña",
        USERNAME: "Nombre de usuario"
      },
      VALIDATION: {
        INVALID: "{{name}} no es válido",
        REQUIRED: "{{name}} es obligatorio",
        MIN_LENGTH: "{{name}} duración mínima es {{min}}",
        AGREEMENT_REQUIRED: "Es obligatorio aceptar los términos y condiciones",
        NOT_FOUND: "No se encontró el {{name}} requerido",
        INVALID_LOGIN: "Los datos de inicio de sesión no son correctos",
        REQUIRED_FIELD: "Campo obligatorio",
        MIN_LENGTH_FIELD: "Longitud mínima del campo:",
        MAX_LENGTH_FIELD: "Longitud máxima del campo:",
        INVALID_FIELD: "El campo no es válido"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Conteo de registros seleccionados: ",
        ALL: "Todos",
        SUSPENDED: "Suspendidos",
        ACTIVE: "Activos",
        FILTER: "Filtrar",
        BY_STATUS: "por estado",
        BY_TYPE: "por tipo",
        BUSINESS: "Empresa",
        INDIVIDUAL: "Individuo",
        SEARCH: "Buscar",
        IN_ALL_FIELDS: "en todos los campos"
      },
      ECOMMERCE: "Comercio electrónico",
      CUSTOMERS: {
        CUSTOMERS: "Huéspedes",
        CUSTOMERS_LIST: "Lista de huéspedes",
        NEW_CUSTOMER: "Nuevo huésped",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Eliminar huésped",
          DESCRIPTION: "¿Está seguro(a) que quiere eliminar permanentemente este huésped?",
          WAIT_DESCRIPTION: "Se está eliminando el huésped...",
          MESSAGE: "El huésped ha sido eliminado"
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Eliminar huéspedes",
          DESCRIPTION: "¿Está seguro(a) que quiere eliminar permanentemente los huéspedes seleccionados?",
          WAIT_DESCRIPTION: "Se están eliminando los huéspedes...",
          MESSAGE: "Los huéspedes seleccionados han sido eliminados"
        },
        UPDATE_STATUS: {
          TITLE: "El estado de los huéspedes seleccionados ha sido actualizado",
          MESSAGE: "El estado de los huéspedes seleccionados ha sido debidamente actualizado"
        },
        EDIT: {
          UPDATE_MESSAGE: "El huésped ha sido actualizado",
          ADD_MESSAGE: "El huésped ha sido creado"
        }
      }
    },
    Jan: "Ene",
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: "Abr",
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: "Ago",
    Sep: 'Sept',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: "Dic",
    Clicks: "Clics",
    Opens: "Abiertos",
    '< 7 days': "< 7 días",
    '7-14 Days': "7-14 días",
    '15-30 Days': "15-30 días",
    '31-60 Days': "31-60 días",
    '61-90 Days': "61-90 días",
    '> 90 Days': "> 90 días",
    '< 1 month': "< 1 mes",
    '1-2 Months': "1-2 meses",
    '2-6 Months': "2-6 meses",
    '6-12 Months': "6-12 meses",
    '> 1 Year': "> 1 año",
    Sent: "Enviado",
    Delivered: "Entregado",
    Open: "Abierto",
    Spam: 'Spam',
    Click: "Clic",
    HardBounce: "Rebotado definitivamente",
    SoftBounce: "Rebotado temporalmente",
    Unsubscribe: "No está suscrito",
    InvalidEmail: "Correo no válido",
    Blocked: "Bloqueado",
    Complaint: "Presentado queja",
    Deferred: "Diferido",
    ListAddition: "Complemento de la lista",
    Overview: "Información general",
    "Guest List": "Lista de huéspedes",
    "Guest Lists": "Listas de huéspedes",
    "Guest Segments": "Segmentos de huéspedes",
    Campaigns: "Campañas",
    Templates: "Plantillas"
  }
};