import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from } from 'rxjs';
import {  switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import OktaAuth from '@okta/okta-auth-js';

@Injectable({
	providedIn: 'root'
})
export class OktaAuthService {

	private oktaAuthClient = new OktaAuth({
		issuer: environment.oktaConfig.issuerUrl,
		clientId: environment.oktaConfig.clientId,
		redirectUri: `${window.location.origin}/mfd`,
		responseType: 'code',
		pkce: true,
	});

	constructor(private httpClient: HttpClient) {

	}

	mfdAuthenticate() {
		if (this.oktaAuthClient.token.isLoginRedirect()) {
			let tokens = null;
			return from(this.oktaAuthClient.token.parseFromUrl()).pipe(
				switchMap((t) => {
					tokens = t;
					this.oktaAuthClient.tokenManager.setTokens(tokens.tokens);
					return from(this.oktaAuthClient.start());
				}),
				switchMap(() => {
					return this.exchangeToken(tokens.tokens.accessToken.accessToken);
				}),
			);
		}

		return from(this.oktaAuthClient.isAuthenticated()).pipe(
			switchMap(isAuthenticated => {
				if (isAuthenticated) {
					const tokens = this.oktaAuthClient.tokenManager.getTokensSync();
					return this.exchangeToken(tokens.accessToken.accessToken);
				} else {
					return from(this.oktaAuthClient.token.getWithRedirect({ scopes: ['openid'] }));
				}
			})
		);
	}

	login() {
		from(this.oktaAuthClient.token.getWithRedirect({ scopes: ['openid'] })).subscribe(() => { });
	}

	exchangeToken(accessToken: string) {
		let apiUrl = `${environment.api.usersSettings}/authentication/token`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		
		let urlParams  = new URLSearchParams(window.location.search)
		let mfdPropertyId = urlParams.get('id') || localStorage.getItem('cb-mfd-id');

		return this.httpClient.post<{ accessToken: string, idToken: string }>(apiUrl, { 'accessToken': accessToken, 'mfdPropertyId': mfdPropertyId }, httpOptions);
	}
}
