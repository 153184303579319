// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../_services';
import { OktaAuthService } from '../_services/okta-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private auth: AuthService, private oktaAuth: OktaAuthService) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
		return this.auth.isAuthenticated$.pipe(
			tap(loggedIn => {
				if (!loggedIn) {
					//this.auth.login(state.url);
					this.oktaAuth.login();
				}
			})
		);
	}
}
