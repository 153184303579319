import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CoreModule } from '../../../../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';

// General widgets
import { OpportunitiesSmallComponent } from './opportunities-small/opportunities-small.component';
import { DataTableComponent } from './general/data-table/data-table.component';
import { KpiChartComponent } from './general/kpi-chart/kpi-chart.component';
import { KpiContentComponent } from './general/kpi-content/kpi-content.component';
import { SegBarChartComponent } from './general/seg-bar-chart/seg-bar-chart.component';
import { SegColChartComponent } from './general/seg-col-chart/seg-col-chart.component';
import { PickupLineChartComponent } from './general/pickup-line-chart/pickup-line-chart.component';
import { CampaignLineChartComponent } from './general/campaign-line-chart/campaign-line-chart.component';
import { PieChartComponent } from './general/pie-chart/pie-chart.component';
import { PieChartFullComponent } from './general/pie-chart-full/pie-chart-full.component';
import { MarketingTableComponent } from './marketing-table/marketing-table.component';
import { CrmMarketingTableComponent } from './crm-marketing-table/crm-marketing-table.component';
import { OpportunitiesFullComponent } from './opportunities-full/opportunities-full.component';
import { ProjectionsChartComponent } from './general/projections-chart/projections-chart.component';
import { RateChartComponent } from './general/rate-chart/rate-chart.component';
import { OpportunitiesModalComponent } from './opportunities-modal/opportunities-modal.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { TabBarComponent } from './general/tab-bar/tab-bar.component';
import { RateChartOverviewComponent } from './general/rate-chart-overview/rate-chart-overview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HeatmapModule } from './heatmap-new/heatmap.module';
import { SegHorizontalBarChartComponent } from './general/seg-horizontal-bar-chart/seg-horizontal-bar-chart.component';
import { MetaChartOverviewComponent } from './general/meta-chart-overview/meta-chart-overview.component';
import { EventTableComponent } from './general/event-table/event-table.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { SegBarStackedChartComponent } from './general/seg-bar-stacked-chart/seg-bar-stacked-chart.component';
import { SegBarStackedChartComponentV2 } from './general/seg-bar-stacked-chart-v2/seg-bar-stacked-chart-v2.component';
import { SegBarStackedGeneralChartComponent } from './general/seg-bar-stacked-general-chart/seg-bar-stacked-general-chart.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MbscModule } from '@mobiscroll/angular';
import { MenuModule } from '../../menus/menu.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {ChatMessage} from "./smart-analytics/chat-message/chat-message.component";
import {ChatLineChart} from "./smart-analytics/chat-line-chart/chat-line-chart.component";
import {ChatBarChart} from "./smart-analytics/chat-bar-chart/chat-bar-chart.component";
import {ChatPieChart} from "./smart-analytics/chat-pie-chart/chat-pie-chart.component";
import { ChatTable } from "./smart-analytics/chat-table/chat-table.component";
import { DataGrid } from "./general/data-grid/data-grid.component";
import {MatMenuModule} from "@angular/material/menu";
import {ChatFilter} from "./smart-analytics/chat-filter/chat-filter.component";
import { ChatVoiceInput } from "./smart-analytics/chat-voice-input/chat-voice-input.component";
import {AgGridModule} from "ag-grid-angular";
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    declarations: [
        OpportunitiesSmallComponent,
        OpportunitiesFullComponent,
        DataTableComponent,
        KpiChartComponent,
        KpiContentComponent,
        SegBarChartComponent,
        SegBarStackedChartComponent,
        SegBarStackedChartComponentV2,
        SegBarStackedGeneralChartComponent,
        SegColChartComponent,
        SegHorizontalBarChartComponent,
        PickupLineChartComponent,
        CampaignLineChartComponent,
        PieChartComponent,
        PieChartFullComponent,
        MarketingTableComponent,
        CrmMarketingTableComponent,
        ProjectionsChartComponent,
        RateChartComponent,
        RateChartOverviewComponent,
        MetaChartOverviewComponent,
        OpportunitiesModalComponent,
        ReportDialogComponent,
        TabBarComponent,
        EventTableComponent,
        ChatMessage,
        ChatLineChart,
        ChatBarChart,
        ChatPieChart,
        ChatTable,
        ChatFilter,
        ChatVoiceInput,
        DataGrid
    ],
    exports: [
        OpportunitiesSmallComponent,
        OpportunitiesFullComponent,
        DataTableComponent,
        KpiChartComponent,
        KpiContentComponent,
        SegBarChartComponent,
        SegBarStackedChartComponent,
        SegBarStackedChartComponentV2,
        SegBarStackedGeneralChartComponent,
        SegColChartComponent,
        SegHorizontalBarChartComponent,
        PickupLineChartComponent,
        CampaignLineChartComponent,
        PieChartComponent,
        PieChartFullComponent,
        MarketingTableComponent,
        CrmMarketingTableComponent,
        ProjectionsChartComponent,
        RateChartComponent,
        RateChartOverviewComponent,
        MetaChartOverviewComponent,
        OpportunitiesModalComponent,
        ReportDialogComponent,
        TabBarComponent,
        ScrollingModule,
        ChatMessage,
        ChatLineChart,
        ChatBarChart,
        ChatPieChart,
        ChatTable,
        ChatFilter,
        ChatVoiceInput,
        DataGrid
    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        MatTableModule,
        CoreModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        NgbModule,
        ScrollingModule,
        HeatmapModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        FormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRadioModule,
        MbscModule,
        MenuModule,
        MatMenuModule,
        AgGridModule
    ]
})
export class WidgetModule {
}
