export enum DatePeriod {
	Daily,
	Weekly,
	Monthly,
	Quarterly,
	Annually,
	MonthWeeks,
	All,
	FinancialPeriods,
	DayOfWeek
}
