// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MfdModule } from './views/pages/mfd/mfd.module';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./views/themes/demo1/theme.module').then(m => m.ThemeModule) },
	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
