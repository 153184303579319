import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, Subscription } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js'
import { HelpService } from './help.service';
import { ThemeService } from '../../_base/layout/services/theme.service';
import { Theme } from '../../_base/layout/models/theme';
import { UserService } from '../../_services/user.service';
import { TranslationService } from 'app/core/_base/layout';
import OktaAuth from '@okta/okta-auth-js';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	auth0Client$ = (from(
		createAuth0Client({
			domain: environment.authConfig.domain,
			client_id: environment.authConfig.clientId,
			redirect_uri: `${window.location.origin}`,
			audience: environment.authConfig.audience,
			cacheLocation: 'localstorage',
		})
	) as Observable<Auth0Client>).pipe(
		shareReplay(1), // Every subscription receives the same shared value
		catchError(err => throwError(err))
	);

	private oktaAuthClient = new OktaAuth({
		issuer: environment.oktaConfig.issuerUrl,
		clientId: environment.oktaConfig.clientId,
		redirectUri: `${window.location.origin}/mfd`,
		responseType: 'code',
		pkce: true,
	});

	// Define observables for SDK methods that return promises by default
	// For each Auth0 SDK method, first ensure the client instance is ready
	// concatMap: Using the client instance, call SDK method; SDK returns a promise
	// from: Convert that resulting promise into an observable
	isAuthenticated$ = this.auth0Client$.pipe(
		concatMap((client: Auth0Client) => from(client.isAuthenticated())),
		tap(res => this.loggedIn = res)
	);

	handleRedirectCallback$ = this.auth0Client$.pipe(
		concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
	);
	// Create subject and public observable of user profile data
	private userProfileSubject$ = new BehaviorSubject<any>(null);
	private userSubscription: Subscription = null;
	userProfile$ = this.userProfileSubject$.asObservable();
	// Create a local property for login status
	loggedIn: boolean = null;

	constructor(private router: Router, private userService: UserService, private readonly themeService: ThemeService, private readonly translationService: TranslationService) {
		// On initial load, check authentication state with authorization server
		// Set up local auth streams if user is already authenticated
		this.localAuthSetup();
		// Handle redirect from Auth0 login
		this.handleAuthCallback();
	}

	// When calling, options can be passed if desired
	// https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
	getUser$(options?): Observable<any> {
		return this.auth0Client$.pipe(
			concatMap((client: Auth0Client) => from(client.getUser(options))),
			tap(user => {
				this.userProfileSubject$.next(user);
				if (user) {
					Bugsnag.setUser(null, user.email, user.name);

					if (this.userSubscription) {
						this.userSubscription.unsubscribe();
					}
					this.userSubscription = this.userService.getSettings().subscribe(settings => {
						this.translationService.setLanguage(settings.language || 'en');

						if (window.location.host.includes('catalaconsulting')) {
							this.themeService.setTheme(Theme.Purple);
						} else if (window.location.host.includes('vertellam')) {
							this.themeService.setTheme(Theme.Yellow);
						} else {
							this.themeService.setTheme(settings.theme || Theme.Light);
						}
					});
				}
			})
		);
	}

	getTokenSilently$(options?): Observable<any> {
		return this.auth0Client$.pipe(
			concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
		);
	}

	private localAuthSetup() {
		// This should only be called on app initialization
		// Set up local authentication streams
		const checkAuth$ = this.isAuthenticated$.pipe(
			concatMap((loggedIn: boolean) => {
				if (loggedIn) {
					// If authenticated, get user and set in app
					// NOTE: you could pass options here if needed
					return this.getUser$();
				}
				// If not authenticated, return stream that emits 'false'
				return of(loggedIn);
			})
		);
		checkAuth$.subscribe();
	}

	login(redirectPath: string = '/') {
		const params = window.location.search;
		if (params.includes('code=') && params.includes('state=')) {
			return;
		}
		// A desired redirect path can be passed to login method
		// (e.g., from a route guard)
		// Ensure Auth0 client instance exists
		this.auth0Client$.subscribe((client: Auth0Client) => {
			// Call method to log in
			client.loginWithRedirect({
				redirect_uri: `${window.location.origin}`,
				appState: { target: redirectPath }
			});
		});
	}

	loginWithAccessToken(accessToken: string): Observable<void> {
		return new Observable<void>((observer) => {
			this.auth0Client$.subscribe({
				next: () => {
					this.setSession(accessToken);
					observer.next();
					observer.complete();
				},
				error: (err) => {
					observer.error(err);
				}
			});
		});
	}


	private handleAuthCallback() {
		// To prevent clash with Okta
		if (window.location.pathname == '/mfd') {
			return;
		}
		// Call when app reloads after user logs in with Auth0
		const params = window.location.search;
		if (params.includes('code=') && params.includes('state=')) {
			let targetRoute: string; // Path to redirect to after login processsed
			const authComplete$ = this.handleRedirectCallback$.pipe(
				// Have client, now call method to handle auth callback redirect
				tap(cbRes => {
					// Get and set target redirect route from callback results
					targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
				}),
				concatMap(() => {
					// Redirect callback complete; get user and login status
					return combineLatest([
						this.getUser$(),
						this.isAuthenticated$
					]);
				})
			);
			// Subscribe to authentication completion observable
			// Response will be an array of user and login status
			authComplete$.subscribe(([user, loggedIn]) => {
				// Redirect to target route after callback processing
				this.router.navigateByUrl(targetRoute);
			});
		}
	}

	logout(redirectUrl: string = null) {
		// Ensure Auth0 client instance exists
		this.auth0Client$.subscribe((client: Auth0Client) => {
			// Call method to log out
			client.logout({
				client_id: environment.authConfig.clientId,
				returnTo: environment.oktaConfig.logoutRedirectUrl
			});
		});
	}

	private base64UrlDecode(str: string): string {
		return decodeURIComponent(atob(str.replace(/_/g, '/').replace(/-/g, '+')).split('').map((c) => {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	}

	private decodeJWT(token: string): any {
		const [header, payload, signature] = token.split('.');
		if (!header || !payload || !signature) {
			throw new Error('Invalid token');
		}
		return JSON.parse(this.base64UrlDecode(payload));
	}

	private setSession(accessToken: string): void {
		const decodedToken = this.decodeJWT(accessToken);
		const expiresAt = decodedToken.exp;
		const idToken = {
			__raw: accessToken,
			header: JSON.parse(this.base64UrlDecode(accessToken.split('.')[0])),
			payload: JSON.parse(this.base64UrlDecode(accessToken.split('.')[1])),
			claims: decodedToken
		};

		const key = `@@auth0spajs@@::${environment.authConfig.clientId}::${environment.authConfig.audience}::openid profile email`;
		const value = {
			body: {
				client_id: environment.authConfig.clientId,
				access_token: accessToken,
				id_token: accessToken,
				scope: 'openid profile email',
				expires_in: expiresAt - Math.floor(Date.now() / 1000),
				token_type: 'Bearer',
				decodedToken: {
					encoded: {
						header: JSON.stringify(idToken.header),
						payload: JSON.stringify(idToken.payload),
						signature: accessToken.split('.')[2]
					},
					header: idToken.header,
					claims: idToken.payload,
					user: idToken.claims
				},
				oauthTokenScope: 'openid profile email',
				audience: environment.authConfig.audience
			},
			expiresAt: expiresAt
		};

		this.userProfileSubject$.next(idToken.claims)

		localStorage.setItem(key, JSON.stringify(value));
	}
}
