import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePeriod } from '../_models/date-period.enum';
import { formatDate } from '@angular/common';
import { MetaStatisticDto } from '../_models/dtos/meta-statistic-dto.model';
import { MetaBudgetDto } from '../_models/dtos/meta-budget-dto.model';
import { MetaMarketingType } from '../_models/meta-marketing.enum';
import { MetaBookingDto } from '../_models/dtos/meta-booking-dto.model';
import { MetaSearchDto } from '../_models/dtos/meta-search-dto.model';
import { MetaPickupDto } from '../_models/dtos/meta-pickup-dto.model';


@Injectable({
	providedIn: 'root'
})
export class MarketingService {

	constructor(private httpClient: HttpClient) { }

	getForHotel(hotelId: number, fromDate: Date, toDate: Date, datePeriod: DatePeriod = DatePeriod.Daily, byCountry = false, bySource = false): Observable<MetaStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/marketing`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('byCountry', byCountry ? 'true' : 'false')
			.set('bySource', bySource ? 'true' : 'false');

		return this.httpClient.get<MetaStatisticDto[]>(apiUrl, { params });
	}

	getBudgetsForHotel(hotelId: number): Observable<MetaBudgetDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/marketing/budgets`;
		return this.httpClient.get<MetaBudgetDto[]>(apiUrl);
	}

	getBookings(hotelId: number, fromDate: Date, toDate: Date, datePeriod: DatePeriod = DatePeriod.Daily, dateUpdated: Date = null, bySource = false, metaMarketingType: MetaMarketingType = MetaMarketingType.None): Observable<MetaBookingDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/marketing/bookings`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('bySource', bySource ? 'true' : 'false')
			.set('metaMarketingType', <any>metaMarketingType);

		if (dateUpdated)
			params = params.set('updateTime', formatDate(dateUpdated, 'yyyy-MM-dd', 'en-GB'));

		return this.httpClient.get<MetaBookingDto[]>(apiUrl, { params });
	}

	getSearches(hotelId: number, fromDate: Date, toDate: Date, datePeriod: DatePeriod = DatePeriod.Daily, dateUpdated: Date = null, bySource = false, metaMarketingType: MetaMarketingType = MetaMarketingType.None): Observable<MetaSearchDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/marketing/searches`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('bySource', bySource ? 'true' : 'false')
			.set('metaMarketingType', <any>metaMarketingType);

		if (dateUpdated)
			params = params.set('updateTime', formatDate(dateUpdated, 'yyyy-MM-dd', 'en-GB'));

		return this.httpClient.get<MetaSearchDto[]>(apiUrl, { params });
	}

	getPickup(hotelId: number, stayDate: Date, bySource = false, leadInFrom: number = 0, leadIn: number = 60): Observable<MetaPickupDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/marketing/pickup`;

		let params = new HttpParams()
			.set('stayDate', formatDate(stayDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('bySource', bySource ? 'true' : 'false')
			.set('leadInFrom', leadInFrom.toString())
			.set('leadIn', leadIn.toString());

		return this.httpClient.get<MetaPickupDto[]>(apiUrl, { params });
	}
}
