import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserSettingDto } from '../_models/dtos/user-setting-dto.model';

@Injectable({
	providedIn: 'root'
})
export class UserSettingService {
	constructor(private httpClient: HttpClient) { }

	getSettings(): Observable<UserSettingDto[]> {
		let apiUrl = `${environment.api.hotelSettings}/usersetting`;
		return this.httpClient.get<UserSettingDto[]>(apiUrl);
	}

	getSettingByKey(key: string): Observable<UserSettingDto> {
		let apiUrl = `${environment.api.hotelSettings}/usersetting/${key}`;
		return this.httpClient.get<UserSettingDto>(apiUrl);
	}

	update(userSetting: UserSettingDto): Observable<any> {
		let apiUrl = `${environment.api.hotelSettings}/usersetting`;
		return this.httpClient.put(apiUrl, userSetting);
	}
}
