// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotelMenuComponent } from './hotel-menu/hotel-menu.component';
import { MbscModule } from '@mobiscroll/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
	declarations: [
		HotelMenuComponent
	],
	exports: [
		HotelMenuComponent,		
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MbscModule,
		NgbModule,
		MatSlideToggleModule
	]
})
export class MenuModule {
}
