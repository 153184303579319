// Angular
import { AfterViewInit, Directive, ElementRef, Input, ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { HotelService } from '../../../_services/hotel.service';
import { Subscribable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Directive({
	selector: '[hasPermission]',
	exportAs: 'hasPermission',
})
export class HasPermissionDirective implements AfterViewInit, OnDestroy {
	private hotels = [];
	private permission = null;
	private hotelId = null;
	private subscription: Subscription = null;

	constructor(
		private el: ElementRef,
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<any>,
		private hotelService: HotelService) {
	}

	ngAfterViewInit(): void {
		this.subscription = this.hotelService.getAll().pipe(take(1)).subscribe(h => {
			this.hotels = h;
			this.updateView();
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@Input()
	set hasPermission(val) {
		this.permission = val;
		this.updateView();
	}

	@Input()
	set hotel(val) {
		this.hotelId = val;
		this.updateView();
	}

	private updateView() {
		if (this.checkPermission()) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	private checkPermission() {
		let hasPermission = false;

		if (this.hotels) {
			let htls = this.hotels;
			if (this.hotelId) {
				htls = this.hotels.filter(h => h.hotelId == this.hotelId);
			}

			let allPermissions = [].concat(...htls.map(h => h.permissions));
			return !!allPermissions.find(p => p.toLocaleLowerCase() == this.permission.toLocaleLowerCase());
		}

		return hasPermission;
	}
}
