import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StatisticDto } from '../_models/dtos/statistic-dto.model';
import { DatePeriod } from '../_models/date-period.enum';
import { DataSourceType } from '../_models/data-source.enum';
import { formatDate } from '@angular/common';
import { HotelStatisticDto } from '../_models/dtos/hotel-statistic-dto.model';
import { CustomHotelStatisticDto } from '../_models/dtos/custom-hotel-statistic-dto.model';
import { CustomStatisticDto } from "../_models/dtos/custom-statistic-dto.model";
import { HotelEodStatisticDto } from '../_models/dtos/hotel-eod-statistic-dto.model';
import { HotelPayrollStatisticDto } from '../_models/dtos/hotel-payroll-statistic-dto.model';
import { SearchResultDto } from '../_models/dtos/search-result-dto.model';
import { DetailStatisticDto } from '../_models/dtos/detail-statistic-dto.model';
import { map, retry } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { param } from 'jquery';
import { last } from 'rxjs-compat/operator/last';


@Injectable({
	providedIn: 'root'
})
export class StatisticService {

	constructor(private httpClient: HttpClient) { }

	getAll(fromDate: Date, toDate: Date, roomId: number = null, datePeriod: DatePeriod = DatePeriod.Daily, byDay = true, hotelIds: number[] = null, reportDate: Date = null): Observable<HotelStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('byDay', byDay ? 'true' : 'false');

		if (roomId) {
			params = params.set('roomCode', roomId.toString());
		}
		if (reportDate) {
			params = params.set('reportDate', formatDate(reportDate, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'))
		}
		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<HotelStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getForHotel(hotelId: number, fromDate: Date, toDate: Date, roomId: number = null, datePeriod: DatePeriod = DatePeriod.Daily, showSegments = false, reportDate: Date = null, byDay = true): Observable<StatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/statistic`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('showSegments', showSegments ? 'true' : 'false')
			.set('byDay', byDay ? 'true' : 'false');

		if (reportDate) {
			params = params.set('reportDate', formatDate(reportDate, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'))
		}

		if (roomId) {
			params = params.set('roomCode', roomId.toString());
		}		

		return this.httpClient.get<StatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getTotal(hotelIds: number[], fromDate: Date, toDate: Date, roomId: number = null, datePeriod: DatePeriod = null, showSegments = false, reportDate: Date = null, byDay = true, marketSegment: string = null): Observable<HotelStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/total`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))			
			.set('showSegments', showSegments ? 'true' : 'false')
			.set('byDay', byDay ? 'true' : 'false');

		if (datePeriod) {
			params = params.set('datePeriod', <any>datePeriod);
		}
		if (reportDate) {
			params = params.set('reportDate', formatDate(reportDate, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'))
		}

		if (roomId) {
			params = params.set('roomCode', roomId.toString());
		}
		if (marketSegment) {
			params = params.set('marketSegment', marketSegment);
		}
		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<HotelStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getTotalCustom(hotelIds: number[], fromDate: Date, toDate: Date, roomId: number = null, datePeriod: DatePeriod = null, showSegments = false, reportDate: Date = null, byDay = true, marketSegment: string = null, historical: number[] = null, historicalCustom: Date[] = null, spitYears: number[] = null, spitCustom: Date[] = null, bookingsAsOf: Date[] = null): Observable<CustomHotelStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/totalcustom`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('showSegments', showSegments ? 'true' : 'false')
			.set('byDay', byDay ? 'true' : 'false')
			.set('comparisonTimes', historical != null || historicalCustom != null || spitYears != null || spitCustom != null || bookingsAsOf != null);
		if (historical != null && historical.length > 0) {
			historical.forEach(y => { params = params.append('historical', y.toString()); });
		}
		if (historicalCustom != null && historicalCustom.length > 0) {
			historicalCustom.forEach(d => { params = params.append('historicalCustom', formatDate(d, 'yyyy-MM-dd', 'en-GB', 'UTC')); });
		}
		if (spitYears != null && spitYears.length > 0) {
			spitYears.forEach(y => { params = params.append('spitYears', y.toString()); });
		}
		if (spitCustom != null && spitCustom.length > 0) {
			spitCustom.forEach(d => { params = params.append('spitCustom', formatDate(d, 'yyyy-MM-dd', 'en-GB', 'UTC')); });
		}
		if (bookingsAsOf != null && bookingsAsOf.length > 0) {
			bookingsAsOf.forEach(d => { params = params.append('bookingsAsOf', formatDate(d, 'yyyy-MM-dd', 'en-GB', 'UTC')); });
		}

		if (datePeriod) {
			params = params.set('datePeriod', <any>datePeriod);
		}
		if (reportDate) {
			params = params.set('reportDate', formatDate(reportDate, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'))
		}

		if (roomId) {
			params = params.set('roomCode', roomId.toString());
		}
		if (marketSegment) {
			params = params.set('marketSegment', marketSegment);
		}
		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<CustomHotelStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getTotalSources(hotelIds: number[], fromDate: Date, toDate: Date, datePeriod: DatePeriod = null, byDay = true, groupDate = true, groupSourceOfBusiness = false, groupBookingChannel = false, groupAgentCode = false, groupCompanyCode = false, sourceOfBusiness: string = null, bookingChannelCode: string = null, agentCode: string = null, bookingChannelName: string = null, companyName: string = null, groupRateCode = false, groupMarketCode = false, groupPromoCode = false, rateCode: string = null, marketCode: string = null, promoCode: string = null, dataSource: DataSourceType = DataSourceType.Full): Observable<HotelStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/totalsources`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('byDay', byDay ? 'true' : 'false')
			.set('groupDate', groupDate ? 'true' : 'false')
			.set('groupSourceOfBusiness', groupSourceOfBusiness ? 'true' : 'false')
			.set('groupBookingChannel', groupBookingChannel ? 'true' : 'false')
			.set('groupAgentCode', groupAgentCode ? 'true' : 'false')
			.set('groupCompanyCode', groupCompanyCode ? 'true' : 'false')
			.set('groupRateCode', groupRateCode ? 'true' : 'false')
			.set('groupMarketCode', groupMarketCode ? 'true' : 'false')
			.set('groupPromoCode', groupPromoCode ? 'true' : 'false');

		if (datePeriod) {
			params = params.set('datePeriod', <any>datePeriod);
		}
		if (sourceOfBusiness) {
			params = params.set('sourceOfBusiness', sourceOfBusiness);
		}
		if (bookingChannelCode) {
			params = params.set('bookingChannelCode', bookingChannelCode);
		}
		if (bookingChannelName) {
			params = params.set('bookingChannelName', bookingChannelName);
		}
		if (agentCode) {
			params = params.set('agentCode', agentCode);
		}
		if (companyName) {
			params = params.set('companyName', companyName);
		}
		if (sourceOfBusiness) {
			params = params.set('sourceOfBusiness', sourceOfBusiness);
		}
		if (rateCode) {
			params = params.set('rateCode', rateCode);
		}
		if (marketCode) {
			params = params.set('marketCode', marketCode);
		}
		if (promoCode) {
			apiUrl += '?promoCode=' + encodeURIComponent(promoCode);
		}
		if (dataSource != null) {
			params = params.set('dataSource', dataSource.toString());
		}
		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<HotelStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getTotalEod(hotelIds: number[], fromDate: Date, toDate: Date, datePeriod: DatePeriod = null, byDay = true, analysisGroup: string = null, showCodes = false, analysisCode: string = null): Observable<HotelEodStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/totaleod`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('showCodes', showCodes ? 'true' : 'false')
			.set('byDay', byDay ? 'true' : 'false');

		if (datePeriod) {
			params = params.set('datePeriod', <any>datePeriod);
		}

		if (analysisGroup) {
			params = params.set('analysisGroup', analysisGroup);
		}
		if (analysisCode) {
			params = params.set('analysisCode', analysisCode);
		}
		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<HotelEodStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getTotalPayroll(hotelIds: number[], fromDate: Date, toDate: Date, datePeriod: DatePeriod = null, byDay = true, department: string = null, byEmployeeGroup = false, employeeGroup: string = null, byHotelDepartment: boolean = false): Observable<HotelPayrollStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/totalpayroll`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('byEmployeeGroup', byEmployeeGroup ? 'true' : 'false')
			.set('byDay', byDay ? 'true' : 'false')
			.set('byHotelDepartment', byHotelDepartment ? 'true' : 'false');

		if (datePeriod) {
			params = params.set('datePeriod', <any>datePeriod);
		}

		if (department) {
			params = params.set('department', department);
		}
		if (employeeGroup) {
			params = params.set('employeeGroup', employeeGroup);
		}
		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<HotelPayrollStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getPickup(hotelId: number, fromDate: Date, toDate: Date, roomId: number = null, datePeriod: DatePeriod = DatePeriod.Daily, showSegments = false, leadInFrom = 0, leadIn = 60, byDay = true, marketSegment: string = null): Observable<StatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/statistic/pickup`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('showSegments', showSegments ? 'true' : 'false')
			.set('leadIn', leadIn.toString())
			.set('leadInFrom', leadInFrom.toString())
			.set('byDay', byDay ? 'true' : 'false');

		if (roomId) {
			params = params.set('roomCode', roomId.toString());
		}
		if (marketSegment) {
			params = params.set('marketSegment', marketSegment);
		}
		return this.httpClient.get<StatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getPickupCustom(hotelId: number, fromDate: Date, toDate: Date, roomId: number = null, datePeriod: DatePeriod = DatePeriod.Daily, showSegments = false, leadInFrom = 0, leadIn = 60, byDay = true, marketSegment: string = null, spitYears: number[] = null, spitCustom: Date[] = null, bookingsAsOf: Date[] = null): Observable<CustomStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/statistic/pickupcustom`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('datePeriod', <any>datePeriod)
			.set('showSegments', showSegments ? 'true' : 'false')
			.set('leadIn', leadIn.toString())
			.set('leadInFrom', leadInFrom.toString())
			.set('byDay', byDay ? 'true' : 'false')
			.set('comparisonTimes', spitYears != null || spitCustom != null || bookingsAsOf != null);
		if (spitYears != null && spitYears.length > 0) {
			spitYears.forEach(y => { params = params.append('spitYears', y.toString()); });
		}
		if (spitCustom != null && spitCustom.length > 0) {
			spitCustom.forEach(d => { params = params.append('spitCustom', formatDate(d, 'yyyy-MM-dd', 'en-GB', 'UTC')); });
		}
		if (bookingsAsOf != null && bookingsAsOf.length > 0) {
			bookingsAsOf.forEach(d => { params = params.append('bookingsAsOf', formatDate(d, 'yyyy-MM-dd', 'en-GB', 'UTC')); });
		}
		if (roomId) {
			params = params.set('roomCode', roomId.toString());
		}
		if (marketSegment) {
			params = params.set('marketSegment', marketSegment);
		}
		return this.httpClient.get<CustomStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getSmartQuery(hotelId: number, query: string, lastMessage: any = null): Observable<SearchResultDto> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/statistic/smart`;

		let params = new HttpParams()
			.set('query', query);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			params:params
		};

		let msg = lastMessage ? JSON.parse(JSON.stringify(lastMessage)) : {};
		msg.columns = null;

		return this.httpClient.post<SearchResultDto>(apiUrl, msg, httpOptions);
	}

	getTotalDetail(hotelIds: number[], fromDate: Date, toDate: Date, reportDate: Date = null): Observable<DetailStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/totaldetail`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'));

		if (reportDate) {
			params = params.set('reportDate', formatDate(reportDate, 'yyyy-MM-dd', 'en-GB', 'UTC'));
		}

		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<DetailStatisticDto[]>(apiUrl, { params }).pipe(
			retry(2),
			map(stats => {
				stats.forEach(s => {
					s.confirmedRooms = (s.bookingStatus == 'Confirmed') ? s.rooms : 0;
					s.confirmedRoomRevenue = (s.bookingStatus == 'Confirmed') ? s.roomRevenue : 0;
					s.confirmedMeetingRevenue = (s.bookingStatus == 'Confirmed') ? s.meetingRevenue: 0;
					s.confirmedFbRevenue = (s.bookingStatus == 'Confirmed') ? s.fbRevenue : 0;
					s.confirmedGolfRevenue = (s.bookingStatus == 'Confirmed') ? s.golfRevenue : 0;
					s.confirmedSpaRevenue = (s.bookingStatus == 'Confirmed') ? s.spaRevenue : 0;
					s.confirmedOtherRevenue = (s.bookingStatus == 'Confirmed') ? s.otherRevenue: 0;
					s.confirmedTotalRevenue = (s.bookingStatus == 'Confirmed') ? s.totalRevenue : 0;

					s.tentativeRooms = (s.bookingStatus == 'Tentative') ? s.rooms : 0;
					s.tentativeRoomRevenue = (s.bookingStatus == 'Tentative') ? s.roomRevenue : 0;
					s.tentativeMeetingRevenue = (s.bookingStatus == 'Tentative') ? s.meetingRevenue : 0;
					s.tentativeFbRevenue = (s.bookingStatus == 'Tentative') ? s.fbRevenue : 0;
					s.tentativeGolfRevenue = (s.bookingStatus == 'Tentative') ? s.golfRevenue : 0;
					s.tentativeSpaRevenue = (s.bookingStatus == 'Tentative') ? s.spaRevenue : 0;
					s.tentativeOtherRevenue = (s.bookingStatus == 'Tentative') ? s.otherRevenue : 0;
					s.tentativeTotalRevenue = (s.bookingStatus == 'Tentative') ? s.totalRevenue : 0;

					s.provisionalRooms = (s.bookingStatus == 'Provisional') ? s.rooms : 0;
					s.provisionalRoomRevenue = (s.bookingStatus == 'Provisional') ? s.roomRevenue : 0;
					s.provisionalMeetingRevenue = (s.bookingStatus == 'Provisional') ? s.meetingRevenue : 0;
					s.provisionalFbRevenue = (s.bookingStatus == 'Provisional') ? s.fbRevenue : 0;
					s.provisionalGolfRevenue = (s.bookingStatus == 'Provisional') ? s.golfRevenue : 0;
					s.provisionalSpaRevenue = (s.bookingStatus == 'Provisional') ? s.spaRevenue : 0;
					s.provisionalOtherRevenue = (s.bookingStatus == 'Provisional') ? s.otherRevenue : 0;
					s.provisionalTotalRevenue = (s.bookingStatus == 'Provisional') ? s.totalRevenue : 0;

					s.allocationRooms = (s.bookingStatus == 'Hold') ? s.rooms : 0;
					s.allocationRoomRevenue = (s.bookingStatus == 'Hold') ? s.roomRevenue : 0;
					s.allocationMeetingRevenue = (s.bookingStatus == 'Hold') ? s.meetingRevenue : 0;
					s.allocationFbRevenue = (s.bookingStatus == 'Hold') ? s.fbRevenue : 0;
					s.allocationGolfRevenue = (s.bookingStatus == 'Hold') ? s.golfRevenue : 0;
					s.allocationSpaRevenue = (s.bookingStatus == 'Hold') ? s.spaRevenue : 0;
					s.allocationOtherRevenue = (s.bookingStatus == 'Hold') ? s.otherRevenue : 0;
					s.allocationTotalRevenue = (s.bookingStatus == 'Hold') ? s.totalRevenue : 0;

					s.enquiryRooms = (s.bookingStatus == 'Enquiry') ? s.rooms : 0;
					s.enquiryRoomRevenue = (s.bookingStatus == 'Enquiry') ? s.roomRevenue : 0;
					s.enquiryMeetingRevenue = (s.bookingStatus == 'Enquiry') ? s.meetingRevenue : 0;
					s.enquiryFbRevenue = (s.bookingStatus == 'Enquiry') ? s.fbRevenue : 0;
					s.enquiryGolfRevenue = (s.bookingStatus == 'Enquiry') ? s.golfRevenue : 0;
					s.enquirySpaRevenue = (s.bookingStatus == 'Enquiry') ? s.spaRevenue : 0;
					s.enquiryOtherRevenue = (s.bookingStatus == 'Enquiry') ? s.otherRevenue : 0;
					s.enquiryTotalRevenue = (s.bookingStatus == 'Enquiry') ? s.totalRevenue : 0;
				});
				return stats;
			})
		);
	}

	getTotalBudget(hotelIds: number[], fromDate: Date, toDate: Date): Observable<DetailStatisticDto[]> {
		let apiUrl = `${environment.api.analytics}/statistic/totalbudget`;

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'));

		if (hotelIds && hotelIds.length > 0 && !!hotelIds[0]) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		return this.httpClient.get<DetailStatisticDto[]>(apiUrl, { params }).pipe(retry(2));
	}
}
