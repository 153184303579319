// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials
import { ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent } from './content/crud';
// Layout partials
import {
	DateMonthMenuComponent,
	DropDownMenuComponent,
	CampaignMenuComponent,
	ChipListComponent,
	MobileTabBarComponent,
	ContextMenuComponent,
	LanguageSelectorComponent,
	QuickActionComponent,
	ScrollTopComponent,
	SearchDefaultComponent,
	SearchDropdownComponent,
	SearchResultComponent,
	SplashScreenComponent,
	PickupSubheaderComponent,
	AnalyticsSubheaderComponent,
	UserProfileComponent,
	CompanyBrandComponent,
	HomeSubheaderComponent,
	CrmSubheaderComponent,
	CrmCreateSubheaderComponent
} from './layout';
// General
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { CartComponent } from './layout/topbar/cart/cart.component';
import { ThemeSwitchComponent } from './layout/topbar/theme-switch/theme-switch.component';
import { SettingSubheaderComponent } from './layout/subheader/setting-subheader/setting-subheader.component';
import { TitleOnlySubheaderComponent } from './layout/subheader/title-only-subheader/title-only-subheader.component';
import { EventSubheaderComponent } from './layout/subheader/event-subheader/event-subheader.component';
import { MbscModule } from '@mobiscroll/angular';
import { MatChipsModule } from '@angular/material/chips';
import { MenuModule } from './menus/menu.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		ScrollTopComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,

		// topbar components
		DateMonthMenuComponent,
		DropDownMenuComponent,
		CampaignMenuComponent,
		ChipListComponent,
		ContextMenuComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		PickupSubheaderComponent,
		AnalyticsSubheaderComponent,
		HomeSubheaderComponent,
		CrmSubheaderComponent,
		CrmCreateSubheaderComponent,
		LanguageSelectorComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		CompanyBrandComponent,
		CartComponent,
		ErrorComponent,
		ThemeSwitchComponent,
		SettingSubheaderComponent,
		TitleOnlySubheaderComponent,
		EventSubheaderComponent,
		MobileTabBarComponent
	],
	exports: [
		WidgetModule,
		PortletModule,

		ScrollTopComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,

		// topbar components
		DateMonthMenuComponent,
		DropDownMenuComponent,
		CampaignMenuComponent,
		ChipListComponent,
		ContextMenuComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		PickupSubheaderComponent,
		AnalyticsSubheaderComponent,
		HomeSubheaderComponent,
		CrmSubheaderComponent,
		CrmCreateSubheaderComponent,
		SettingSubheaderComponent,
		LanguageSelectorComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		CompanyBrandComponent,
		CartComponent,
		ErrorComponent,
		ThemeSwitchComponent,
		TitleOnlySubheaderComponent,
		EventSubheaderComponent,
		MobileTabBarComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		TranslateModule,
		CoreModule,
		PortletModule,
		WidgetModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatSlideToggleModule,
		MbscModule,
		MenuModule
	]
})
export class PartialsModule {
}
