import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { HeatMapNewComponent } from './heatmap-new.component';
import { YearViewMonthComponent } from './year-view-month/year-view-month.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		HeatMapNewComponent,
		YearViewMonthComponent
	],
	exports: [
		HeatMapNewComponent,
		YearViewMonthComponent
	],
	imports: [
		CommonModule,
		ScrollingModule,
		NgbModule
	]
})
export class HeatmapModule {
}
