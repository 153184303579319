import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { AvailabilityUpdateDto } from '../_models/dtos/availability-update-dto.model';
import { HotelAvailabilityDto } from '../_models/dtos/hotel-availability-dto.model';
import { HotelAvailabilityDetailDto } from '../_models/dtos/hotel-availability-detail-dto.model';

@Injectable({
	providedIn: 'root'
})
export class AvailabilityService {

	constructor(private httpClient: HttpClient) { }

	getByHotelId(hotelId: number, fromDate: Date, toDate: Date, dateUpdated: Date = null): Observable<AvailabilityUpdateDto> {

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'));

		if (dateUpdated)
			params = params.set('updateTime', formatDate(dateUpdated, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'));

		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/availability`;
		return this.httpClient.get<AvailabilityUpdateDto>(apiUrl, { params }).pipe(retry(2));
	}

	getAll(fromDate: Date, toDate: Date, dateUpdated: Date = null): Observable<HotelAvailabilityDto[]> {

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'));

		if (dateUpdated)
			params = params.set('updateTime', formatDate(dateUpdated, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'));

		let apiUrl = `${environment.api.analytics}/availability`;
		return this.httpClient.get<HotelAvailabilityDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getOverview(fromDate: Date, toDate: Date, dateUpdated: Date = null, bestFlex: boolean = false, guests: number = 2, hotelIds: number[] = null, mealType: number = null): Observable<HotelAvailabilityDto[]> {

		let params = new HttpParams()
			.set('startDate', formatDate(fromDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(toDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('bestFlex', bestFlex ? 'true' : 'false')
			.set('guests', guests.toString());

		if (mealType >= 0) {
			params = params.set('mealType', mealType.toString());
		}

		if (dateUpdated)
			params = params.set('updateTime', formatDate(dateUpdated, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'));

		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => { params = params.append('hotelIds', id.toString()); });
		}

		let apiUrl = `${environment.api.analytics}/availability/overview`;
		return this.httpClient.get<HotelAvailabilityDto[]>(apiUrl, { params }).pipe(retry(2));
	}

	getDetail(hotelId: number, startDate: Date, endDate: Date, dateUpdated: Date = null, bestFlex: boolean = false, guests: number = 2, mealType: number = null): Observable<HotelAvailabilityDetailDto> {

		let params = new HttpParams()
			.set('startDate', formatDate(startDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('endDate', formatDate(endDate, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			.set('bestFlex', bestFlex ? 'true' : 'false')
			.set('guests', guests.toString());

		if (mealType >= 0) {
			params = params.set('mealType', mealType.toString());
		}

		if (dateUpdated)
			params = params.set('updateTime', formatDate(dateUpdated, 'yyyy-MM-ddTHH:mm', 'en-GB', 'UTC'));

		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/availability/detail`;
		return this.httpClient.get<HotelAvailabilityDetailDto>(apiUrl, { params }).pipe(retry(2));
	}
}
