import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable, empty } from "rxjs";
import { mergeMap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from "../auth/_services/auth.service";

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private auth: AuthService) { }

	/*intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError((err: any) => {
			if (err instanceof HttpErrorResponse && err.status === 401) {
				this.router.navigate(['/auth/login'], {
					queryParams: {
						redirectTo: document.location.pathname
					}
				});

				// this response is handled
				// stop the chain of handlers by returning empty
				return empty();
			}

			// rethrow so other error handlers may pick this up
			return throwError(err);
		}))
	}*/

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (req.url.includes('/api/authentication/token')) {
			return next.handle(req)
		}
		return this.auth.getTokenSilently$().pipe(
			mergeMap(token => {
				const tokenReq = req.clone({
					setHeaders: { Authorization: `Bearer ${token}` }
				});
				return next.handle(tokenReq);
			}),
			catchError(err => throwError(err))
		);
	}
}
