// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthService } from '../_services';
import { HelpService } from '../_services/help.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HelpGuard implements CanActivate {
	constructor(private auth: AuthService, private helpService: HelpService, private router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
		return this.helpService.getSupportToken().pipe(
			map(token => {
				if (this.router.url === '/') {
					window.location.href = `${environment.zendeskUrl}${token}`;
					return false;
				}

				window.open(`${environment.zendeskUrl}${token}`, '_blank');
				this.router.navigate(['/']);
				return false;
			})
		);
	}
}
