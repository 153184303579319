import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { HotelDto } from '../_models/dtos/hotel-dto.model';
import { RoomDto } from '../_models/dtos/room-dto.model';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class HotelService {
	private _hotels: HotelDto[] = null;
	private _hotelDate: moment.Moment = null;
	private readonly selectedCurrencyCode$ = new BehaviorSubject<string>('GBP');
	public currencyCode$ = this.selectedCurrencyCode$.asObservable();

	constructor(private httpClient: HttpClient) { }

	getAll(cached=true): Observable<HotelDto[]> {
		if (cached && this._hotels && this._hotelDate) {
			if (moment().diff(this._hotelDate, 'minutes') < 60)
				return of(JSON.parse(JSON.stringify(this._hotels)));
		}

		let apiUrl = `${environment.api.analytics}/hotel?cached=${cached ? "true" : "false"}`;
		return this.httpClient.get<HotelDto[]>(apiUrl).pipe(
			map(d => {
				if (!d) return null;

				return d.sort((a, b) => {
					if (a.group && b.group) {
						if (a.group.groupId === b.group.groupId) {
							if (a.order == b.order) {
								return a.name.localeCompare(b.name);
							} else {
								return a.order - b.order;
							}
						} else if (a.group.order === b.group.order) {
							return a.group.groupId - b.group.groupId;							
						} else {
							return a.group.order - b.group.order;
						}
					} else if (!a.group && !b.group) {
						return a.name.localeCompare(b.name);
					} else if (a.group) {
						return 1;
					} else {
						return -1;
					}
				});
			}),
			tap(d => {
				if (!d) return;
				this._hotels = JSON.parse(JSON.stringify(d));
				this._hotelDate = moment();
			},
			retry(2))
		);
	}

	getRooms(hotelId: number): Observable<RoomDto[]> {
		let apiUrl = `${environment.api.analytics}/hotel/${hotelId}/room`;
		return this.httpClient.get<RoomDto[]>(apiUrl);
	}

	getById(hotelId: number): Observable<HotelDto> {
		let apiUrl = `${environment.api.hotelSettings}/Hotel/${hotelId}`;
		return this.httpClient.get<HotelDto>(apiUrl);
	}

	update(hotelData: HotelDto): Observable<any> {
		let apiUrl = `${environment.api.hotelSettings}/Hotel`;
		return this.httpClient.put(apiUrl, hotelData);
	}

	getRanges(hotelId: number): Observable<HotelDto> {
		let apiUrl = `${environment.api.hotelSettings}/HotelRange/${hotelId}`;
		return this.httpClient.get<HotelDto>(apiUrl);
	}

	updateRange(hotelData: HotelDto): Observable<any> {
		let apiUrl = `${environment.api.hotelSettings}/HotelRange`;
		return this.httpClient.put(apiUrl, hotelData);
	}

	selectCurrencyCode(hotelId: number): void {
		if (!this._hotels) {
			return;
		}

		const selectedHotel = this._hotels.find(hotel => hotel.hotelId === hotelId)
		if (selectedHotel) {
			this.selectedCurrencyCode$.next(selectedHotel.currencyCode);
		}
	}
}
