// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Service
import { MenuConfigService } from './menu-config.service';
import { HotelService } from '../../../_services/hotel.service';
import { take } from 'rxjs/operators';

@Injectable()
export class MenuHorizontalService {
	// Public properties
	menuList$: BehaviorSubject<any> = new BehaviorSubject([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigServcie
	 */
	constructor(private menuConfigService: MenuConfigService, private hotelService: HotelService) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
		let menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'header.items');
		this.hotelService.getAll().pipe(take(1)).subscribe(hotels => {
			let allPermissions = [].concat(...hotels.map(h => h.permissions));
			menuItems = menuItems.filter(item => !item.permission || allPermissions.find(p => p.toLocaleLowerCase() == item.permission.toLocaleLowerCase()));
			let inactive = menuItems.filter(item => item.condition && !hotels.find(h => !!h[item.condition] || !!h.features[item.condition]));
			inactive.forEach(i => { i.inactive = true; });

			menuItems.forEach(parent => {
				if (!parent.submenu)
					return;
				parent.submenu = parent.submenu.filter(item => !item.permission || allPermissions.find(p => p.toLocaleLowerCase() == item.permission.toLocaleLowerCase()));

				inactive = parent.submenu.filter(item => item.condition && !hotels.find(h => !!h[item.condition] || !!h.features[item.condition]));
				inactive.forEach(i => { i.inactive = true; });
			});
			this.menuList$.next(menuItems);
		});
	}
}
