import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
  lang: "it",
  data: {
    TRANSLATOR: {
      SELECT: "Seleziona la lingua"
    },
    USER_PROFILE: {
      SETTINGS: "Impostazioni",
      HOTEL_OPTIONS: "Opzioni e configurazione hotel",
      HELP: "Guida e supporto",
      HELP_GUID: "Guida e supporto completo",
      THEME: "Tema",
      LANGUAGE: "Lingua",
      SIGN_OUT: "Esci",
      USERS: "Utenti",
      MANAGE_USERS: "Gestisci autorizzazioni di utenti e hotel"
    },
    CONNECTS: {
      MENU: {
        OVERVIEW: "Riepilogo",
        GUESTS_SEGMENTS: "Ospiti e segmenti",
        GUESTS: "Ospiti",
        SEGMENTS: "Segmenti",
        LISTS: "Liste",
        CAMPAIGNS: "Campagne",
        CAMPAIGN_LIST: "Lista campagna",
        TEMPLATES: "Modelli",
        REPORTS: "Report"
      },
      OVERVIEW: {
        TOTAL: "Profili totali",
        REACHABLE: "Profili raggiungibili",
        UNREACHABLE: "Profili non raggiungibili",
        EMAIL_COVERAGE: "Copertura e-mail",
        CAMPAIGN: "Ricavi camere campagna",
        YEAR: "Da inizio anno",
        ONE_TIME: "Ricavi campagna una tantum",
        LAST: "Ultimi 5 ricavi",
        ALL: "Visualizza tutto",
        LIFETIME: "Lifetime revenue",
        REVENUE: "Totale ricavi",
        ARRIVALS: "Arrivi",
        NUMBER_OF_GUESTS: "Numero di ospiti",
        ROOM_REVENUE: "Ricavi camera",
        NAME: "Nome",
        NIGHTS: "Notti",
        LIFETIME_VALUE: "Lifetime value",
        EMAIL: "E-mail",
        DEPARTURES: "Partenze"
      },
      GUESTS: {
        ALL_GUESTS: "Tutti gli ospiti",
        ARRIVALS: "Arrivi",
        HOUSE: "Check-in eseguito",
        DEPARTURES: "Partenze",
        SEARCH_PLACEHOLDER: "Cerca per nome, e-mail, riferimento",
        DATE_PLACEHOLDER: "Seleziona periodo",
        FILTERS: "Filtri",
        CHANGE_HOTEL: "Cambia hotel",
        SEARCH: "Cerca",
        CLEAR: "Cancella",
        GUEST: "Ospite",
        NIGHTS: "Notti",
        ROOM_TYPE: "Tipo di camera",
        REVENUE: "Ricavi",
        BOOKING: "Canale di prenotazione",
        RATE: "Codice tariffa",
        EMAIL: "E-mail",
        PREVIOUS: "Soggiorni precedenti",
        LIFETIME: "Lifetime value",
        NOTES: "Note",
        NO_RESULTS: "Nessun risultato trovato",
        ITEMS: "Elementi per pagina:"
      },
      SEGMENTS: {
        SEARCH_SEGMENTS: "Cerca per nome segmento",
        CREATE_SEGMENT: "Crea segmento ospiti",
        EDIT_SEGMENT: "Modifica segmento ospiti",
        REFRESH: "Ricarica ora",
        STATISTICS: "Ultime statistiche aggiornate",
        NO_RESULTS: "Nessun risultato trovato",
        REACHABLE: "Raggiungibili via e-mail",
        PROFILES: "Profili",
        AVARAGE_NIGHTS: "Media pernottamenti",
        AVARAGE_RATE: "Tariffa media giornaliera",
        OR: "OPPURE",
        EXCLUDE_BOOKINGS: "Escludi prenotazioni con soggiorni futuri",
        VIEW_DETAILS: "Mostra dettagli",
        SELECT: "Seleziona",
        PEOPLE_IN_SEGMENT: "persone in questo segmento",
        TOTAL_LIFETIME: "Totale spese lifetime",
        SEARCH_PLACEHOLDER: "Cerca per nome, e-mail, riferimento",
        SEARCH: "Cerca",
        CLEAR: "Cancella",
        GUEST: "Ospite",
        EMAIL: "E-mail",
        LIFETIME_VALUE: "Lifetime value",
        LIFETIME_STAYS: "Soggiorni lifetime",
        TOTAL_NIGHTS: "Totale notti",
        CLOSE: "Chiudi",
        ITEMS: "Elementi per pagina:",
        SEGMENT_NAME: "Nome segmento",
        SEGMENT_REQUIRED: "Il campo Nome segmento è obbligatorio",
        CONTACT_LISTS: "Lista contatti",
        ENTER_CONTACT: "Inserisci la lista contatti...",
        PROFILES_SEGMENT: "Profili in questo segmento",
        TOTAL_LIFETIME_UPPER: "Totale spese lifetime",
        REFRESH_STATICITCS: "Ricarica statistiche",
        FIND_GUESTS: "Trova ospiti corrispondenti alle seguenti condizioni",
        AND: "E",
        SEARCH_VALUE: "Inserisci valore di ricerca",
        ENTER_DISTANCE: "Inserisci distanza",
        IMPLICIT_CONSENT: "Consenso implicito (sicuro)",
        EXPLICIT_CONSENT: "Consenso esplicito (più sicuro)",
        DOUBLE_CONSENT: "Consenso double opt-in (massima sicurezza)",
        ENTER_DATE: "Inserisci un periodo",
        START_DATE: "Data iniziale",
        END_DATE: "Data finale",
        START_DAYS: "Giorni iniziali",
        END_DAYS: "Giorni finali",
        LAST_DAYS: "Ultimi giorni",
        EXCLUDE_GUESTS: "Escludi ospiti con soggiorni futuri",
        CORRECT_FILTERS: "Correggi gli errori nei filtri di segmento",
        CANCEL: "Annulla",
        SAVE: "Salva",
        BETWEEN_DATES: "tra le date",
        BETWEEN_FUTURE: "tra giorni futuri",
        DAYS: "giorni",
        BETWEEN_PAST: "tra giorni passati",
        LAST: "negli ultimi",
        EQUAL: "uguale a",
        NOT_EQUAL: "non uguale a",
        GREATER: "maggiore di",
        LESS: "minore di",
        KM: 'km',
        CONTAINS: "contiene",
        NOT_CONTAINS: "non contiene",
        IS_EQUAL: "è uguale a",
        IS_NOT_EQUAL: "non è uguale a",
        LEAST: "ha minimo",
        ANNIVERSARY: "Anniversario",
        BADGE: 'Badge',
        BIRTHDAY: "Compleanno",
        COMPANY: "Società",
        EMAIL_ADDRESS: "Indirizzo e-mail",
        GENDER: "Sesso",
        LANGUAGE: "Lingua",
        LIFETIME_ROOM: "Spesa camera lifetime",
        LOCATION: "Località",
        TOTAL_NUMBER_NIGHTS: "Totale n° pernottamenti",
        TOTAL_NUMBER_STAYS: "Totale n° soggiorni",
        SUBSCRIPTION: "Stato iscrizione",
        BOOKING: "Canale di prenotazione",
        BOOKING_DATE: "Data di prenotazione",
        BOOKING_LEAD: "Lead time di prenotazione",
        CHECK_IN_DATE: "Data di check-in",
        CHECK_OUT_DATE: "Data di check-out",
        LENGTH: "Durata del soggiorno",
        MARKET: "Codice mercato",
        RATE: "Codice tariffa",
        ROOM: "Codice camera",
        RATE_AVARAGE: "Media tariffe",
        SOURCE: "Origine della prenotazione",
        STAY: "Data del soggiorno",
        DEMOGRAPHICS: "Dati demografici",
        MARKETING: "E-mail marketing",
        STAY_DATA: "Dati del soggiorno",
        IMPORT_GUESTS: "Importa ospiti",
        ADD_LIST: "Aggiungi lista",
        HOTEL_NAME: "Nome hotel",
        GROUPWIDE: "A livello di gruppo",
        NAME: "Nome",
        GUESTS: "Ospiti",
        EDIT_LIST: "Modifica lista",
        UPDATE_LIST: "Aggiorna lista",
        LIST_NAME: "Nome lista",
        ROOM_REQUIRED: "Il campo Nome del tipo camera è obbligatorio",
        SELECT_HOTEL: "Seleziona hotel",
        SELECT_DATA: "Seleziona data",
        SELECT_LIST: "Seleziona lista",
        PLEASE_SELECT_LIST: "Seleziona una lista",
        SUBSCRIPTION_STATUS: "Stato iscrizione",
        UNSUBSCRIBED: "Iscrizione annullata",
        IMPLICIT: "Implicito",
        EXPLICIT: "Esplicito",
        DOUBLE_OPT_IN: "Double opt-in",
        PLEASE_SELECT_SUBSCRIPTION: "Seleziona un tipo di iscrizione",
        PLEASE_UPLOAD_FILE: "Carica un file",
        DOWNLOAD_EXAMPLE: "Scarica un file .csv esempio",
        NEXT: "Avanti",
        COMPLETE_MAPPINGS: "Completa abbinamenti",
        BACK: "Indietro",
        IMPORT: "Importa",
        ERROR_UPLOADING: "Spiacenti, si è verificato un errore durante il caricamento degli ospiti",
        UPLOAD_FILE: "Carica file",
        NO_FILE: "Nessun file selezionato"
      },
      GUESTS_PROFILE: {
        LEAD_TIME: "Lead time",
        LIFETIME_STAYS: "Soggiorni lifetime",
        LIFETIME_NIGHTS: "Pernottamenti lifetime",
        LIFETIME_SPEND: "Spesa lifetime",
        STATUS: "Stato",
        HOUSE: "Check-in eseguito",
        LAST_STAY: "Ultimo soggiorno",
        NEXT_STAY: "Prossimo soggiorno",
        NIGHTS: "Notti",
        TODAY: "Oggi",
        PERSONAL: "Personale",
        TITLE: "Titolo",
        FIRST_NAME: "Nome",
        LAST_NAME: "Cognome",
        GENDER: "Sesso",
        LANGUAGE: "Lingua",
        COMPANY: "Società",
        JOB_TITLE: "Titolo professionale",
        ADDRESS_1: "Indirizzo riga 1",
        ADDRESS_2: "Indirizzo riga 2",
        CITY_COUNTY: "Città, provincia",
        POST_CODE: "Codice postale",
        COUNTRY: "Paese",
        OTHER: "Altro",
        BIRTHDAY: "Compleanno",
        ANNIVERSARY: "Anniversario",
        PROFILE_NOTES: "Note profilo",
        EMAIL: "E-mail",
        SUBSCRIPTION_STATUS: "Stato iscrizione",
        BADGES: "Badge",
        CONTACT_LISTS: "Lista contatti",
        BOOKING_NOTES: "Note prenotazione",
        LOYALTY: "Fedeltà",
        FIRST_STAY: "Primo soggiorno con gruppo",
        LIFETIME_VALID_STAYS: "Soggiorni validi lifetime",
        LIFETIME_CANCELLED: "Soggiorni annullati lifetime",
        LIFETIME_VALID_NIGHTS: "Pernottamenti validi lifetime",
        CURRENT_STAY: "Soggiorno corrente",
        NO_STAYS: "Nessun soggiorno futuro",
        CHECK_IN: "Check-in",
        CHECK_OUT: "Check-out",
        OF_NIGHTS: "N° pernottamenti",
        BOOKING_REF: "Rif. prenotazione",
        HOTEL: 'Hotel',
        ROOM_TYPE: "Tipo di camera",
        RATE: "Tariffa",
        CHANNEL: "Canale",
        GUESTS: "Ospiti",
        ROOM: "Camera",
        BOOKING_DATE: "Data di prenotazione",
        NIGTH: "notte",
        ALL_STAYS: "Tutti i soggiorni",
        CANCELLED: "Annullati",
        IN_HOUSE: "Check-in eseguito",
        GUEST_MARKETING: "Guest marketing",
        DATE_SENT: "Data di invio",
        EVENT_DATE: "Data evento",
        CAMPAIGN: "Campagna",
        PENDING_REQUESTS: "Richieste in attesa",
        DATE: "Data",
        READ_ON: "Lette il",
        REQUESTS: "Richieste",
        TYPE: "Tipo",
        READ: "Lette",
        UNREAD: "Non lette",
        ADD_BADGE: "Aggiungi badge",
        BADGE: 'Badge',
        CANCEL: "Annulla",
        SAVE: "Salva",
        GUEST_EMAIL: "E-mail dell'ospite",
        ENTER_EMAIL: "Inserisci il nuovo indirizzo e-mail del profilo.",
        NOTE_PROFILE: "Attenzione: l'aggiornamento dell'indirizzo e-mail del profilo ospite potrebbe richiedere qualche minuto",
        EMAIL_REQUIRED: "Il campo E-mail è obbligatorio.",
        INVALID_EMAIL: "Indirizzo e-mail non valido.",
        EDIT_STATUS: "Modifica stato iscrizione",
        PLEASE_CONFIRM: "Conferma per modificare lo stato di iscrizione del profilo ospite",
        UNSUBSCRIBE: "Annulla iscrizione",
        NOTE_UNDO: "Attenzione: non è possibile annullare questa azione. L'iscrizione è ripristinabile solo dal lato ospite.",
        PROFILE: "Profilo",
        STAYS: "Soggiorni",
        ENGAGEMENT: "Coinvolgimento",
        IMPLICIT: "Consenso implicito",
        EXPLICIT: "Consenso esplicito",
        DOUBLE: "Double opt-in",
        UNSUBSCRIBED: "Iscrizione annullata"
      },
      REPORTS: {
        ONE_TIME: "Una tantum",
        STATS: "Statistiche",
        REVENUE_DETAIL: "Dettagli ricavi",
        AUTOMATED: "Automatizzati",
        SEARCH_CAMPAIGN_NAME: "Cerca per nome campagna",
        SEARCH: "Cerca",
        CLEAR: "Cancella",
        CAMPAIGN_NAME: "Nome campagna",
        BOOKINGS: "Prenotazioni",
        SENT: "Inviate",
        OPENED: "Aperte",
        CLICKED: "Cliccate",
        LAST_MODIFIED: "Ultima modifica",
        REPORT: 'Report',
        PREVIEW: "Anteprima",
        NO_RESULTS: "Nessun risultato trovato"
      },
      CREDITS: "Crediti e-mail",
      SUB_HEADER: {
        QUIT: "Esci",
        SAVE_QUIT: "Salva ed esci",
        SEND_TEST: "Invia una prova",
        SCHEDULE: "Programma",
        ACTIVATE: "Attiva",
        CANCEL_SCHEDULE: "Annulla programma",
        DEACTIVATE: "Disattiva",
        NEXT_STEP: "Passo successivo"
      },
      CAMPAIGNS: {
        REVENUE: "Ricavi",
        SEARCH_PLACEHOLDER: "Cerca per nome, e-mail, riferimento",
        RESERVATIONS: "Prenotazioni",
        SENT: "Inviate",
        OPENED: "Aperte",
        CLICKED: "Cliccate",
        DELIVERED: "Consegnate",
        BOUNCES: "Non recapitate",
        UNSUBSCRIBE: "Iscrizioni annullate",
        SPAM_REPORTS: "Report di spam",
        DELIVERY_REPORT: "Report di consegna",
        DOWNLOAD_REPORT: "Scarica report",
        DISPATCHED: "Inviati",
        GUEST: "Ospite",
        EMAIL: "E-mail",
        STATUS: "Stato",
        NO_RESULTS: "Nessun risultato trovato",
        ROOM_NIGHTS: "Pernottamenti in camera",
        OPENS: "Aperte",
        CLICKS: "Cliccate",
        TOP_CHANNELS: "Canali più popolari",
        TIME_SINCE_LAST_STAY: "Tempo trascorso dall'ultimo soggiorno",
        TOP_RATE_CODE_BY_CHANNEL: "Codice tariffa più popolare per canale",
        RATE_CODE: "Codice tariffa",
        TOTAL: "Totale",
        BOOKING_LEAD_TIMES: "Lead time di prenotazione",
        RESERVATION_REPORT: "Report prenotazione",
        BOOK_DATE: "Data di prenotazione",
        SEND_DATE: "Data di invio",
        NIGHTS: "Notti",
        BOOKING_CHANNEL: "Canale di prenotazione",
        TOTAL_RATE: "Tariffa totale",
        REPORTS: "Report campagna",
        ONE_TIME: "Una tantum",
        AUTOMATED: "Automatizzata",
        CREATE_CAMPAIGN: "Crea campagna",
        CREATE: "Crea",
        SEARCH_CAMPAIGN: "Cerca per nome campagna",
        SEARCH: "Cerca",
        CLEAR: "Cancella",
        ALL_STATUSES: "Tutti gli stati",
        DRAFT: "Bozza",
        SCHEDULED: "Programmate",
        INACTIVE: "Non attive",
        ACTIVE: "Attive",
        CAMPAIGN_NAME: "Nome campagna",
        TYPE: "Tipo",
        DELIVERY: "Consegna",
        LAST_UPDATED: "Ultimo aggiornamento",
        REPORT: 'Report',
        CANCEL_SCHEDULE: "Annulla programma",
        DEACTIVATE: "Disattiva",
        ACTIVATE: 'Activate',
        VIEW: "Visualizza",
        DUPLICATE: "Duplica",
        DELETE: "Elimina",
        DELIVERING: "Consegna in corso",
        SUSPENDED: "Sospese",
        REMOVE_CAMPAIGN: "Rimuovi campagna",
        DO_YOU_REMOVE_CAMPAIGN: "Vuoi davvero rimuovere questa campagna?",
        CANCEL_CAMPAIGN: "Annulla campagna",
        DO_YOU_CANCEL_CAMPAIGN: "Vuoi annullare la campagna programmata?",
        DEACTIVATE_CAMPAIGN: "Disattiva campagna",
        DO_YOU_DEACTIVATE_CAMPAIGN: "Vuoi disattivare la campagna?",
        ACTIVATE_CAMPAIGN: "Attiva campagna",
        DO_YOU_ACTIVATE_CAMPAIGN: "Vuoi attivare la campagna?",
        SETUP: "Configurazione",
        DESIGN: "Layout",
        SEGMENTS: "Segmenti",
        SCHEDULE: "Programma",
        CONFIRMATION: "Conferma",
        SELECT: "Seleziona",
        ROOM_OFFERS: "Offerte camere",
        CAMPAIGNS_DESCR: "Campagne progettate per aumentare i ricavi dalle camere attraverso offerte e pacchetti",
        EVENTS_ANNOUNCEMENTS: "Eventi e annunci",
        NOTIFY_RECIPIENTS: "Informano i destinatari di eventi speciali che si terranno nella proprietà",
        NEWSLETTER: 'Newsletter',
        CAMPAIGNS_UPDATE_DESCR: "Campagne per aggiornare i destinatari su eventi o altre novità dell'hotel, a cadenza regolare",
        MISS_YOU: "Ci manchi",
        CAMPAGINS_SENT: "Campagne da inviare agli ospiti dopo il loro soggiorno per invogliarli a fare un'altra prenotazione",
        OTHER: "Altro",
        CREATE_CUSTOM: "Crea una campagna personalizzata",
        PRE_ARRIVAL: "Prima dell'arrivo",
        SEND_EMAIL: "Invia un'e-mail agli ospiti prima del loro arrivo",
        WELCOME: "Benvenuto",
        SEND_EMAIL_CHECK_IN: "Invia un'e-mail agli ospiti al momento del check-in",
        QUALIFICATION: "Qualifica",
        SEND_EMAIL_SOON: "Invia un'e-mail all'ospite appena viene inserito in un segmento. Riceverà un'unica notifica",
        SENT_TO_GUESTS: "Da inviare agli ospiti dopo il loro soggiorno per invogliarli a tornare",
        OTA_WINBACK: "Winback OTA",
        SEND_CAMPAIGN: "Invia una campagna agli ospiti che hanno prenotato con agenzie di viaggio online entro 30 giorni dal check-out",
        BIRTHDAY: "Compleanno",
        SEND_SPECIAL: "Invia un'offerta speciale in occasione di un compleanno o un anniversario",
        DOUBLE_OPT: "Double opt-in",
        AUTOMATICALLY_SEND: "Invia automaticamente un link di iscrizione agli ospiti quando vengono aggiunti a una lista di contatti",
        CAMPAIGN_NAME_HINT: "Dai alla campagna un nome a uso interno all'account per organizzarla e trovarla più facilmente.",
        CAMPAIGN_NAME_REQUIRED: "Il campo Nome campagna è obbligatorio",
        SUBJECT_LINE: "Riga oggetto",
        SUBJECT_LINE_HINT: "Scrivi un oggetto che descriva chiaramente il contenuto dell'e-mail. Sarà il primo contenuto che il destinatario leggerà nella casella di posta in arrivo.",
        SUBJECT_REQUIRED: "Il campo Oggetto è obbligatorio",
        PREVIEW_LINE: "Riga anteprima",
        PREVIEW_LINE_HINT: "Scrivi un breve testo (35 caratteri circa) che riassuma il contenuto dell'e-mail. Questo testo verrà visualizzato nella casella di posta in arrivo, immediatamente sotto l'oggetto.",
        FROM_EMAIL: "Da e-mail",
        FROM_EMAIL_HINT: "Scegli l'indirizzo e-mail che verrà visualizzato nella casella di posta in arrivo del destinatario per le e-mail di questa campagna.",
        FROM_EMAIL_REQUIRED: "Il campo Da e-mail è obbligatorio",
        FROM_NAME: "Da nome",
        FROM_NAME_HINT: "Inserisci un nome (es. il nome della tua azienda) che aiuti i destinatari a riconoscerti quando vedono l'e-mail nella propria casella di posta in arrivo.",
        FROM_NAME_REQUIRED: "Il campo Da nome è obbligatorio",
        MY_TEMPLATES: "I miei modelli",
        TEMPLATE_GALLERY: "Galleria modelli",
        DESIGN_TOOLS: "Strumenti di progettazione",
        DRAG_DROP_EDITOR: "Editor drag & drop",
        DRAG_DROP_EDITOR_DESC: "Realizza un'e-mail compatibile con dispositivi mobili che si legga perfettamente su schermi di ogni dimensione",
        RICH_TEXT_EDITOR: "Editor RTF",
        RICH_TEXT_EDITOR_DESC: "Usa l'editor che supporta il formato rich text per le e-mail più semplici",
        PASTE_YOUR_CODE: "Incolla il tuo codice",
        PASTE_YOUR_CODE_DESC: "Copia e incolla il codice HTML.",
        RESET_EMAIL_DESIGN: "Ripristina progetto e-mail",
        EDIT_EMAIL_CONTENT: "Modifica contenuto e-mail",
        LOAD_SAVED_VERSION: "Carica una versione salvata",
        VERSION: "Versione",
        SAVE: "Salva",
        SAVE_QUIT: "Salva ed esci",
        QUIT_WITHOUT_SAVING: "Esci senza salvare",
        HTML_EDITOR: "Editor HTML",
        ENTER_HTML: "Inserisci codice HTML",
        CANCEL: "Annulla",
        CHOOSE_DIFFERENT_SEGMENT: "Scegli un altro segmento",
        ADDITIONAL_OPTIONS: "Opzioni aggiuntive",
        FILTER_PROPERTIES: "Proprietà filtro",
        ALL_PROPERTIES: "Tutte le proprietà",
        ENTER_PROPERTY_TO_FILTER: "Inserisci la proprietà per filtrare...",
        ONLY_INCLUDE_GUESTS_WHO_HAVE: "Includi solo ospiti che",
        RECEIVED: "hanno ricevuto",
        NOT_RECEIVED: "non hanno ricevuto",
        OPENED_LOWER: "hanno aperto",
        NOT_OPENED: "non hanno aperto",
        CLICKED_LOWER: "hanno cliccato",
        NOT_CLICKED: "non hanno cliccato",
        BEEN_SENT: "sono state inviate",
        NOT_SENT: "non sono state inviate",
        ANY_EXISTING_CAMPAIGN_IN_LAST: "qualsiasi campagna esistente nel periodo",
        DAY: "ultimo giorno",
        WEEK: "ultima settimana",
        TWO_WEEKS: "ultime due settimane",
        MONTH: "ultimo mese",
        YEAR: "ultimo anno",
        TWO_YEARS: "ultimi due anni",
        ENABLE_SAFE_MODE: "Abilita modalità sicura",
        BEST_PRACTICE: "Buone pratiche GDPR",
        GUESTS_MUST_HAVE: "Gli ospiti devono avere",
        IMPLICIT_CONSENT: "Consenso implicito",
        SAFE: "sicuro",
        EXPLICIT_CONSENT: "Consenso esplicito",
        SAFER: "più sicuro",
        DOUBLE_OPT_IN_CONSENT: "Consenso double opt-in",
        SAFEST: "massima sicurezza",
        EXCLUDE_EMAIL_FUTURE_STAYS: "Escludi indirizzi e-mail con soggiorni futuri",
        REFRESH_STATISTICS: "Ricarica statistiche",
        SELECT_SCHEDULE_OPTION: "Seleziona un'opzione di programmazione per la campagna:",
        SPECIFIC_DATE_TIME: "Data e ora specifici",
        IMMEDIATELY: "Immediatamente",
        BASED_ON_GUEST_EVENT: "In base a evento ospite",
        AT: "alle",
        HH: 'HH',
        MM: 'MM',
        ON: "il",
        HOUR_REQUIRED: "Il campo Ora è obbligatorio",
        MINUTES_REQUIRED: " Il campo Minuti è obbligatorio",
        SELECT_DATE: "Seleziona una data",
        DATE_REQUIRED: "Il campo Data è obbligatorio",
        DATE_MUST_BE_FUTURE: "La data deve essere una data futura",
        SELECT_DAYS: "Seleziona giorni",
        DAYS_REQUIRED: "Il campo Giorni è obbligatorio",
        DAYS: "giorni",
        SELECT_EVENT: "Seleziona evento",
        EVENT_REQUIRED: "Il campo Evento è obbligatorio",
        SELECT_LIST: "Seleziona lista",
        LIST_REQUIRED: "Il campo Lista è obbligatorio",
        CAMPAIGN_READY: "La campagna è pronta all'invio!",
        REVIEW_REPORT_BEFORE_SENDING: "Prima di inviare la campagna, esamina il report sottostante",
        SUBJECT: "Oggetto",
        FROM: "Da",
        ROOM_REVENUE_TRACKING: "Tracciamento ricavi camera",
        DOUBLE_OPT_IN_URL: "URL double opt-in",
        DOUBLE_OPT_IN_URL_HINT: "L'URL a cui l'utente viene reindirizzato durante il flusso di double opt-in.",
        DOUBLE_OPT_IN_URL_REQUIRED: "Il campo URL double opt-in è obbligatorio",
        VALID_URL_REQUIRED: "Inserisci un URL valido",
        YOUR_DESIGN: "Il tuo progetto",
        USED: "Hai usato",
        CREATE_DESIGN: "per creare il progetto",
        EDIT_CONTENT: "Modifica il contenuto",
        ADDITIONAL_FILTERS: "Filtri aggiuntivi",
        SEND_IMMEDIATELY: "Invia immediatamente",
        SEND_TEST_EMAIL: "Invia e-mail di prova",
        ENTER_EMAILS: "Inserisci e-mail",
        ENTER_EMAILS_DESC: "Inserisci una lista di indirizzi e-mail, un indirizzo per riga",
        SEND_TEST: "Invia prova",
        SCHEDULE_CAMPAIGN: "Programma campagna",
        SURE_DELIVERY: "Intendi davvero programmare la campagna per l'invio?",
        CAMPAIGN_IN_LAST: " qualsiasi campagna nel periodo ",
        SEARCH_TEMPLATE_NAME: "Cerca per nome modello",
        CREATE_TEMPLATE: "Crea modello",
        EDIT_CAMPAIGN: "Modifica campagna",
        TEMPLATE_NAME: "Nome modello",
        EDIT_TEMPLATE: "Modifica modello",
        MORE_OPTIONS: "Altre opzioni",
        TEMPLATE_NAME_HINT: "Dai al modello un nome a uso interno all'account per organizzarlo e trovarlo più facilmente.",
        TEMPLATE_NAME_REQUIRED: "Il campo Nome modello è obbligatorio",
        LIST_SELECTED: "Lista selezionata",
        LISTS_SELECTED: "Liste selezionate",
        ALL_LISTS_SELECTED: "Tutte le liste selezionate",
        BEFORE_CHECK_IN: "prima del check-in",
        AFTER_CHECK_IN: "dopo il check-in",
        BEFORE_CHECK_OUT: "prima del check-out",
        AFTER_LAST_CHECK_OUT: "dopo l'ultimo check-out",
        ON_CHECK_OUT: "al check-out",
        ON_CHECK_IN: "al check-in",
        ON_DAY_OF_CHECK_IN: "il giorno del check-in",
        BEFORE_BIRTHDAY: "prima del compleanno",
        BEFORE_ANNIVERSARY: "prima dell'anniversario",
        ON_LIST_JOINED: "all'inserimento in lista",
        AT_SPASE: "alle ",
        UNLAYER_LANGUAGE: "it-IT",
        EDITOR_LANGUAGE: "it"
      }
    },
    MENU: {
      NEW: "novità",
      ACTIONS: "Azioni",
      CREATE_POST: "Crea nuovo post",
      PAGES: "Pagine",
      FEATURES: "Funzionalità",
      APPS: "App",
      HOME: "Pagina iniziale"
    },
    AUTH: {
      GENERAL: {
        OR: "Oppure",
        SUBMIT_BUTTON: "Invia",
        NO_ACCOUNT: "Non hai un account?",
        SIGNUP_BUTTON: "Registrati",
        FORGOT_BUTTON: "Password dimenticata",
        BACK_BUTTON: "Indietro",
        PRIVACY: 'Privacy',
        LEGAL: "Note legali",
        CONTACT: "Contatto"
      },
      LOGIN: {
        TITLE: "Accedi all'account",
        BUTTON: "Accedi"
      },
      FORGOT: {
        TITLE: "Hai dimenticato la password?",
        DESC: "Inserisci il tuo indirizzo e-mail per ripristinare la password",
        SUCCESS: "L'account è stato correttamente reimpostato."
      },
      REGISTER: {
        TITLE: "Registrati",
        DESC: "Inserisci i tuoi dati per creare un account",
        SUCCESS: "L'account è stato correttamente registrato."
      },
      INPUT: {
        EMAIL: 'E-mail',
        FULLNAME: "Nome",
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: "Conferma password",
        USERNAME: "Nome utente"
      },
      VALIDATION: {
        INVALID: "Il campo {{name}} non è valido",
        REQUIRED: "Il campo {{name}} è obbligatorio",
        MIN_LENGTH: "La lunghezza minima consentita per {{name}} è di {{min}}",
        AGREEMENT_REQUIRED: "Per continuare, accetta i Termini e condizioni",
        NOT_FOUND: "Nessun risultato per {{name}}",
        INVALID_LOGIN: "Credenziali di login errate",
        REQUIRED_FIELD: "Campo obbligatorio",
        MIN_LENGTH_FIELD: "Lunghezza minima del campo:",
        MAX_LENGTH_FIELD: "Lunghezza massima del campo:",
        INVALID_FIELD: "Il campo non è valido"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Conteggio elementi selezionati: ",
        ALL: "Tutto",
        SUSPENDED: "Sospesi",
        ACTIVE: "Attivi",
        FILTER: "Filtro",
        BY_STATUS: "Per stato",
        BY_TYPE: "Per tipo",
        BUSINESS: "Azienda",
        INDIVIDUAL: "Persona fisica",
        SEARCH: "Cerca",
        IN_ALL_FIELDS: "in tutti i campi"
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: "Clienti",
        CUSTOMERS_LIST: "Lista clienti",
        NEW_CUSTOMER: "Nuovo cliente",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Eliminazione cliente",
          DESCRIPTION: "Intendi davvero eliminare permanentemente questo cliente?",
          WAIT_DESCRIPTION: "Eliminazione cliente in corso...",
          MESSAGE: "Il cliente è stato eliminato"
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Eliminazione clienti",
          DESCRIPTION: "Intendi davvero eliminare permanentemente i clienti selezionati?",
          WAIT_DESCRIPTION: "Eliminazione clienti in corso...",
          MESSAGE: "I clienti selezionati sono stati eliminati"
        },
        UPDATE_STATUS: {
          TITLE: "Stato aggiornato per i clienti selezionati",
          MESSAGE: "Lo stato dei clienti selezionati è stato aggiornato correttamente"
        },
        EDIT: {
          UPDATE_MESSAGE: "Il cliente è stato aggiornato",
          ADD_MESSAGE: "Il cliente è stato creato"
        }
      }
    },
    Jan: "Gen",
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: "Mag",
    Jun: "Giu",
    Jul: "Lug",
    Aug: "Ago",
    Sep: "Set",
    Oct: "Ott",
    Nov: 'Nov',
    Dec: "Dic",
    Clicks: "Cliccate",
    Opens: "Aperte",
    '< 7 days': "< 7 giorni",
    '7-14 Days': "7-14 giorni",
    '15-30 Days': "15-30 giorni",
    '31-60 Days': "31-60 giorni",
    '61-90 Days': "61-90 giorni",
    '> 90 Days': "> 90 giorni",
    '< 1 month': "< 1 mese",
    '1-2 Months': "1-2 mesi",
    '2-6 Months': "2-6 mesi",
    '6-12 Months': "6-12 mesi",
    '> 1 Year': "> 1 anno",
    Sent: "Inviate",
    Delivered: "Consegnate",
    Open: "Aperte",
    Spam: 'Spam',
    Click: "Cliccate",
    HardBounce: "Mancato recapito permanente",
    SoftBounce: "Mancato recapito temporaneo",
    Unsubscribe: "Iscrizioni annullate",
    InvalidEmail: "E-mail non valida",
    Blocked: "Bloccate",
    Complaint: "Reclamo",
    Deferred: "Posticipate",
    ListAddition: "Aggiunti alla lista",
    Overview: "Riepilogo",
    "Guest List": "Lista ospiti",
    "Guest Lists": "Liste ospiti",
    "Guest Segments": "Segmenti ospiti",
    Campaigns: "Campagne",
    Templates: "Modelli"
  }
};