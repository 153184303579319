import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutConfigService } from './layout-config.service';
import { Theme } from '../models/theme';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../../../_services/user.service';

@Injectable()
export class ThemeService {
	private themeSubject = new BehaviorSubject<Theme>(Theme.None);
	currentTheme = this.themeSubject.asObservable();
	singleTheme = false;

	constructor(private layoutConfigService: LayoutConfigService, private userService: UserService, @Inject(DOCUMENT) private document: Document) {
		this.setTheme(this.layoutConfigService.getConfig('brand.custom.skin') || Theme.Light, true);
	}

	setTheme(theme: Theme, initial = false, forceSingle = false) {
		if (theme === this.themeSubject.value)
			return;

		if (this.singleTheme)
			return;

		if (!initial)
			this.userService.updateSettings({ theme }).subscribe();

		this.singleTheme = forceSingle;
		this.setLayoutSkin(theme);
		this.setDocumentBody(theme);
		this.themeSubject.next(theme);
	}

	private setDocumentBody(theme) {
		this.document.body.classList.remove('light-theme', 'dark-theme', 'blue-theme', 'purple-theme', 'yellow-theme');
		this.document.body.classList.add(theme + '-theme');
	}

	private setLayoutSkin(theme) {
		this.layoutConfigService.setConfig({
			'brand': {
				'self': {
					'skin': ((theme == "light") || (theme == "yellow")) ? theme : "dark"
				},
				'custom': {
					'skin': theme
				}
			}
		}, true);
	}
}
