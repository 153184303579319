// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	isMockEnabled: false,
	authConfig: {
		realm: 'Username-Password-Authentication',
		domain: "login.hotel.cloud",
		clientId: "3XIPK62J8Khj5ul3ntrs8ExIBR2y8xJo",
		audience: "api.hotel.cloud"
	},
	oktaConfig: {
		clientId: '0oag5tq0g9MFevSUN1d7',
		issuerUrl: 'https://idp.cloudbeds-dev.com/oauth2/aush1s13nSzzePM9d1d6',
		authorizationUrl: 'https://idp.cloudbeds-dev.com/oauth2/aush1s13nSzzePM9d1d6/v1/authorize',
		logoutRedirectUrl: 'https://hotels.cloudbeds-dev.com/auth/logout'
	},
	cbLoginUrl: "https://hotels.cloudbeds.com/",
	mapApiKey: "AIzaSyDFOxdKXrk7XnoKnOjChMri4R7f9vahMp4",
	api: {
		analytics: 'https://analyticsapi.dev.hotel.cloud/api/v1',
		hotelSettings: 'https://rateapi.dev.hotel.cloud/api',
		usersSettings: 'https://userapi.dev.hotel.cloud/api',
		events: 'https://eventapi.dev.hotel.cloud/api/v1',
		opportunities: 'https://opportunityapi-prod.hotel.cloud/api/v1',
		crm: 'https://crmapi.dev.hotel.cloud/api/v1',
		voice: 'https://voiceapi-prod.hotel.cloud/api'
	},
	agGridKey: 'Using_this_{AG_Grid}_Enterprise_key_{AG-069257}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Digital_Arbitrage_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Hotel_Cloud}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Hotel_Cloud}_need_to_be_licensed___{Hotel_Cloud}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_October_2025}____[v3]_[01]_MTc2MDMxMDAwMDAwMA==d1a6b594764cc636b154bf5eda39eeaf',
	zendeskAccount: 'support.hotel.cloud',
	zendeskUrl: 'https://hoteldynamics.zendesk.com/access/jwt?jwt='
};
