import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { UserDto } from '../_models/dtos/user-dto.model';
import { UserSettings } from '../_models/user-settings.model';
import { environment } from '../../../environments/environment';
import { PagedList } from '../_models/dtos/paged-list.model';
import { SortOrder } from '../_models/sort-order.enum';


@Injectable({
	providedIn: 'root'
})
export class UserService {
	

	constructor(private httpClient: HttpClient) {
		
	}

	getAll(): Observable<UserDto[]> {
		let apiUrl = `${environment.api.usersSettings}/Users`;
		return this.httpClient.get<UserDto[]>(apiUrl);
	}

	getAllPaged(hotelId: number = null, searchText: string = null, pageIndex: number = 1, pageSize: number = 20, sortFilter = "email", sortOrder: SortOrder = SortOrder.Ascending): Observable<PagedList<UserDto>> {

		let params = new HttpParams()
			.set('pageNumber', pageIndex.toString())
			.set('pageSize', pageSize.toString())
			.set('sortFilter', sortFilter)
			.set('sortOrder', sortOrder.toString());

		if (hotelId) {
			params = params.set('hotelId', hotelId.toString());
		}
		if (searchText !== null) {
			params = params.set('searchText', searchText);
		}

		const apiUrl = `${environment.api.usersSettings}/Users/paged`;
		return this.httpClient.get<PagedList<UserDto>>(apiUrl, { params });
	}

	add(userData: any): Observable<any> {
		let apiUrl = `${environment.api.usersSettings}/Users`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post(apiUrl, userData, httpOptions);
	}

	update(userData: any): Observable<any> {
		let apiUrl = `${environment.api.usersSettings}/Users`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.put(apiUrl, userData, httpOptions);
	}

	delete(userId: string): Observable<any> {
		let apiUrl = `${environment.api.usersSettings}/Users/${userId}`;
		return this.httpClient.delete(apiUrl);
	}

	updateSettings(settings: UserSettings) {
		let apiUrl = `${environment.api.usersSettings}/Settings`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.put(apiUrl, settings, httpOptions);
	}

	getSettings(): Observable<UserSettings> {
		let apiUrl = `${environment.api.usersSettings}/Settings`;
		return this.httpClient.get<UserSettings>(apiUrl);
	}
}
