import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
  lang: "pt",
  data: {
    TRANSLATOR: {
      SELECT: "Selecione seu idioma"
    },
    USER_PROFILE: {
      SETTINGS: "Configurações",
      HOTEL_OPTIONS: "Opções e configurações do hotel",
      HELP: "Ajuda e Assistência",
      HELP_GUID: "Guia de ajuda completo e assistência",
      THEME: "Tema",
      LANGUAGE: "Idioma",
      SIGN_OUT: "Sair",
      USERS: "Usuários",
      MANAGE_USERS: "Gerencie usuários e permissões do hotel"
    },
    CONNECTS: {
      MENU: {
        OVERVIEW: "Visão Geral",
        GUESTS_SEGMENTS: "Segmentos de hóspedes",
        GUESTS: "Hóspedes",
        SEGMENTS: "Segmentos",
        LISTS: "Listas",
        CAMPAIGNS: "Campanhas",
        CAMPAIGN_LIST: "Lista de campanhas",
        TEMPLATES: "Modelos",
        REPORTS: "Relatórios"
      },
      OVERVIEW: {
        TOTAL: "Total de perfis",
        REACHABLE: "Perfis acessíveis",
        UNREACHABLE: "Perfis inacessíveis",
        EMAIL_COVERAGE: "Cobertura do e-mail",
        CAMPAIGN: "Receita por quarto da campanha",
        YEAR: "Acumulado do ano",
        ONE_TIME: "Receita de uma campanha única",
        LAST: "Últimas 5 receitas",
        ALL: "Ver tudo",
        LIFETIME: "Receitas do ciclo de vida",
        REVENUE: "Receita total",
        ARRIVALS: "Chegadas",
        NUMBER_OF_GUESTS: "Número de hóspedes",
        ROOM_REVENUE: "Receita da acomodação",
        NAME: "Nome",
        NIGHTS: "Pernoites",
        LIFETIME_VALUE: "Valor do ciclo de vida",
        EMAIL: "E-mail",
        DEPARTURES: "Saídas"
      },
      GUESTS: {
        ALL_GUESTS: "Todos os hóspedes",
        ARRIVALS: "Chegadas",
        HOUSE: "Hospedados",
        DEPARTURES: "Saídas",
        SEARCH_PLACEHOLDER: "Pesquisar por nome, e-mail, referência",
        DATE_PLACEHOLDER: "Selecionar intervalo de datas",
        FILTERS: "Filtros",
        CHANGE_HOTEL: "Mudar de hotel",
        SEARCH: "Pesquisar",
        CLEAR: "Remover",
        GUEST: "Hóspede",
        NIGHTS: "Pernoites",
        ROOM_TYPE: "Tipo de acomodação",
        REVENUE: "Receita",
        BOOKING: "Canal de reserva",
        RATE: "Código da tarifa",
        EMAIL: "E-mail",
        PREVIOUS: "Estadias anteriores",
        LIFETIME: "Valor do ciclo de vida",
        NOTES: "Observações",
        NO_RESULTS: "Não foram encontrados resultados",
        ITEMS: "Itens por página:"
      },
      SEGMENTS: {
        SEARCH_SEGMENTS: "Pesquisar pelo nome do segmento",
        CREATE_SEGMENT: "Criar segmento de hóspedes",
        EDIT_SEGMENT: "Editar segmento de hóspedes",
        REFRESH: "Atualizar agora",
        STATISTICS: "Última atualização das estatísticas",
        NO_RESULTS: "Não foram encontrados resultados",
        REACHABLE: "Contato por e-mail",
        PROFILES: "Perfis",
        AVARAGE_NIGHTS: "Média de pernoites",
        AVARAGE_RATE: "Tarifa média diária",
        OR: "OU",
        EXCLUDE_BOOKINGS: "Excluir reservas com estadias futuras",
        VIEW_DETAILS: "Ver Detalhes",
        SELECT: "Selecionar",
        PEOPLE_IN_SEGMENT: "pessoas nesse segmento",
        TOTAL_LIFETIME: "gasto total do ciclo de vida",
        SEARCH_PLACEHOLDER: "Pesquisar por nome, e-mail, referência",
        SEARCH: "Pesquisar",
        CLEAR: "Remover",
        GUEST: "Hóspede",
        EMAIL: "E-mail",
        LIFETIME_VALUE: "Valor do ciclo de vida",
        LIFETIME_STAYS: "Estadias do ciclo de vida",
        TOTAL_NIGHTS: "Total de pernoites",
        CLOSE: "Fechar",
        ITEMS: "Itens por página:",
        SEGMENT_NAME: "Nome do segmento",
        SEGMENT_REQUIRED: "É necessário o nome do segmento",
        CONTACT_LISTS: "Listas de contato",
        ENTER_CONTACT: "Digite a lista de contatos...",
        PROFILES_SEGMENT: "Perfis neste segmento",
        TOTAL_LIFETIME_UPPER: "Gasto total do ciclo de vida",
        REFRESH_STATICITCS: "Atualizar estatísticas",
        FIND_GUESTS: "Encontre hóspedes que atendam às seguintes condições",
        AND: "E",
        SEARCH_VALUE: "Digite o valor da pesquisa",
        ENTER_DISTANCE: "Digite a distância",
        IMPLICIT_CONSENT: "Consentimento implícito (seguro)",
        EXPLICIT_CONSENT: "Consentimento explícito (mais seguro)",
        DOUBLE_CONSENT: "Consentimento para dupla aceitação (mais seguro)",
        ENTER_DATE: "Digite um intervalo de datas",
        START_DATE: "Data de início",
        END_DATE: "Data final",
        START_DAYS: "Dias iniciais",
        END_DAYS: "Últimos dias",
        LAST_DAYS: "Últimos dias",
        EXCLUDE_GUESTS: "Excluir hóspedes com estadias futuras",
        CORRECT_FILTERS: "Corrija os erros nos filtros dos segmentos",
        CANCEL: "Cancelar",
        SAVE: "Salvar",
        BETWEEN_DATES: "entre as datas",
        BETWEEN_FUTURE: "entre os dias seguintes",
        DAYS: "dias",
        BETWEEN_PAST: "entre os dias anteriores",
        LAST: "nos últimos",
        EQUAL: "igual a",
        NOT_EQUAL: "diferente de",
        GREATER: "maior que",
        LESS: "menos que",
        KM: 'km',
        CONTAINS: "contém",
        NOT_CONTAINS: "não contém",
        IS_EQUAL: "for igual a",
        IS_NOT_EQUAL: "não for igual a",
        LEAST: "tenha ao menos",
        ANNIVERSARY: "Aniversário",
        BADGE: "Selo",
        BIRTHDAY: "Aniversário",
        COMPANY: "Empresa",
        EMAIL_ADDRESS: "Endereço do e-mail",
        GENDER: "Gênero",
        LANGUAGE: "Idioma",
        LIFETIME_ROOM: "Gastos por quarto do ciclo de vida",
        LOCATION: "Local",
        TOTAL_NUMBER_NIGHTS: "No. total de pernoites",
        TOTAL_NUMBER_STAYS: "No. total de estadias",
        SUBSCRIPTION: "Status da assinatura",
        BOOKING: "Canal de reserva",
        BOOKING_DATE: "Data da reserva",
        BOOKING_LEAD: "Tempo de espera da reserva",
        CHECK_IN_DATE: "Data de check-in",
        CHECK_OUT_DATE: "Data de check-out",
        LENGTH: "Duração da estadia",
        MARKET: "Código de mercado",
        RATE: "Código da tarifa",
        ROOM: "Código da acomodação",
        RATE_AVARAGE: "Tarifa média",
        SOURCE: "Origem do negócio",
        STAY: "Data da estadia",
        DEMOGRAPHICS: "Dados demográficos",
        MARKETING: "Marketing por e-mail",
        STAY_DATA: "Dados da estadia",
        IMPORT_GUESTS: "Importar hóspedes",
        ADD_LIST: "Adicionar lista",
        HOTEL_NAME: "Nome do hotel",
        GROUPWIDE: "Para todo o grupo",
        NAME: "Nome",
        GUESTS: "Hóspedes",
        EDIT_LIST: "Editar lista",
        UPDATE_LIST: "Atualizar lista",
        LIST_NAME: "Nome da lista",
        ROOM_REQUIRED: "É necessário o nome do tipo de acomodação",
        SELECT_HOTEL: "Selecionar hotel",
        SELECT_DATA: "Selecionar dados",
        SELECT_LIST: "Selecionar lista",
        PLEASE_SELECT_LIST: "Selecione uma lista",
        SUBSCRIPTION_STATUS: "Status da assinatura",
        UNSUBSCRIBED: "Assinatura cancelada",
        IMPLICIT: "Implícito",
        EXPLICIT: "Explícito",
        DOUBLE_OPT_IN: "Dupla aceitação",
        PLEASE_SELECT_SUBSCRIPTION: "Selecione um status para a assinatura",
        PLEASE_UPLOAD_FILE: "Faça o upload de um arquivo",
        DOWNLOAD_EXAMPLE: "Faça o download de um arquivo .csv de exemplo",
        NEXT: "Avançar",
        COMPLETE_MAPPINGS: "Completar mapeamentos",
        BACK: "Voltar",
        IMPORT: "Importar",
        ERROR_UPLOADING: "Desculpe, houve um erro ao fazer o upload dos hóspedes",
        UPLOAD_FILE: "Fazer upload de arquivo",
        NO_FILE: "Nenhum arquivo foi escolhido"
      },
      GUESTS_PROFILE: {
        LEAD_TIME: "Ciclo completo do processo",
        LIFETIME_STAYS: "Estadias do ciclo de vida",
        LIFETIME_NIGHTS: "Pernoites do ciclo de vida",
        LIFETIME_SPEND: "Gastos do ciclo de vida",
        STATUS: 'Status',
        HOUSE: "Hospedados",
        LAST_STAY: "Última estadia",
        NEXT_STAY: "Próxima estadia",
        NIGHTS: "Pernoites",
        TODAY: "Hoje",
        PERSONAL: "Particular",
        TITLE: "Título",
        FIRST_NAME: "Nome",
        LAST_NAME: "Sobrenome",
        GENDER: "Gênero",
        LANGUAGE: "Idioma",
        COMPANY: "Empresa",
        JOB_TITLE: "Cargo",
        ADDRESS_1: "Endereço 1",
        ADDRESS_2: "Endereço 2",
        CITY_COUNTY: "Cidade, País",
        POST_CODE: "CEP/Código Postal",
        COUNTRY: "País",
        OTHER: "Outro",
        BIRTHDAY: "Aniversário",
        ANNIVERSARY: "Aniversário",
        PROFILE_NOTES: "Notas do perfil",
        EMAIL: "E-mail",
        SUBSCRIPTION_STATUS: "Status da assinatura",
        BADGES: "Selos",
        CONTACT_LISTS: "Listas de contato",
        BOOKING_NOTES: "Notas da reserva",
        LOYALTY: "Fidelidade",
        FIRST_STAY: "Primeira estadia com o grupo",
        LIFETIME_VALID_STAYS: "Estadias válidas do ciclo de vida",
        LIFETIME_CANCELLED: "Estadias válidas do ciclo de vida",
        LIFETIME_VALID_NIGHTS: "Pernoites válidas do ciclo de vida",
        CURRENT_STAY: "Estadia em curso",
        NO_STAYS: "Sem futuras estadias",
        CHECK_IN: "Check-in",
        CHECK_OUT: "Check-out",
        OF_NIGHTS: "Nº de noites",
        BOOKING_REF: "Referência da reserva",
        HOTEL: 'Hotel',
        ROOM_TYPE: "Tipo de acomodação",
        RATE: "Tarifa",
        CHANNEL: "Canal",
        GUESTS: "Hóspedes",
        ROOM: "Acomodação",
        BOOKING_DATE: "Data da reserva",
        NIGTH: "noite",
        ALL_STAYS: "Todas as estadias",
        CANCELLED: "Cancelada",
        IN_HOUSE: "Hospedados",
        GUEST_MARKETING: "Marketing para o hóspede",
        DATE_SENT: "Data enviada",
        EVENT_DATE: "Data do evento",
        CAMPAIGN: "Campanha",
        PENDING_REQUESTS: "Solicitações pendentes",
        DATE: "Data",
        READ_ON: "Lida em",
        REQUESTS: "Solicitações",
        TYPE: "Tipo",
        READ: "Lidas",
        UNREAD: "Não lidas",
        ADD_BADGE: "Adicionar selo",
        BADGE: "Selo",
        CANCEL: "Cancelar",
        SAVE: "Salvar",
        GUEST_EMAIL: "E-mail do hóspede",
        ENTER_EMAIL: "Digite o novo endereço de e-mail para esse perfil.",
        NOTE_PROFILE: "Observe que pode levar alguns minutos para que o novo e-mail seja atualizado no perfil do hóspede.",
        EMAIL_REQUIRED: "O e-mail é obrigatório.",
        INVALID_EMAIL: "Endereço de e-mail inválido.",
        EDIT_STATUS: "Editar o status da assinatura",
        PLEASE_CONFIRM: "Confirme a alteração do status da assinatura para o perfil deste hóspede",
        UNSUBSCRIBE: "Cancelar assinatura",
        NOTE_UNDO: "Observação: Você não pode desfazer essa ação. Somente o hóspede pode se inscrever novamente.",
        PROFILE: "Perfil",
        STAYS: "Estadias",
        ENGAGEMENT: "Engajamento",
        IMPLICIT: "Consentimento implícito",
        EXPLICIT: "Consentimento explícito",
        DOUBLE: "Dupla aceitação",
        UNSUBSCRIBED: "Assinatura cancelada"
      },
      REPORTS: {
        ONE_TIME: "Uma única vez",
        STATS: "Estatísticas",
        REVENUE_DETAIL: "Detalhes da receita",
        AUTOMATED: "Automatizado",
        SEARCH_CAMPAIGN_NAME: "Pesquisar pelo nome da campanha",
        SEARCH: "Pesquisar",
        CLEAR: "Remover",
        CAMPAIGN_NAME: "Nome da campanha",
        BOOKINGS: "Reservas",
        SENT: "Enviado",
        OPENED: "Aberto",
        CLICKED: "Clicado",
        LAST_MODIFIED: "Última modificação",
        REPORT: "Relatório",
        PREVIEW: "Visualizar",
        NO_RESULTS: "Não foram encontrados resultados"
      },
      CREDITS: "Créditos por e-mail",
      SUB_HEADER: {
        QUIT: "Encerrar",
        SAVE_QUIT: "Salvar e sair",
        SEND_TEST: "Envie um e-mail de teste",
        SCHEDULE: "Agenda",
        ACTIVATE: "Ativar",
        CANCEL_SCHEDULE: "Cancelar agendamento",
        DEACTIVATE: "Desativar",
        NEXT_STEP: "Próxima etapa"
      },
      CAMPAIGNS: {
        REVENUE: "Receita",
        SEARCH_PLACEHOLDER: "Pesquisar por nome, e-mail, referência",
        RESERVATIONS: "Reservas",
        SENT: "Enviado",
        OPENED: "Aberto",
        CLICKED: "Clicado",
        DELIVERED: "Entregue",
        BOUNCES: "Rejeições",
        UNSUBSCRIBE: "Cancelar assinatura",
        SPAM_REPORTS: "Relatórios de spam",
        DELIVERY_REPORT: "Relatório de entregas",
        DOWNLOAD_REPORT: "Baixar relatório",
        DISPATCHED: "Enviado",
        GUEST: "Hóspede",
        EMAIL: "E-mail",
        STATUS: 'Status',
        NO_RESULTS: "Não foram encontrados resultados",
        ROOM_NIGHTS: "Pernoites na acomodação",
        OPENS: "Em aberto",
        CLICKS: "Cliques",
        TOP_CHANNELS: "Principais canais",
        TIME_SINCE_LAST_STAY: "Período desde a última estadia",
        TOP_RATE_CODE_BY_CHANNEL: "Código da tarifa mais alta por canal",
        RATE_CODE: "Código da tarifa",
        TOTAL: 'Total',
        BOOKING_LEAD_TIMES: "Prazos da reserva",
        RESERVATION_REPORT: "Relatório sobre a reserva",
        BOOK_DATE: "Data de reserva",
        SEND_DATE: "Data de envio",
        NIGHTS: "Pernoites",
        BOOKING_CHANNEL: "Canal de reserva",
        TOTAL_RATE: "Total de tarifas",
        REPORTS: "Relatório da campanha",
        ONE_TIME: "Uma única vez",
        AUTOMATED: "Automatizada",
        CREATE_CAMPAIGN: "Criar campanha",
        CREATE: "Criar",
        SEARCH_CAMPAIGN: "Pesquisar pelo nome da campanha",
        SEARCH: "Pesquisar",
        CLEAR: "Remover",
        ALL_STATUSES: "Todos os status",
        DRAFT: "Rascunho",
        SCHEDULED: "Agendada",
        INACTIVE: "Inativa",
        ACTIVE: "Ativa",
        CAMPAIGN_NAME: "Nome da campanha",
        TYPE: "Tipo",
        DELIVERY: "Entrega",
        LAST_UPDATED: "Última atualização",
        REPORT: "Relatório",
        CANCEL_SCHEDULE: "Cancelar agendamento",
        DEACTIVATE: "Desativar",
        ACTIVATE: 'Activate',
        VIEW: "Visualizar",
        DUPLICATE: "Duplicar",
        DELETE: "Excluir",
        DELIVERING: "Entregando",
        SUSPENDED: "Suspensa",
        REMOVE_CAMPAIGN: "Remover campanha",
        DO_YOU_REMOVE_CAMPAIGN: "Você tem certeza de que deseja remover a campanha?",
        CANCEL_CAMPAIGN: "Cancelar campanha",
        DO_YOU_CANCEL_CAMPAIGN: "Você vai cancelar a campanha agendada?",
        DEACTIVATE_CAMPAIGN: "Desativar campanha",
        DO_YOU_DEACTIVATE_CAMPAIGN: "Você vai desativar a campanha?",
        ACTIVATE_CAMPAIGN: "Ativar campanha",
        DO_YOU_ACTIVATE_CAMPAIGN: "Você vai ativar a campanha?",
        SETUP: "Configuração",
        DESIGN: 'Design',
        SEGMENTS: "Segmentos",
        SCHEDULE: "Agenda",
        CONFIRMATION: "Confirmação",
        SELECT: "Selecionar",
        ROOM_OFFERS: "Ofertas para a acomodação",
        CAMPAIGNS_DESCR: "Campanhas criadas para aumentar a receita por acomodação através de ofertas e pacotes",
        EVENTS_ANNOUNCEMENTS: "Eventos e Comunicados",
        NOTIFY_RECIPIENTS: "Avise os destinatários sobre eventos especiais na propriedade",
        NEWSLETTER: "Boletim informativo",
        CAMPAIGNS_UPDATE_DESCR: "Campanhas de atualização sobre notícias ou eventos do hotel, enviadas regularmente",
        MISS_YOU: "Sentimos muito a sua falta",
        CAMPAGINS_SENT: "Campanhas enviadas aos hóspedes após a estadia para incentivar uma nova reserva",
        OTHER: "Outra",
        CREATE_CUSTOM: "Criar uma campanha personalizada",
        PRE_ARRIVAL: "Antes da chegada",
        SEND_EMAIL: "Envie um e-mail aos hóspedes antes da chegada deles.",
        WELCOME: "Bem-vindo(a)",
        SEND_EMAIL_CHECK_IN: "Envie um e-mail aos hóspedes no momento do check-in.",
        QUALIFICATION: "Qualificação",
        SEND_EMAIL_SOON: "Envie um e-mail assim que um hóspede entrar em um segmento. Eles receberão esse e-mail apenas uma vez.",
        SENT_TO_GUESTS: "Enviado aos hóspedes após a estadia para incentivar uma nova visita.",
        OTA_WINBACK: "Regresso OTA",
        SEND_CAMPAIGN: "Envie uma campanha para os hóspedes da OTA dentro de 30 dias após o check-out",
        BIRTHDAY: "Aniversário",
        SEND_SPECIAL: "Envie uma oferta especial para comemorar um aniversário ou data comemorativa",
        DOUBLE_OPT: "Dupla aceitação",
        AUTOMATICALLY_SEND: "Envie automaticamente um link de inscrição para os hóspedes quando eles forem adicionados a uma lista de contatos",
        CAMPAIGN_NAME_HINT: "Dê à sua campanha um nome interno para ajudar a organizá-la e localizá-la facilmente em sua conta.",
        CAMPAIGN_NAME_REQUIRED: "O nome da campanha é obrigatório",
        SUBJECT_LINE: "Linha do assunto",
        SUBJECT_LINE_HINT: "Escreva uma linha no assunto que descreva claramente o conteúdo do e-mail. Ela ficará visível na caixa de entrada do destinatário e é o primeiro conteúdo que ele/ela verá.",
        SUBJECT_REQUIRED: "O campo de \"Assunto\" é necessário",
        PREVIEW_LINE: "Linha de pré-visualização",
        PREVIEW_LINE_HINT: "Escreva um texto curto (cerca de 35 caracteres) que dê uma visão geral do seu e-mail. O texto será exibido na caixa de entrada do destinatário, logo abaixo do assunto.",
        FROM_EMAIL: "Do e-mail",
        FROM_EMAIL_HINT: "Escolha o endereço de e-mail a ser exibido na caixa de entrada dos destinatários quando eles receberem sua campanha.",
        FROM_EMAIL_REQUIRED: "O e-mail de origem é obrigatório",
        FROM_NAME: "Nome da origem",
        FROM_NAME_HINT: "Digite um nome (p. ex., o nome da sua empresa) para ajudar os destinatários da campanha a reconhecerem você na caixa de entrada.",
        FROM_NAME_REQUIRED: "O nome da origem é necessário",
        MY_TEMPLATES: "Meus modelos",
        TEMPLATE_GALLERY: "Galeria de modelos",
        DESIGN_TOOLS: "Ferramentas de design",
        DRAG_DROP_EDITOR: "Editor para o recurso de Arrastar e Soltar",
        DRAG_DROP_EDITOR_DESC: "Crie um e-mail compatível com dispositivos móveis que fique ótimo em qualquer dispositivo ou tamanho de tela",
        RICH_TEXT_EDITOR: "Editor de \"rich text\"",
        RICH_TEXT_EDITOR_DESC: "Use o editor de \"rich text\" para criar e-mails simples",
        PASTE_YOUR_CODE: "Cole seu código",
        PASTE_YOUR_CODE_DESC: "Copie e cole seu código HTML.",
        RESET_EMAIL_DESIGN: "Redefinir o design do e-mail",
        EDIT_EMAIL_CONTENT: "Editar conteúdo do e-mail",
        LOAD_SAVED_VERSION: "Carregar uma versão salva",
        VERSION: "Versão",
        SAVE: "Salvar",
        SAVE_QUIT: "Salvar e sair",
        QUIT_WITHOUT_SAVING: "Encerrar sem salvar",
        HTML_EDITOR: "Editor de HTML",
        ENTER_HTML: "Digite o HTML",
        CANCEL: "Cancelar",
        CHOOSE_DIFFERENT_SEGMENT: "Escolha um segmento diferente",
        ADDITIONAL_OPTIONS: "Mais opções",
        FILTER_PROPERTIES: "Filtrar propriedades",
        ALL_PROPERTIES: "Todas as propriedades",
        ENTER_PROPERTY_TO_FILTER: "Digite a propriedade para filtrar...",
        ONLY_INCLUDE_GUESTS_WHO_HAVE: "Inclua apenas hóspedes que tenham",
        RECEIVED: "recebido",
        NOT_RECEIVED: "não foi recebido",
        OPENED_LOWER: "aberto",
        NOT_OPENED: "não foi aberto",
        CLICKED_LOWER: "clicado",
        NOT_CLICKED: "não clicado",
        BEEN_SENT: "enviados",
        NOT_SENT: "não foi enviado",
        ANY_EXISTING_CAMPAIGN_IN_LAST: "qualquer campanha vigente no último",
        DAY: "dia",
        WEEK: "semana",
        TWO_WEEKS: "duas semanas",
        MONTH: "mês",
        YEAR: "ano",
        TWO_YEARS: "dois anos",
        ENABLE_SAFE_MODE: "Ativar o modo de segurança",
        BEST_PRACTICE: "Práticas recomendadas pela LGPD",
        GUESTS_MUST_HAVE: "Os hóspedes devem ter",
        IMPLICIT_CONSENT: "Consentimento implícito",
        SAFE: "seguro",
        EXPLICIT_CONSENT: "Consentimento explícito",
        SAFER: "mais seguro",
        DOUBLE_OPT_IN_CONSENT: "Consentimento para dupla aceitação",
        SAFEST: "o mais seguro",
        EXCLUDE_EMAIL_FUTURE_STAYS: "Excluir endereços de e-mail com estadias futuras",
        REFRESH_STATISTICS: "Atualizar estatísticas",
        SELECT_SCHEDULE_OPTION: "Selecione uma opção de agendamento para sua campanha:",
        SPECIFIC_DATE_TIME: "Uma data e hora específicas",
        IMMEDIATELY: "Imediatamente",
        BASED_ON_GUEST_EVENT: "Com base em um evento do hóspede",
        AT: "às",
        HH: "hh",
        MM: "mm",
        ON: "em",
        HOUR_REQUIRED: "Um valor para hora é necessário",
        MINUTES_REQUIRED: "Um valor para minutos é necessário",
        SELECT_DATE: "Selecione uma data",
        DATE_REQUIRED: "A data é necessária",
        DATE_MUST_BE_FUTURE: "A data deve estar no futuro",
        SELECT_DAYS: "Selecionar dias",
        DAYS_REQUIRED: "Os dias são necessários",
        DAYS: "dias",
        SELECT_EVENT: "Selecionar eventos",
        EVENT_REQUIRED: "O evento é necessário",
        SELECT_LIST: "Selecionar lista",
        LIST_REQUIRED: "É necessário ter uma lista",
        CAMPAIGN_READY: "Sua campanha está pronta para ser enviada!",
        REVIEW_REPORT_BEFORE_SENDING: "Revise o relatório abaixo antes de enviar sua campanha",
        SUBJECT: "Assunto",
        FROM: "De",
        ROOM_REVENUE_TRACKING: "Monitoramento da receita da acomodação",
        DOUBLE_OPT_IN_URL: "URL para Dupla aceitação",
        DOUBLE_OPT_IN_URL_HINT: "Essa é a URL para a qual o usuário será redirecionado durante um fluxo de aceitação duplo.",
        DOUBLE_OPT_IN_URL_REQUIRED: "URL para Dupla aceitação é necessária",
        VALID_URL_REQUIRED: "Digite uma URL válida",
        YOUR_DESIGN: "O seu design",
        USED: "Você usou o",
        CREATE_DESIGN: "para criar o seu design",
        EDIT_CONTENT: "Editar o conteúdo",
        ADDITIONAL_FILTERS: "Filtros extras",
        SEND_IMMEDIATELY: "Enviar imediatamente",
        SEND_TEST_EMAIL: "Enviar e-mail de teste",
        ENTER_EMAILS: "Digite os e-mails",
        ENTER_EMAILS_DESC: "Digite uma lista de endereços de e-mail, um por linha.",
        SEND_TEST: "Enviar e-mail de teste",
        SCHEDULE_CAMPAIGN: "Agenda da campanha",
        SURE_DELIVERY: "Você tem certeza de que deseja agendar sua campanha a ser entregue?",
        CAMPAIGN_IN_LAST: " qualquer campanha nos últimos ",
        SEARCH_TEMPLATE_NAME: "Pesquisar pelo nome do modelo",
        CREATE_TEMPLATE: "Criar modelo",
        EDIT_CAMPAIGN: "Editar campanha",
        TEMPLATE_NAME: "Nome do modelo",
        EDIT_TEMPLATE: "Editar o modelo",
        MORE_OPTIONS: "Mais opções",
        TEMPLATE_NAME_HINT: "Dê ao seu modelo um nome interno para ajudar a organizá-lo e localizá-lo facilmente em sua conta.",
        TEMPLATE_NAME_REQUIRED: "É necessário o nome do modelo",
        LIST_SELECTED: "Lista selecionada",
        LISTS_SELECTED: " Listas selecionadas",
        ALL_LISTS_SELECTED: "Todas as listas selecionadas",
        BEFORE_CHECK_IN: "antes do check-in",
        AFTER_CHECK_IN: "após o check-in",
        BEFORE_CHECK_OUT: "antes do check-out",
        AFTER_LAST_CHECK_OUT: "após o último check-out",
        ON_CHECK_OUT: "no check-out",
        ON_CHECK_IN: "no check-in",
        ON_DAY_OF_CHECK_IN: "no dia do check-in",
        BEFORE_BIRTHDAY: "antes do aniversário",
        BEFORE_ANNIVERSARY: "antes do aniversário",
        ON_LIST_JOINED: "na lista de participantes",
        AT_SPASE: "em ",
        UNLAYER_LANGUAGE: "pt-BR",
        EDITOR_LANGUAGE: "pt"
      }
    },
    MENU: {
      NEW: "novo",
      ACTIONS: "Ações",
      CREATE_POST: "Criar nova publicação",
      PAGES: "Páginas",
      FEATURES: "Recursos",
      APPS: "Aplicativos",
      HOME: "Início"
    },
    AUTH: {
      GENERAL: {
        OR: "Ou",
        SUBMIT_BUTTON: "Enviar",
        NO_ACCOUNT: "Você não possui uma conta?",
        SIGNUP_BUTTON: "Cadastre-se",
        FORGOT_BUTTON: "Esqueci a senha",
        BACK_BUTTON: "Voltar",
        PRIVACY: "Privacidade",
        LEGAL: "Jurídico",
        CONTACT: "Contato"
      },
      LOGIN: {
        TITLE: "Acessar conta",
        BUTTON: "Entrar"
      },
      FORGOT: {
        TITLE: "Você esqueceu a senha?",
        DESC: "Digite seu e-mail para redefinir sua senha",
        SUCCESS: "Sua conta foi redefinida com sucesso."
      },
      REGISTER: {
        TITLE: "Cadastre-se",
        DESC: "Digite seus dados para criar sua conta",
        SUCCESS: "Sua conta foi registrada com sucesso."
      },
      INPUT: {
        EMAIL: 'E-mail',
        FULLNAME: "Nome",
        PASSWORD: "Senha",
        CONFIRM_PASSWORD: "Confirmar senha",
        USERNAME: "Nome de usuário"
      },
      VALIDATION: {
        INVALID: "{{name}} não é válido",
        REQUIRED: "{{name}} é obrigatório",
        MIN_LENGTH: "O comprimento mínimo de {{name}} é {{min}}",
        AGREEMENT_REQUIRED: "É necessário aceitar os termos e condições",
        NOT_FOUND: "O {{name}} solicitado não foi encontrado",
        INVALID_LOGIN: "Os detalhes de logon estão incorretos",
        REQUIRED_FIELD: "Campo obrigatório",
        MIN_LENGTH_FIELD: "Extensão mínima do campo:",
        MAX_LENGTH_FIELD: "Extensão máxima do campo:",
        INVALID_FIELD: "O campo não é válido"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Contagem dos registros selecionados:",
        ALL: "Tudo",
        SUSPENDED: "Suspensos",
        ACTIVE: "Ativo",
        FILTER: "Filtro",
        BY_STATUS: "por status",
        BY_TYPE: "por tipo",
        BUSINESS: "Corporativo",
        INDIVIDUAL: "Particular",
        SEARCH: "Pesquisar",
        IN_ALL_FIELDS: "em todos os campos"
      },
      ECOMMERCE: "Comércio eletrônico",
      CUSTOMERS: {
        CUSTOMERS: "Clientes",
        CUSTOMERS_LIST: "Lista de clientes",
        NEW_CUSTOMER: "Novo cliente",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Excluir cliente",
          DESCRIPTION: "Você tem certeza de que deseja excluir permanentemente esse cliente?",
          WAIT_DESCRIPTION: "O perfil do cliente está sendo excluído...",
          MESSAGE: "O perfil do cliente foi excluído"
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Excluir clientes",
          DESCRIPTION: "Você tem certeza de que deseja excluir permanentemente os clientes selecionados?",
          WAIT_DESCRIPTION: "Os perfis dos clientes estão sendo excluídos...",
          MESSAGE: "Os clientes selecionados foram excluídos"
        },
        UPDATE_STATUS: {
          TITLE: "O status foi atualizado para os clientes selecionados",
          MESSAGE: "O status dos clientes selecionados foi atualizado com sucesso"
        },
        EDIT: {
          UPDATE_MESSAGE: "O perfil do cliente foi atualizado",
          ADD_MESSAGE: "O perfil do cliente foi criado"
        }
      }
    },
    Jan: 'Jan',
    Feb: "Fev",
    Mar: 'Mar',
    Apr: "Abr",
    May: "Mai",
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: "Ago",
    Sep: "Set",
    Oct: "Out",
    Nov: 'Nov',
    Dec: "Dez",
    Clicks: "Cliques",
    Opens: "Abertos",
    '< 7 days': "< 7 dias",
    '7-14 Days': "7 a 14 dias",
    '15-30 Days': "15 a 30 dias",
    '31-60 Days': "31 a 60 dias",
    '61-90 Days': "61 a 90 dias",
    '> 90 Days': "> 90 dias",
    '< 1 month': "< 1 mês",
    '1-2 Months': "1 a 2 meses",
    '2-6 Months': "2 a 6 meses",
    '6-12 Months': "6 a 12 meses",
    '> 1 Year': "> 1 ano",
    Sent: "Enviado",
    Delivered: "Entregue",
    Open: "Abrir",
    Spam: 'Spam',
    Click: "Clique",
    HardBounce: "Rejeição permanente",
    SoftBounce: "Rejeição temporária",
    Unsubscribe: "Cancelar assinatura",
    InvalidEmail: "E-mail inválido",
    Blocked: "Bloqueado",
    Complaint: "Reclamação",
    Deferred: "Adiado",
    ListAddition: "Inclusão de lista",
    Overview: "Visão Geral",
    "Guest List": "Lista do hóspede",
    "Guest Lists": "Listas do hóspede",
    "Guest Segments": "Segmentos de hóspedes",
    Campaigns: "Campanhas",
    Templates: "Modelos"
  }
};