import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EmailService {
	constructor(private httpClient: HttpClient) { }

	getUrl(hotelId: number = null): Observable<string> {
		let apiUrl = `${environment.api.analytics}/email`;
		if(!!hotelId && Number.isInteger(+hotelId) && (+hotelId!==0))
			apiUrl += '?hotelid=' + hotelId.toString();
		
		return this.httpClient.get<string>(apiUrl, {responseType: 'text' as 'json' });
	}
}
