<div [ngClass]="{'dialog-loading': viewLoading}">
	<div *ngIf="viewLoading" class="loading-overlay justify-content-center">
		<div class="loading-container">
			<mat-spinner diameter="30"></mat-spinner>
		</div>
	</div>
	<h2 mat-dialog-title>{{data.title}}</h2>
	<mat-dialog-content>
		<div style="margin-bottom:15px;" [innerHTML]="viewLoading ? data.waitDesciption : data.description">
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button (click)="onNoClick()">{{cancelButtonText}}</button>&nbsp;
		<button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="viewLoading">{{buttonText}}</button>
	</mat-dialog-actions>
</div>
