import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import 'ag-grid-enterprise';
import { LicenseManager } from "ag-grid-enterprise";
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler, isDevMode, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
import { MenuModule } from './views/partials/menus/menu.module';
// Services
import { DataTableService, ThemeService } from './core/_base/layout';
// Layout Services
import { LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService,
	KtDialogService } from './core/_base/layout';
// Auth
import { AuthService, AuthGuard, ModuleGuard } from './core/auth';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/demo1/layout.config';
import { HotelService } from './core/_services/hotel.service';
import { UserSettingService } from './core/_services/user-setting.service';
import { AvailabilityService } from './core/_services/availability.service';
import { SettingService } from './core/_services/setting.service';
import { Router } from '@angular/router';
import { AuthInterceptor } from './core/interceptors/interceptors';
import { StatisticService } from './core/_services/statistic.service';
import { MarketingService } from './core/_services/marketing.service';
import { EmailService } from './core/_services/email.service';
import { HelpService } from './core/auth/_services/help.service';
import { CrmService } from './core/_services/crm.service';
import { CrmFilterService } from './core/_services/crm-filter.service';
import { HelpGuard } from './core/auth/_guards/help.guard';
import { AgGridModule } from "ag-grid-angular";

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {

	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

@Injectable()
export class ChunkErrorHandler extends BugsnagErrorHandler {
	handleError(error: any) {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message) && localStorage.getItem('lastChunkError') !== error.originalStack) {
			localStorage.setItem('lastChunkError', error.originalStack);
			window.location.reload();
		} else {
			super.handleError(error);
		}		
	}
}

LicenseManager.setLicenseKey(environment.agGridKey);

// configure Bugsnag asap
Bugsnag.start({ apiKey: '7e26de71b64daa98837d2bbcf93de3ee' })
// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
	if (isDevMode()) {
		return new ErrorHandler();
	}
	return new ChunkErrorHandler();
}

@NgModule({
	declarations: [AppComponent],
	imports: [ 
    MbscModule,  
    FormsModule,  
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		PartialsModule,
		MenuModule,
		CoreModule,
		OverlayModule,
		NgbModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		AgGridModule
	],
	exports: [],
	providers: [
		AuthService,
		LayoutConfigService,
		ThemeService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: ErrorHandler,
			useFactory: errorHandlerFactory
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		HotelService,
		UserSettingService,
		AvailabilityService,
		CrmService,
		CrmFilterService,
		StatisticService,
		MarketingService,
		EmailService,
		HelpService,
		SettingService,
		AuthGuard,
		ModuleGuard,
		HelpGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
