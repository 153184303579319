import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class HelpService {

	constructor(private httpClient: HttpClient) { }

	getSupportToken(): Observable<string> {
		const apiUrl = `${environment.api.usersSettings}/support/hc`;
		return this.httpClient.get<string>(apiUrl, { responseType: 'text' as 'json' });
	}

	getWidgetToken(): Observable<string> {
		const apiUrl = `${environment.api.usersSettings}/support/widget`;
		return this.httpClient.get<string>(apiUrl, { responseType: 'text' as 'json' });
	}
}
