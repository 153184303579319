import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
  lang: "th",
  data: {
    TRANSLATOR: {
      SELECT: "เลือกภาษาของคุณ"
    },
    USER_PROFILE: {
      SETTINGS: "การตั้งค่า",
      HOTEL_OPTIONS: "ตัวเลือกและการตั้งค่าโรงแรม",
      HELP: "ช่วยเหลือและสนับสนุน",
      HELP_GUID: "คู่มือช่วยเหลือและสนับสนุนฉบับสมบูรณ์",
      THEME: "ธีม",
      LANGUAGE: "ภาษา",
      SIGN_OUT: "ออกจากระบบ",
      USERS: "ผู้ใช้",
      MANAGE_USERS: "จัดการผู้ใช้และสิทธิ์ของโรงแรม"
    },
    CONNECTS: {
      MENU: {
        OVERVIEW: "ภาพรวม",
        GUESTS_SEGMENTS: "ผู้เข้าพักและเซกเมนต์",
        GUESTS: "ผู้เข้าพัก",
        SEGMENTS: "เซกเมนต์",
        LISTS: "รายการ",
        CAMPAIGNS: "แคมเปญ",
        CAMPAIGN_LIST: "รายการแคมเปญ",
        TEMPLATES: "เทมเพลต",
        REPORTS: "รายงาน"
      },
      OVERVIEW: {
        TOTAL: "โปรไฟล์ทั้งหมด",
        REACHABLE: "โปรไฟล์ที่ติดต่อได้",
        UNREACHABLE: "โปรไฟล์ที่ติดต่อไม่ได้",
        EMAIL_COVERAGE: "ความครอบคลุมของอีเมล",
        CAMPAIGN: "รายได้ห้องพักจากแคมเปญ",
        YEAR: "ตั้งแต่ต้นปีถึงปัจจุบัน",
        ONE_TIME: "รายได้จากแคมเปญหนึ่งครั้ง",
        LAST: "รายได้ล่าสุด 5 รายการ",
        ALL: "ดูทั้งหมด",
        LIFETIME: "รายได้ตลอดระยะเวลาทั้งหมด",
        REVENUE: "รายได้ทั้งหมด",
        ARRIVALS: "เข้าพัก",
        NUMBER_OF_GUESTS: "จำนวนผู้เข้าพัก",
        ROOM_REVENUE: "รายได้ห้องพัก",
        NAME: "ชื่อ",
        NIGHTS: "คืน",
        LIFETIME_VALUE: "มูลค่าตลอดระยะเวลาทั้งหมด",
        EMAIL: "อีเมล",
        DEPARTURES: "เช็คเอ้าท์"
      },
      GUESTS: {
        ALL_GUESTS: "ผู้เข้าพักทั้งหมด",
        ARRIVALS: "เข้าพัก",
        HOUSE: "เข้าพักอยู่",
        DEPARTURES: "เช็คเอ้าท์",
        SEARCH_PLACEHOLDER: "ค้นหาชื่อ อีเมล ข้อมูลอ้างอิง",
        DATE_PLACEHOLDER: "เลือกช่วงวันที่",
        FILTERS: "ตัวกรอง",
        CHANGE_HOTEL: "เปลี่ยนโรงแรม",
        SEARCH: "ค้นหา",
        CLEAR: "ล้าง",
        GUEST: "ผู้เข้าพัก",
        NIGHTS: "คืน",
        ROOM_TYPE: "ประเภทห้อง",
        REVENUE: "รายได้",
        BOOKING: "ช่องทางการจอง",
        RATE: "รหัสราคา",
        EMAIL: "อีเมล",
        PREVIOUS: "การเข้าพักก่อนหน้า",
        LIFETIME: "มูลค่าตลอดระยะเวลาทั้งหมด",
        NOTES: "หมายเหตุ",
        NO_RESULTS: "ไม่พบผลลัพธ์",
        ITEMS: "รายการต่อหน้า:"
      },
      SEGMENTS: {
        SEARCH_SEGMENTS: "ค้นหาตามชื่อเซกเมนต์",
        CREATE_SEGMENT: "สร้างเซกเมนต์ผู้เข้าพัก",
        EDIT_SEGMENT: "แก้ไขเซกเมนต์ผู้เข้าพัก",
        REFRESH: "รีเฟรชทันที",
        STATISTICS: "สถิติที่อัปเดตล่าสุด",
        NO_RESULTS: "ไม่พบผลลัพธ์",
        REACHABLE: "ติดต่อได้ทางอีเมล",
        PROFILES: "โปรไฟล์",
        AVARAGE_NIGHTS: "จำนวนคืนโดยเฉลี่ย",
        AVARAGE_RATE: "อัตราค่าห้องพักเฉลี่ยรายวัน",
        OR: "หรือ",
        EXCLUDE_BOOKINGS: "ไม่รวมการจองที่เข้าพักในอนาคต",
        VIEW_DETAILS: "ดูรายละเอียด",
        SELECT: "เลือก",
        PEOPLE_IN_SEGMENT: "คนในเซกเมนต์นี้",
        TOTAL_LIFETIME: "ยอดใช้จ่ายตลอดระยะเวลาทั้งหมด",
        SEARCH_PLACEHOLDER: "ค้นหาชื่อ อีเมล ข้อมูลอ้างอิง",
        SEARCH: "ค้นหา",
        CLEAR: "ล้าง",
        GUEST: "ผู้เข้าพัก",
        EMAIL: "อีเมล",
        LIFETIME_VALUE: "มูลค่าตลอดระยะเวลาทั้งหมด",
        LIFETIME_STAYS: "จำนวนการเข้าพักตลอดระยะเวลาทั้งหมด",
        TOTAL_NIGHTS: "จำนวนคืนทั้งหมด",
        CLOSE: "ปิด",
        ITEMS: "รายการต่อหน้า:",
        SEGMENT_NAME: "ชื่อเซกเมนต์",
        SEGMENT_REQUIRED: "จำเป็นต้องมีชื่อเซกเมนต์",
        CONTACT_LISTS: "รายชื่อผู้ติดต่อ",
        ENTER_CONTACT: "ป้อนรายชื่อผู้ติดต่อ...",
        PROFILES_SEGMENT: "โปรไฟล์ในเซกเมนต์ยี้",
        TOTAL_LIFETIME_UPPER: "ยอดใช้จ่ายตลอดระยะเวลาทั้งหมด",
        REFRESH_STATICITCS: "รีเฟรชค่าสถิติ",
        FIND_GUESTS: "ค้นหาผู้เข้าพักที่ตรงกับเงื่อนไขต่อไปนี้",
        AND: "และ",
        SEARCH_VALUE: "ป้อนค่าการค้นหา",
        ENTER_DISTANCE: "ป้อนระยะทาง",
        IMPLICIT_CONSENT: "ความยินยอมโดยปริยาย (ปลอดภัย)",
        EXPLICIT_CONSENT: "ความยินยอมแบบชัดแจ้ง (ปลอดภัยกว่า)",
        DOUBLE_CONSENT: "ความยินยอมแบบเลือกรับสองครั้ง (ปลอดภัยที่สุด)",
        ENTER_DATE: "ป้อนช่วงวันที่",
        START_DATE: "วันที่เริ่ม",
        END_DATE: "วันที่สิ้นสุด",
        START_DAYS: "วันที่เริ่ม",
        END_DAYS: "วันที่สิ้นสุด",
        LAST_DAYS: " วันล่าสุด",
        EXCLUDE_GUESTS: "ไม่รวมผู้ที่เข้าพักในอนาคต",
        CORRECT_FILTERS: "โปรดแก้ไขข้อผิดพลาดในตัวกรองเซกเมนต์",
        CANCEL: "ยกเลิก",
        SAVE: "บันทึก",
        BETWEEN_DATES: "ระหว่างวันที่",
        BETWEEN_FUTURE: "ระหว่างวันในอนาคต",
        DAYS: "วัน",
        BETWEEN_PAST: "ระหว่างวันในอดีต",
        LAST: "ในช่วงก่อนหน้า",
        EQUAL: "เท่ากับ",
        NOT_EQUAL: "ไม่เท่ากับ",
        GREATER: "มากกว่า",
        LESS: "น้อยกว่า",
        KM: "กม.",
        CONTAINS: "มี",
        NOT_CONTAINS: "ไม่มี",
        IS_EQUAL: "เท่ากับ",
        IS_NOT_EQUAL: "ไม่เท่ากับ",
        LEAST: "มีอย่างน้อย",
        ANNIVERSARY: "วันครบรอบ",
        BADGE: "เหรียญตรา",
        BIRTHDAY: "วันเกิด",
        COMPANY: "บริษัท",
        EMAIL_ADDRESS: "อีเมล",
        GENDER: "เพศ",
        LANGUAGE: "ภาษา",
        LIFETIME_ROOM: "ยอดใช้จ่ายค่าห้องพักตลอดระยะเวลาทั้งหมด",
        LOCATION: "ที่ตั้ง",
        TOTAL_NUMBER_NIGHTS: "ทั้งหมด # คืน",
        TOTAL_NUMBER_STAYS: "ทั้งหมด # การเข้าพัก",
        SUBSCRIPTION: "สถานะการสมัครสมาชิก",
        BOOKING: "ช่องทางการจอง",
        BOOKING_DATE: "วันที่จอง",
        BOOKING_LEAD: "ระยะเวลาการจองห้องพัก",
        CHECK_IN_DATE: "วันที่เช็คอิน",
        CHECK_OUT_DATE: "วันที่เช็คเอาท์",
        LENGTH: "ระยะเวลาเข้าพัก",
        MARKET: "รหัสตลาด",
        RATE: "รหัสราคา",
        ROOM: "รหัสห้อง",
        RATE_AVARAGE: "ราคาเฉลี่ย",
        SOURCE: "แหล่งที่มาของธุรกิจ",
        STAY: "วันที่เข้าพัก",
        DEMOGRAPHICS: "ข้อมูลประชากร",
        MARKETING: "การตลาดทางอีเมล",
        STAY_DATA: "ข้อมูลการเข้าพัก",
        IMPORT_GUESTS: "นำเข้าผู้เข้าพัก",
        ADD_LIST: "เพิ่มรายการ",
        HOTEL_NAME: "ชื่อโรงแรม",
        GROUPWIDE: "ทั่วทั้งกลุ่ม",
        NAME: "ชื่อ",
        GUESTS: "ผู้เข้าพัก",
        EDIT_LIST: "แก้ไขรายการ",
        UPDATE_LIST: "อัปเดตรายการ",
        LIST_NAME: "ชื่อรายการ",
        ROOM_REQUIRED: "จำเป็นต้องมีชื่อประเภทห้องพัก",
        SELECT_HOTEL: "เลือกโรงแรม",
        SELECT_DATA: "เลือกข้อมูล",
        SELECT_LIST: "เลือกรายการ",
        PLEASE_SELECT_LIST: "โปรดเลือกรายการ",
        SUBSCRIPTION_STATUS: "สถานะการสมัครสมาชิก",
        UNSUBSCRIBED: "เลิกสมัครสมาชิกแล้ว",
        IMPLICIT: "โดยปริยาย",
        EXPLICIT: "โดยชัดแจ้ง",
        DOUBLE_OPT_IN: "เลือกรับสองครั้ง",
        PLEASE_SELECT_SUBSCRIPTION: "กรุณาเลือกสถานะการสมัครสมาชิก",
        PLEASE_UPLOAD_FILE: "โปรดอัปโหลดไฟล์",
        DOWNLOAD_EXAMPLE: "ดาวน์โหลดไฟล์ .csv ตัวอย่าง",
        NEXT: "ถัดไป",
        COMPLETE_MAPPINGS: "แม็ปให้เสร็จสมบูรณ์",
        BACK: "ย้อนกลับ",
        IMPORT: "นำเข้า",
        ERROR_UPLOADING: "ขออภัย เกิดข้อผิดพลาดในการอัปโหลดผู้เข้าพัก",
        UPLOAD_FILE: "อัปโหลดไฟล์",
        NO_FILE: "ไม่มีไฟล์ที่เลือก"
      },
      GUESTS_PROFILE: {
        LEAD_TIME: "ระยะเวลาก่อนการเข้าพัก",
        LIFETIME_STAYS: "จำนวนการเข้าพักตลอดระยะเวลาทั้งหมด",
        LIFETIME_NIGHTS: "จำนวนคืนตลอดระยะเวลาทั้งหมด",
        LIFETIME_SPEND: "ยอดใช้จ่ายตลอดระยะเวลาทั้งหมด",
        STATUS: "สถานะ",
        HOUSE: "เข้าพักอยู่",
        LAST_STAY: "การเข้าพักล่าสุด",
        NEXT_STAY: "การเข้าพักครั้งต่อไป",
        NIGHTS: "คืน",
        TODAY: "วันนี้",
        PERSONAL: "ส่วนตัว",
        TITLE: "คำนำหน้าชื่อ",
        FIRST_NAME: "ชื่อ",
        LAST_NAME: "นามสกุล",
        GENDER: "เพศ",
        LANGUAGE: "ภาษา",
        COMPANY: "บริษัท",
        JOB_TITLE: "ตำแหน่งงาน",
        ADDRESS_1: "ที่อยู่ 1",
        ADDRESS_2: "ที่อยู่ 2",
        CITY_COUNTY: "เมือง, จังหวัด",
        POST_CODE: "รหัสไปรษณีย์",
        COUNTRY: "ประเทศ",
        OTHER: "อื่นๆ",
        BIRTHDAY: "วันเกิด",
        ANNIVERSARY: "วันครบรอบ",
        PROFILE_NOTES: "หมายเหตุโปรไฟล์",
        EMAIL: "อีเมล",
        SUBSCRIPTION_STATUS: "สถานะการสมัครสมาชิก",
        BADGES: "เหรียญตรา",
        CONTACT_LISTS: "รายชื่อผู้ติดต่อ",
        BOOKING_NOTES: "หมายเหตุการจอง",
        LOYALTY: "ความภักดี",
        FIRST_STAY: "เข้าพักครั้งแรกกับกลุ่ม",
        LIFETIME_VALID_STAYS: "จำนวนการเข้าพักตลอดระยะเวลาทั้งหมด",
        LIFETIME_CANCELLED: "จำนวนการเข้าพักที่ยกเลิกตลอดระยะเวลาทั้งหมด",
        LIFETIME_VALID_NIGHTS: "จำนวนคืนตลอดระยะเวลาทั้งหมด",
        CURRENT_STAY: "การเข้าพักในปัจจุบัน",
        NO_STAYS: "ไม่มีการเข้าพักในอนาคต",
        CHECK_IN: "เช็คอิน",
        CHECK_OUT: "เช็คเอ้าท์",
        OF_NIGHTS: "# คืน",
        BOOKING_REF: "ข้อมูลอ้างอิงการจอง",
        HOTEL: "โรงแรม",
        ROOM_TYPE: "ประเภทห้อง",
        RATE: "ราคา",
        CHANNEL: "ช่องทาง",
        GUESTS: "ผู้เข้าพัก",
        ROOM: "ห้องพัก",
        BOOKING_DATE: "วันที่จอง",
        NIGTH: "คืน",
        ALL_STAYS: "การเข้าพักทั้งหมด",
        CANCELLED: "ยกเลิกแล้ว",
        IN_HOUSE: "เข้าพักอยู่",
        GUEST_MARKETING: "การตลาดสำหรับผู้เข้าพัก",
        DATE_SENT: "วันที่ส่ง",
        EVENT_DATE: "วันที่จัดอีเวนต์",
        CAMPAIGN: "แคมเปญ",
        PENDING_REQUESTS: "คำขอที่รอดำเนินการ",
        DATE: "วันที่",
        READ_ON: "อ่านเมื่อ",
        REQUESTS: "คำขอ",
        TYPE: "ประเภท",
        READ: "อ่านแล้ว",
        UNREAD: "ยังไม่ได้อ่าน",
        ADD_BADGE: "เพิ่มเหรียญตรา",
        BADGE: "เหรียญตรา",
        CANCEL: "ยกเลิก",
        SAVE: "บันทึก",
        GUEST_EMAIL: "อีเมลผู้เข้าพัก",
        ENTER_EMAIL: "โปรดป้อนอีเมลใหม่สำหรับโปรไฟล์นี้",
        NOTE_PROFILE: "โปรดทราบว่านี่อาจใช้เวลาสักพักเพื่ออัปเดตอีเมลใหม่บนโปรไฟล์ของผู้เข้าพัก",
        EMAIL_REQUIRED: "จำเป็นต้องมีอีเมล",
        INVALID_EMAIL: "ที่อยู่อีเมลที่ไม่ถูกต้อง",
        EDIT_STATUS: "แก้ไขสถานะการสมัครสมาชิก",
        PLEASE_CONFIRM: "โปรดยืนยันการเปลี่ยนแปลงการสมัครสมาชิกสำหรับโปรไฟล์ผู้เข้าพักรายนี้",
        UNSUBSCRIBE: "เลิกสมัครสมาชิก",
        NOTE_UNDO: "โปรดทราบ: คุณไม่สามารถยกเลิกการกระทำนี้ได้ มีเพียงผู้เข้าพักเท่านั้นที่สามารถสมัครสมาชิกอีกครั้งได้",
        PROFILE: "โปรไฟล์",
        STAYS: "การเข้าพัก",
        ENGAGEMENT: "การมีส่วนร่วม",
        IMPLICIT: "ความยินยอมโดยปริยาย",
        EXPLICIT: "ความยินยอมแบบชัดแจ้ง",
        DOUBLE: "เลือกรับสองครั้ง",
        UNSUBSCRIBED: "เลิกสมัครสมาชิกแล้ว"
      },
      REPORTS: {
        ONE_TIME: "ครั้งเดียว",
        STATS: "สถิติ",
        REVENUE_DETAIL: "รายละเอียดรายได้",
        AUTOMATED: "ทำให้เป็นอัตโนมัติแล้ว",
        SEARCH_CAMPAIGN_NAME: "ค้นหาตามชื่อแคมเปญ",
        SEARCH: "ค้นหา",
        CLEAR: "ล้าง",
        CAMPAIGN_NAME: "ชื่อแคมเปญ",
        BOOKINGS: "การจอง",
        SENT: "ส่งแล้ว",
        OPENED: "เปิดแล้ว",
        CLICKED: "คลิกแล้ว",
        LAST_MODIFIED: "แก้ไขล่าสุด",
        REPORT: "รายงาน",
        PREVIEW: "ดูตัวอย่าง",
        NO_RESULTS: "ไม่พบผลลัพธ์"
      },
      CREDITS: "เครดิตอีเมล",
      SUB_HEADER: {
        QUIT: "ออก",
        SAVE_QUIT: "บันทึกและออก",
        SEND_TEST: "ส่งการทดสอบ",
        SCHEDULE: "กำหนดการ",
        ACTIVATE: "เปิดใช้งาน",
        CANCEL_SCHEDULE: "ยกเลิกกำหนดการ",
        DEACTIVATE: "ปิดใช้งาน",
        NEXT_STEP: "ขั้นตอนต่อไป"
      },
      CAMPAIGNS: {
        REVENUE: "รายได้",
        SEARCH_PLACEHOLDER: "ค้นหาชื่อ อีเมล ข้อมูลอ้างอิง",
        RESERVATIONS: "การจอง",
        SENT: "ส่งแล้ว",
        OPENED: "เปิดแล้ว",
        CLICKED: "คลิกแล้ว",
        DELIVERED: "ส่งแล้ว",
        BOUNCES: "ถูกตีกลับ",
        UNSUBSCRIBE: "เลิกสมัครสมาชิก",
        SPAM_REPORTS: "รายงานสแปม",
        DELIVERY_REPORT: "รายงานการส่ง",
        DOWNLOAD_REPORT: "รายงานการดาวน์โหลด",
        DISPATCHED: "ส่งออกแล้ว",
        GUEST: "ผู้เข้าพัก",
        EMAIL: "อีเมล",
        STATUS: "สถานะ",
        NO_RESULTS: "ไม่พบผลลัพธ์",
        ROOM_NIGHTS: "จำนวนคืน",
        OPENS: "เปิด",
        CLICKS: "คลิก",
        TOP_CHANNELS: "ช่องทางอันดับสูงสุด",
        TIME_SINCE_LAST_STAY: "เวลาตั้งแต่การเข้าพักล่าสุด",
        TOP_RATE_CODE_BY_CHANNEL: "รหัสราคาสูงสุดตามช่องทาง",
        RATE_CODE: "รหัสราคา",
        TOTAL: "ยอดรวม",
        BOOKING_LEAD_TIMES: "ระยะเวลาการจองห้องพัก",
        RESERVATION_REPORT: "รายงานการจอง",
        BOOK_DATE: "วันที่จอง",
        SEND_DATE: "วันที่ส่ง",
        NIGHTS: "คืน",
        BOOKING_CHANNEL: "ช่องทางการจอง",
        TOTAL_RATE: "ราคาทั้งหมด",
        REPORTS: "รายงานแคมเปญ",
        ONE_TIME: "ครั้งเดียว",
        AUTOMATED: "ทำให้เป็นอัตโนมัติแล้ว",
        CREATE_CAMPAIGN: "สร้างแคมเปญ",
        CREATE: "สร้าง",
        SEARCH_CAMPAIGN: "ค้นหาตามชื่อแคมเปญ",
        SEARCH: "ค้นหา",
        CLEAR: "ล้าง",
        ALL_STATUSES: "สถานะทั้งหมด",
        DRAFT: "ร่าง",
        SCHEDULED: "กำหนดเวลาแล้ว",
        INACTIVE: "ไม่ได้ใช้งาน",
        ACTIVE: "ใช้งาน",
        CAMPAIGN_NAME: "ชื่อแคมเปญ",
        TYPE: "ประเภท",
        DELIVERY: "การจัดส่ง",
        LAST_UPDATED: "อัปเดตล่าสุด",
        REPORT: "รายงาน",
        CANCEL_SCHEDULE: "ยกเลิกกำหนดการ",
        DEACTIVATE: "ปิดใช้งาน",
        ACTIVATE: 'Activate',
        VIEW: "ดู",
        DUPLICATE: "ทำซ้ำ",
        DELETE: "ลบ",
        DELIVERING: "กำลังส่ง",
        SUSPENDED: "ถูกระงับ",
        REMOVE_CAMPAIGN: "ลบแคมเปญ",
        DO_YOU_REMOVE_CAMPAIGN: "คุณแน่ใจหรือไม่ว่าต้องการลบแคมเปญ",
        CANCEL_CAMPAIGN: "ยกเลิกแคมเปญ",
        DO_YOU_CANCEL_CAMPAIGN: "คุณต้องการยกเลิกแคมเปญที่กำหนดเวลาไว้หรือไม่",
        DEACTIVATE_CAMPAIGN: "ปิดใช้งานแคมเปญ",
        DO_YOU_DEACTIVATE_CAMPAIGN: "คุณต้องการปิดใช้งานแคมเปญหรือไม่",
        ACTIVATE_CAMPAIGN: "เปิดใช้งานแคมเปญ",
        DO_YOU_ACTIVATE_CAMPAIGN: "คุณต้องการเปิดใช้งานแคมเปญหรือไม่",
        SETUP: "ตั้งต่า",
        DESIGN: "ออกแบบ",
        SEGMENTS: "เซกเมนต์",
        SCHEDULE: "กำหนดการ",
        CONFIRMATION: "การยืนยัน",
        SELECT: "เลือก",
        ROOM_OFFERS: "ข้อเสนอห้องพัก",
        CAMPAIGNS_DESCR: "แคมเปญได้รับการออกแบบมาเพื่อเพิ่มรายได้ห้องพักผ่านข้อเสนอและแพ็คเกจ",
        EVENTS_ANNOUNCEMENTS: "อีเวนต์และประกาศ",
        NOTIFY_RECIPIENTS: "แจ้งเตือนผู้รับเกี่ยวกับอีเวนต์พิเศษในที่พัก",
        NEWSLETTER: "จดหมายข่าว",
        CAMPAIGNS_UPDATE_DESCR: "แคมเปญเพื่ออัปเดตข่าวสารหรืออีเวนต์ของโรงแรม ส่งเป็นประจำ",
        MISS_YOU: "เราคิดถึงคุณ",
        CAMPAGINS_SENT: "แคมเปญได้ถูกส่งไปให้ผู้ที่เข้าพักหลังการเข้าพักเพื่อส่งเสริมให้มีการจองใหม่",
        OTHER: "อื่นๆ",
        CREATE_CUSTOM: "สร้างแคมเปญแบบกำหนดเอง",
        PRE_ARRIVAL: "ก่อนการเข้าพัก",
        SEND_EMAIL: "ส่งอีเมลให้ผู้เข้าพักก่อนที่พวกเขาจะมาถึง",
        WELCOME: "ยินดีต้อนรับ",
        SEND_EMAIL_CHECK_IN: "ส่งอีเมลให้ผู้เข้าพักขณะเช็คอิน",
        QUALIFICATION: "คุณสมบัติ",
        SEND_EMAIL_SOON: "ส่งอีเมลทันทีที่ผู้เข้าพักเข้าไปยังเซกเมนต์ ซึ่งจะเป็นการส่งอีเมลเพียงครั้งเดียวเท่านั้น",
        SENT_TO_GUESTS: "ส่งให้ผู้เข้าพักหลังการเข้าพักเพื่อกระตุ้นให้กลับมาใหม่",
        OTA_WINBACK: "การดึงลูกค้า OTA",
        SEND_CAMPAIGN: "ส่งแคมเปญให้ผู้เข้าพักจากตัวแทนการท่องเที่ยวออนไลน์ภายใน 30 วัน หลังจากที่เช็คเอาท์",
        BIRTHDAY: "วันเกิด",
        SEND_SPECIAL: "ส่งข้อเสนอพิเศษเพื่อฉลองวันเกิดหรือวันครบรอบ",
        DOUBLE_OPT: "เลือกรับสองครั้ง",
        AUTOMATICALLY_SEND: "ส่งลิงก์สมัครสมาชิกให้กับผู้เข้าพักโดยอัตโนมัติ เมื่อเพิ่มพวกเขาลงในรายชื่อผู้ติดต่อ",
        CAMPAIGN_NAME_HINT: "มอบชื่อภายในให้กับแคมเปญของคุณเพื่อช่วยจัดระเบียบ และค้นหาแคมเปญภายในบัญชีของคุณได้อย่างง่ายดาย",
        CAMPAIGN_NAME_REQUIRED: "จำเป็นต้องมีชื่อแคมเปญ",
        SUBJECT_LINE: "หัวเรื่อง",
        SUBJECT_LINE_HINT: "เขียนหัวเรื่องที่อธิบายเนื้อหาอีเมลของคุณอย่างชัดเจน ซึ่งจะปรากฏในกล่องจดหมายของผู้รับและเป็นเนื้อหาแรกที่พวกเขาจะได้เห็น",
        SUBJECT_REQUIRED: "จำเป็นต้องมีหัวเรื่อง",
        PREVIEW_LINE: "ดูตัวอย่างบรรทัด",
        PREVIEW_LINE_HINT: "เขียนข้อความสั้นๆ (ประมาณ 35 ตัวอักษร) ที่ให้ข้อมูลภาพรวมเกี่ยวกับอีเมลของคุณ ข้อความดังกล่าวจะแสดงในกล่องจดหมายผู้รับของคุณที่ด้านล่างหัวเรื่อง",
        FROM_EMAIL: "อีเมลผู้ส่ง",
        FROM_EMAIL_HINT: "เลือกที่อยู่อีเมลที่จะแสดงในกล่องจดหมายของผู้รับเมื่อพวกเขาได้รับแคมเปญของคุณ",
        FROM_EMAIL_REQUIRED: "ต้องระบุอีเมลผู้ส่ง",
        FROM_NAME: "ชื่อผู้ส่ง",
        FROM_NAME_HINT: "ป้อนชื่อ (เช่น ชื่อบริษัทของคุณ) เพื่อช่วยให้ผู้รับแคมเปญจดจำคุณในกล่องจดหมายของพวกเขาได้",
        FROM_NAME_REQUIRED: "ต้องระบุชื่อผู้ส่ง",
        MY_TEMPLATES: "เทมเพลตของฉัน",
        TEMPLATE_GALLERY: "แกลเลอรี่เทมเพลต",
        DESIGN_TOOLS: "เครื่องมือออกแบบ",
        DRAG_DROP_EDITOR: "เครื่องมือแก้ไขแบบลากและวาง",
        DRAG_DROP_EDITOR_DESC: "สร้างอีเมลที่เหมาะกับอุปกรณ์เคลื่อนที่ ซึ่งจะดูดีบนอุปกรณ์หรือหน้าจอทุกขนาด",
        RICH_TEXT_EDITOR: "เครื่องมือแก้ไข Rich Text",
        RICH_TEXT_EDITOR_DESC: "ใช้เครื่องมือแก้ไข Rich Text เพื่อสร้างอีเมลที่เรียบง่าย",
        PASTE_YOUR_CODE: "วางรหัสของคุณ",
        PASTE_YOUR_CODE_DESC: "คัดลอกและวางรหัส HTML ของคุณ",
        RESET_EMAIL_DESIGN: "รีเซ็ตการออกแบบอีเมล",
        EDIT_EMAIL_CONTENT: "แก้ไขเนื้อหาอีเมล",
        LOAD_SAVED_VERSION: "โหลดเวอร์ชันที่บันทึกไว้",
        VERSION: "เวอร์ชัน",
        SAVE: "บันทึก",
        SAVE_QUIT: "บันทึกและออก",
        QUIT_WITHOUT_SAVING: "ออกโดยไม่บันทึก",
        HTML_EDITOR: "เครื่องมือแก้ไข HTML",
        ENTER_HTML: "ใส่ HTML",
        CANCEL: "ยกเลิก",
        CHOOSE_DIFFERENT_SEGMENT: "เลือกเซกเมนต์อื่น",
        ADDITIONAL_OPTIONS: "ตัวเลือกเพิ่มเติม",
        FILTER_PROPERTIES: "กรองที่พัก",
        ALL_PROPERTIES: "ที่พักทั้งหมด",
        ENTER_PROPERTY_TO_FILTER: "ป้อนที่พักเพื่อกรอง...",
        ONLY_INCLUDE_GUESTS_WHO_HAVE: "รวมเฉพาะผู้เข้าพักที่มี",
        RECEIVED: "ได้รับแล้ว",
        NOT_RECEIVED: "ยังไม่ได้รับ",
        OPENED_LOWER: "เปิดแล้ว",
        NOT_OPENED: "ยังไม่เปิด",
        CLICKED_LOWER: "คลิกแล้ว",
        NOT_CLICKED: "ยังไม่คลิก",
        BEEN_SENT: "ส่งแล้ว",
        NOT_SENT: "ยังไม่ได้ส่ง",
        ANY_EXISTING_CAMPAIGN_IN_LAST: "แคมเปญที่มีอยู่ทั้งหมดในช่วงก่อนหน้า",
        DAY: "วัน",
        WEEK: "สัปดาห์",
        TWO_WEEKS: "2 สัปดาห์",
        MONTH: "เดือน",
        YEAR: "ปี",
        TWO_YEARS: "2 ปี",
        ENABLE_SAFE_MODE: "เปิดโหมดปลอดภัย",
        BEST_PRACTICE: "แนวทางปฏิบัติ GDPR ที่ดีที่สุด",
        GUESTS_MUST_HAVE: "ผู้เข้าพักจำเป็นต้องมี",
        IMPLICIT_CONSENT: "ความยินยอมโดยปริยาย",
        SAFE: "ปลอดภัย",
        EXPLICIT_CONSENT: "ความยินยอมแบบชัดแจ้ง",
        SAFER: "ปลอดภัยกว่า",
        DOUBLE_OPT_IN_CONSENT: "ความยินยอมแบบเลือกรับสองครั้ง",
        SAFEST: "ปลอดภัยที่สุด",
        EXCLUDE_EMAIL_FUTURE_STAYS: "ไม่รวมที่อยู่อีเมลที่มีการเข้าพักในอนาคต",
        REFRESH_STATISTICS: "รีเฟรชค่าสถิติ",
        SELECT_SCHEDULE_OPTION: "โปรดเลือกตัวเลือกกำหนดการสำหรับแคมเปญของคุณ:",
        SPECIFIC_DATE_TIME: "วันที่และเวลาที่เฉพาะเจาะจง",
        IMMEDIATELY: "ทันที",
        BASED_ON_GUEST_EVENT: "อ้างอิงตามอีเวนต์ของผู้เข้าพัก",
        AT: "ที่",
        HH: "ชช",
        MM: "ดด",
        ON: "ใน",
        HOUR_REQUIRED: "จำเป็นต้องระบุชั่วโมง",
        MINUTES_REQUIRED: "จำเป็นต้องระบุนาที",
        SELECT_DATE: "เลือกวันที่",
        DATE_REQUIRED: "ต้องมีวันที่",
        DATE_MUST_BE_FUTURE: "วันที่ต้องอยู่ในอนาคต",
        SELECT_DAYS: "เลือกวัน",
        DAYS_REQUIRED: "จำเป็นต้องระบุวัน",
        DAYS: "วัน",
        SELECT_EVENT: "เลือกอีเวนต์",
        EVENT_REQUIRED: "จำเป็นต้องมีอีเวนต์",
        SELECT_LIST: "เลือกรายการ",
        LIST_REQUIRED: "จำเป็นต้องมีรายการ",
        CAMPAIGN_READY: "แคมเปญของคุณพร้อมส่งแล้ว!",
        REVIEW_REPORT_BEFORE_SENDING: "ดูตัวอย่างรายงานด้านล่างก่อนที่จะส่งแคมเปญของคุณ",
        SUBJECT: "หัวข้อ",
        FROM: "จาก",
        ROOM_REVENUE_TRACKING: "การติดตามรายได้ห้องพัก",
        DOUBLE_OPT_IN_URL: "URL เลือกรับสองครั้ง",
        DOUBLE_OPT_IN_URL_HINT: "นี่คือ URL ที่ผู้ใช้จะถูกเปลี่ยนเส้นทาง ในระหว่างขั้นตอนการเลือกรับสองครั้ง",
        DOUBLE_OPT_IN_URL_REQUIRED: "จำเป็นต้องมี URL เลือกรับสองครั้ง",
        VALID_URL_REQUIRED: "โปรดป้อน URL ที่ถูกต้อง",
        YOUR_DESIGN: "การออกแบบของคุณ",
        USED: "คุณใช้",
        CREATE_DESIGN: "เพื่อสร้างการออกแบบของคุณ",
        EDIT_CONTENT: "แก้ไขเนื้อหา",
        ADDITIONAL_FILTERS: "ตัวกรองเพิ่มเติม",
        SEND_IMMEDIATELY: "ส่งทันที",
        SEND_TEST_EMAIL: "ส่งอีเมลทดสอบ",
        ENTER_EMAILS: "ใส่อีเมล",
        ENTER_EMAILS_DESC: "ป้อนรายการอีเมล หนึ่งอีเมลต่อบรรทัด",
        SEND_TEST: "ส่งการทดสอบ",
        SCHEDULE_CAMPAIGN: "กำหนดการแคมเปญ",
        SURE_DELIVERY: "คุณแน่ใจหรือไม่ว่าคุณต้องการจัดกำหนดการส่งแคมเปญของคุณ",
        CAMPAIGN_IN_LAST: " แคมเปญใดๆ ในช่วงก่อนหน้า ",
        SEARCH_TEMPLATE_NAME: "ค้นหาตามชื่อเทมเพลต",
        CREATE_TEMPLATE: "สร้างเทมเพลต",
        EDIT_CAMPAIGN: "แก้ไขแคมเปญ",
        TEMPLATE_NAME: "ชื่อเทมเพลต",
        EDIT_TEMPLATE: "แก้ไขเทมเพลต",
        MORE_OPTIONS: "ตัวเลือกเพิ่มเติม",
        TEMPLATE_NAME_HINT: "มอบชื่อภายในให้กับเทมเพลตของคุณเพื่อช่วยจัดระเบียบ และค้นหาแคมเปญภายในบัญชีของคุณได้อย่างง่ายดาย",
        TEMPLATE_NAME_REQUIRED: "จำเป็นต้องมีชื่อเทมเพลต",
        LIST_SELECTED: "รายการที่เลือก",
        LISTS_SELECTED: " รายการที่เลือก",
        ALL_LISTS_SELECTED: "เลือกรายการทั้งหมดแล้ว",
        BEFORE_CHECK_IN: "ก่อนเช็คอิน",
        AFTER_CHECK_IN: "หลังจากเช็คอิน",
        BEFORE_CHECK_OUT: "ก่อนเช็คเอ้าท์",
        AFTER_LAST_CHECK_OUT: "หลังจากเช็คเอาท์ล่าสุด",
        ON_CHECK_OUT: "เมื่อเช็คเอาท์",
        ON_CHECK_IN: "เมื่อเช็กอิน",
        ON_DAY_OF_CHECK_IN: "ในวันที่เช็คอิน",
        BEFORE_BIRTHDAY: "ก่อนวันเกิด",
        BEFORE_ANNIVERSARY: "ก่อนวันครบรอบ",
        ON_LIST_JOINED: "ในรายการที่เข้าร่วม",
        AT_SPASE: "ในเวลา ",
        UNLAYER_LANGUAGE: "th-TH",
        EDITOR_LANGUAGE: "th"
      }
    },
    MENU: {
      NEW: "ใหม่",
      ACTIONS: "การกระทำ",
      CREATE_POST: "สร้างโพสต์ใหม่",
      PAGES: "หน้า",
      FEATURES: "แนะนำ",
      APPS: "แอป",
      HOME: "หน้าหลัก"
    },
    AUTH: {
      GENERAL: {
        OR: "หรือ",
        SUBMIT_BUTTON: "ส่ง",
        NO_ACCOUNT: "ไม่มีบัญชีใช่หรือไม่",
        SIGNUP_BUTTON: "สมัคร",
        FORGOT_BUTTON: "ลืมรหัสผ่าน",
        BACK_BUTTON: "ย้อนกลับ",
        PRIVACY: "ความเป็นส่วนตัว",
        LEGAL: "กฎหมาย",
        CONTACT: "ข้อมูลติดต่อ"
      },
      LOGIN: {
        TITLE: "บัญชีเข้าสู่ระบบ",
        BUTTON: "เข้าสู่ระบบ"
      },
      FORGOT: {
        TITLE: "ลืมรหัสผ่านใช่หรือไม่",
        DESC: "ป้อนอีเมลของคุณเพื่อรีเซ็ตรหัสผ่าน",
        SUCCESS: "รีเซ็ตบัญชีของคุณสำเร็จแล้ว"
      },
      REGISTER: {
        TITLE: "สมัคร",
        DESC: "ป้อนรายละเอียดของคุณเพื่อสร้างบัญชีของคุณ",
        SUCCESS: "ลงทะเบียนบัญชีของคุณสำเร็จแล้ว"
      },
      INPUT: {
        EMAIL: "อีเมล",
        FULLNAME: "ชื่อ",
        PASSWORD: "รหัสผ่าน",
        CONFIRM_PASSWORD: "ยืนยันรหัสผ่าน",
        USERNAME: "ชื่อผู้ใช้"
      },
      VALIDATION: {
        INVALID: "{{name}} ไม่ถูกต้อง",
        REQUIRED: "จำเป็นต้องมี {{name}}",
        MIN_LENGTH: "{{name}} มีความยาวขั้นต่ำคือ {{min}}",
        AGREEMENT_REQUIRED: "จำเป็นต้องยอมรับข้อกำหนดและเงื่อนไข",
        NOT_FOUND: "ไม่พบ {{name}} ที่ส่งคำขอ",
        INVALID_LOGIN: "รายละเอียดการเข้าสู่ระบบไม่ถูกต้อง",
        REQUIRED_FIELD: "ช่องที่จำเป็น",
        MIN_LENGTH_FIELD: "ความยาวช่องขั้นต่ำ:",
        MAX_LENGTH_FIELD: "ความยาวช่องสูงสุด:",
        INVALID_FIELD: "ช่องไม่ถูกต้อง"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "จำนวนบันทึกที่เลือก: ",
        ALL: "ทั้งหมด",
        SUSPENDED: "ถูกระงับ",
        ACTIVE: "ใช้งาน",
        FILTER: "ตัวกรอง",
        BY_STATUS: "ตามสถานะ",
        BY_TYPE: "ตามประเภท",
        BUSINESS: "ธุรกิจ",
        INDIVIDUAL: "รายบุคคล",
        SEARCH: "ค้นหา",
        IN_ALL_FIELDS: "ในช่องทั้งหมด"
      },
      ECOMMERCE: "อีคอมเมิร์ซ",
      CUSTOMERS: {
        CUSTOMERS: "ผู้เข้าพัก",
        CUSTOMERS_LIST: "รายชื่อผู้เข้าพัก",
        NEW_CUSTOMER: "ผู้เข้าพักคนใหม่",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "ลบผู้เข้าพัก",
          DESCRIPTION: "คุณต้องการลบผู้เข้าพักรายนี้แบบถาวรหรือไม่",
          WAIT_DESCRIPTION: "ผู้เข้าพักกำลังลบ...",
          MESSAGE: "ลบผู้เข้าพักแล้ว"
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "ลบผู้เข้าพัก",
          DESCRIPTION: "คุณต้องการลบผู้เข้าพักที่เลือกแบบถาวรหรือไม่",
          WAIT_DESCRIPTION: "ผู้เข้าพักกำลังลบ...",
          MESSAGE: "ลบผู้เข้าพักที่เลือกแล้ว"
        },
        UPDATE_STATUS: {
          TITLE: "อัปเดตสถานะสำหรับผู้เข้าพักที่เลือกแล้ว",
          MESSAGE: "อัปเดตสถานะผู้เข้าพักที่เลือกเสร็จแล้ว"
        },
        EDIT: {
          UPDATE_MESSAGE: "อัปเดตผู้เข้าพักแล้ว",
          ADD_MESSAGE: "สร้างผู้เข้าพักแล้ว"
        }
      }
    },
    Jan: "ม.ค.",
    Feb: "ก.พ.",
    Mar: "มี.ค.",
    Apr: "เม.ย.",
    May: "พ.ค.",
    Jun: "มิ.ย.",
    Jul: "ก.ค.",
    Aug: "ส.ค.",
    Sep: "ก.ย.",
    Oct: "ต.ค.",
    Nov: "พ.ย.",
    Dec: "ธ.ค.",
    Clicks: "คลิก",
    Opens: "เปิด",
    '< 7 days': "< 7 วัน",
    '7-14 Days': "7-14 วัน",
    '15-30 Days': "15-30 วัน",
    '31-60 Days': "31-60 วัน",
    '61-90 Days': "61-90 วัน",
    '> 90 Days': "> 90 วัน",
    '< 1 month': "< 1 เดือน",
    '1-2 Months': "1-2 เดือน",
    '2-6 Months': "2-6 เดือน",
    '6-12 Months': "6-12 เดือน",
    '> 1 Year': "> 1 ปี",
    Sent: "ส่งแล้ว",
    Delivered: "ส่งแล้ว",
    Open: "เปิด",
    Spam: "สแปม",
    Click: "คลิก",
    HardBounce: "ตีกลับแบบถาวร",
    SoftBounce: "ตีกลับแบบชั่วคราว",
    Unsubscribe: "เลิกสมัครสมาชิก",
    InvalidEmail: "อีเมลไม่ถูกต้อง",
    Blocked: "ถูกบล็อก",
    Complaint: "คำร้องเรียน",
    Deferred: "เลื่อนแล้ว",
    ListAddition: "การเพิ่มรายการ",
    Overview: "ภาพรวม",
    "Guest List": "รายชื่อผู้เข้าพัก",
    "Guest Lists": "รายชื่อผู้เข้าพัก",
    "Guest Segments": "เซกเมนต์ของผู้เข้าพัก",
    Campaigns: "แคมเปญ",
    Templates: "เทมเพลต"
  }
};