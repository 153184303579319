import { Injectable } from '@angular/core';
import { SettingType } from '../_models/setting-type.enum';
import { Setting } from '../_models/setting.model';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class SettingService {

	constructor() { }

	getSetting(settingType: SettingType, hours: number = 2): Setting {
		let data = localStorage.getItem(settingType.toString());
		if (!data) {
			return null;
		}
		let setting = JSON.parse(data) as Setting;
		if (hours && (moment().diff(moment(setting.date), 'hours') > hours)) {
			return null;
		}

		if ((settingType == SettingType.LastHotel) && (setting)) {
			if (!Array.isArray(setting.value)) {
				if (!isNaN(setting.value)) {
					setting.value = [setting.value];
				} else {
					setting = null;
				}
			}
		}
		return setting;
	}

	setSetting(setting: Setting) {
		setting.date = new Date();
		localStorage.setItem(setting.settingType.toString(), JSON.stringify(setting));
	}
}
