// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { HotelService } from '../../_services/hotel.service';
import { HotelDto } from '../../_models/dtos/hotel-dto.model';

@Injectable()
export class ModuleGuard implements CanActivate {
    constructor(private router: Router, private hotelService: HotelService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        const modulePermissions = route.data['permissions'] as string;
		if (!modulePermissions) {
            return of(true);
        }
		return this.hotelService.getAll()
			.pipe(
				map((hotels: HotelDto[]) => {
					let allPermissions = [].concat(...hotels.map(h => h.permissions));
					return !!allPermissions.find(p => p.toLocaleLowerCase() == modulePermissions.toLocaleLowerCase());
				}),
				tap(hasAccess => {
					if (!hasAccess) {
						this.router.navigate(['/reports/pickup']);
						return false;
					}
				})
			);
    }
}
