export enum MetaMarketingType {
	None,
	GoogleHotelAds,
	TripAdvisor,
	Direct,
	Kayak,
	Trivago,
	GoogleAdwords,
	TripAdvisorMobile,
	Email
}
