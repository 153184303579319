import { L } from "@angular/cdk/keycodes";

// USA
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: "Sélectionner votre langue"
    },
    USER_PROFILE: {
      SETTINGS: "Paramètres",
      HOTEL_OPTIONS: "Options et configuration de l'hôtel",
      HELP: "Assistance",
      HELP_GUID: "Guide d'aide complet et assistance",
      THEME: "Thème",
      LANGUAGE: "Langue",
      SIGN_OUT: "Déconnexion",
      USERS: "Utilisateurs",
      MANAGE_USERS: "Gérer les utilisateurs et les droits d'accès à l'hôtel"
    },
    CONNECTS: {
      MENU: {
        OVERVIEW: "Aperçu",
        GUESTS_SEGMENTS: "Clients et segments",
        GUESTS: "Clients",
        SEGMENTS: 'Segments',
        LISTS: "Listes",
        CAMPAIGNS: "Campagnes",
        CAMPAIGN_LIST: "Liste de campagne",
        TEMPLATES: "Modèles",
        REPORTS: "Rapports"
      },
      OVERVIEW: {
        TOTAL: "Total des profils",
        REACHABLE: "Profils accessibles",
        UNREACHABLE: "Profils non accessibles",
        EMAIL_COVERAGE: "Couverture par e-mail",
        CAMPAIGN: "Revenus par chambre de la campagne",
        YEAR: "Année à ce jour",
        ONE_TIME: "Revenus de la campagne unique",
        LAST: "Les 5 derniers revenus",
        ALL: "Tout afficher",
        LIFETIME: "Revenus depuis le début",
        REVENUE: "Total des revenus",
        ARRIVALS: "Arrivées",
        NUMBER_OF_GUESTS: "Nombre de clients",
        ROOM_REVENUE: "Revenus de la chambre",
        NAME: "Nom",
        NIGHTS: "Nuits",
        LIFETIME_VALUE: "Valeur depuis le début",
        EMAIL: "E-mail",
        DEPARTURES: "Départs"
      },
      GUESTS: {
        ALL_GUESTS: "Tous les clients",
        ARRIVALS: "Arrivées",
        HOUSE: "Client hébergé",
        DEPARTURES: "Départs",
        SEARCH_PLACEHOLDER: "Recherche par nom, e-mail, référence",
        DATE_PLACEHOLDER: "Sélectionner la plage de dates",
        FILTERS: "Filtres",
        CHANGE_HOTEL: "Changer d'hôtel",
        SEARCH: "Rechercher",
        CLEAR: "Effacer",
        GUEST: "Client",
        NIGHTS: "Nuits",
        ROOM_TYPE: "Type de chambre",
        REVENUE: "Revenus",
        BOOKING: "Canal de réservation",
        RATE: "Code tarifaire",
        EMAIL: "E-mail",
        PREVIOUS: "Séjours précédents",
        LIFETIME: "Valeur depuis le début",
        NOTES: 'Notes',
        NO_RESULTS: "Aucun résultat n'a été trouvé",
        ITEMS: "Nombre d'articles par page :"
      },
      SEGMENTS: {
        SEARCH_SEGMENTS: "Recherche par nom de segment",
        CREATE_SEGMENT: "Créer un segment de clients",
        EDIT_SEGMENT: "Modifier le segment de clients",
        REFRESH: "Actualiser maintenant",
        STATISTICS: "Dernière mise à jour des statistiques",
        NO_RESULTS: "Aucun résultat n'a été trouvé",
        REACHABLE: "Accessible par e-mail",
        PROFILES: "Profils",
        AVARAGE_NIGHTS: "Nombre moyen de nuits",
        AVARAGE_RATE: "Taux journalier moyen",
        OR: "OU",
        EXCLUDE_BOOKINGS: "Exclure les réservations avec des séjours futurs",
        VIEW_DETAILS: "Afficher les détails",
        SELECT: "Sélectionner",
        PEOPLE_IN_SEGMENT: "personnes dans ce segment",
        TOTAL_LIFETIME: "dépenses totales depuis le début",
        SEARCH_PLACEHOLDER: "Recherche par nom, e-mail, référence",
        SEARCH: "Rechercher",
        CLEAR: "Effacer",
        GUEST: "Client",
        EMAIL: "E-mail",
        LIFETIME_VALUE: "Valeur depuis le début",
        LIFETIME_STAYS: "Séjours depuis le début",
        TOTAL_NIGHTS: "Nombre total de nuits",
        CLOSE: "Fermer",
        ITEMS: "Nombre d'articles par page :",
        SEGMENT_NAME: "Nom du segment",
        SEGMENT_REQUIRED: "Le nom du segment est obligatoire",
        CONTACT_LISTS: "Listes de contacts",
        ENTER_CONTACT: "Saisir une liste de contacts...",
        PROFILES_SEGMENT: "Profils dans ce segment",
        TOTAL_LIFETIME_UPPER: "Dépenses totales depuis le début",
        REFRESH_STATICITCS: "Actualiser les statistiques",
        FIND_GUESTS: "Trouver des clients qui répondent aux conditions suivantes",
        AND: "ET",
        SEARCH_VALUE: "Saisir la valeur de la recherche",
        ENTER_DISTANCE: "Saisir la distance",
        IMPLICIT_CONSENT: "Consentement implicite (sûr)",
        EXPLICIT_CONSENT: "Consentement explicite (sécurité renforcée)",
        DOUBLE_CONSENT: "Double consentement (le plus sûr)",
        ENTER_DATE: "Saisir une plage de dates",
        START_DATE: "Date de début",
        END_DATE: "Date de fin",
        START_DAYS: "Jours de début",
        END_DAYS: "Jours de fin",
        LAST_DAYS: "Derniers jours",
        EXCLUDE_GUESTS: "Exclure les clients avec des séjours futurs",
        CORRECT_FILTERS: "Veuillez corriger les erreurs dans les filtres de segment",
        CANCEL: "Annuler",
        SAVE: "Enregistrer",
        BETWEEN_DATES: "entre dates",
        BETWEEN_FUTURE: "entre jours futurs",
        DAYS: "jours",
        BETWEEN_PAST: "entre jours précédents",
        LAST: "dans le(s) dernier(s)",
        EQUAL: "égal à",
        NOT_EQUAL: "n'est pas égal à",
        GREATER: "supérieur à",
        LESS: "inférieur à",
        KM: 'km',
        CONTAINS: "contient",
        NOT_CONTAINS: "ne contient pas",
        IS_EQUAL: "est égal à",
        IS_NOT_EQUAL: "n'est pas égal à",
        LEAST: "a au moins",
        ANNIVERSARY: "Célébration",
        BADGE: 'Badge',
        BIRTHDAY: "Anniversaire",
        COMPANY: "Société",
        EMAIL_ADDRESS: "Adresse e-mail",
        GENDER: "Sexe",
        LANGUAGE: "Langue",
        LIFETIME_ROOM: "Dépenses de chambre depuis le début",
        LOCATION: "Lieu",
        TOTAL_NUMBER_NIGHTS: "Nombre total de nuits",
        TOTAL_NUMBER_STAYS: "Nombre total de séjours",
        SUBSCRIPTION: "Statut de l'abonnement",
        BOOKING: "Canal de réservation",
        BOOKING_DATE: "Date de réservation",
        BOOKING_LEAD: "Délai d'exécution de la réservation",
        CHECK_IN_DATE: "Date d'arrivée",
        CHECK_OUT_DATE: "Date de départ",
        LENGTH: "Durée du séjour",
        MARKET: "Code du marché",
        RATE: "Code tarifaire",
        ROOM: "Code de la chambre",
        RATE_AVARAGE: "Tarif moyen",
        SOURCE: "Source d'affaires",
        STAY: "Date du séjour",
        DEMOGRAPHICS: "Données démographiques",
        MARKETING: "Marketing par e-mail",
        STAY_DATA: "Données de séjour",
        IMPORT_GUESTS: "Importer les clients",
        ADD_LIST: "Ajouter une liste",
        HOTEL_NAME: "Nom de l'hôtel",
        GROUPWIDE: "Au niveau du groupe",
        NAME: "Nom",
        GUESTS: "Clients",
        EDIT_LIST: "Modifier la liste",
        UPDATE_LIST: "Mettre à jour la liste",
        LIST_NAME: "Nom de la liste",
        ROOM_REQUIRED: "Le nom du type de chambre est obligatoire",
        SELECT_HOTEL: "Sélectionner un hôtel",
        SELECT_DATA: "Sélectionner les données",
        SELECT_LIST: "Sélectionner une liste",
        PLEASE_SELECT_LIST: "Veuillez sélectionner une liste",
        SUBSCRIPTION_STATUS: "Statut de l'abonnement",
        UNSUBSCRIBED: "Désabonné",
        IMPLICIT: "Implicite",
        EXPLICIT: "Explicite",
        DOUBLE_OPT_IN: "Double consentement",
        PLEASE_SELECT_SUBSCRIPTION: "Veuillez sélectionner un statut d'abonnement",
        PLEASE_UPLOAD_FILE: "Veuillez télécharger un fichier",
        DOWNLOAD_EXAMPLE: "Télécharger un exemple de fichier .csv",
        NEXT: "Suivant",
        COMPLETE_MAPPINGS: "Terminer les mappages",
        BACK: "Retour",
        IMPORT: "Importer",
        ERROR_UPLOADING: "Désolé, une erreur est survenue lors du téléchargement des clients",
        UPLOAD_FILE: "Télécharger un fichier",
        NO_FILE: "Aucun fichier choisi"
      },
      GUESTS_PROFILE: {
        LEAD_TIME: "Délai d'exécution",
        LIFETIME_STAYS: "Séjours depuis le début",
        LIFETIME_NIGHTS: "Nuits depuis le début",
        LIFETIME_SPEND: "Dépenses depuis le début",
        STATUS: "Statut",
        HOUSE: "Client hébergé",
        LAST_STAY: "Dernier séjour",
        NEXT_STAY: "Prochain séjour",
        NIGHTS: "Nuits",
        TODAY: "Aujourd'hui",
        PERSONAL: "Personnel",
        TITLE: "Titre",
        FIRST_NAME: "Prénom",
        LAST_NAME: "Nom",
        GENDER: "Sexe",
        LANGUAGE: "Langue",
        COMPANY: "Société",
        JOB_TITLE: "Titre du poste",
        ADDRESS_1: "Adresse 1",
        ADDRESS_2: "Adresse 2",
        CITY_COUNTY: "Ville, département",
        POST_CODE: "Code postal",
        COUNTRY: "Pays",
        OTHER: "Autre",
        BIRTHDAY: "Anniversaire",
        ANNIVERSARY: "Célébration",
        PROFILE_NOTES: "Notes de profil",
        EMAIL: "E-mail",
        SUBSCRIPTION_STATUS: "Statut de l'abonnement",
        BADGES: 'Badges',
        CONTACT_LISTS: "Listes de contacts",
        BOOKING_NOTES: "Notes de réservation",
        LOYALTY: "Fidélité",
        FIRST_STAY: "Premier séjour avec le groupe",
        LIFETIME_VALID_STAYS: "Séjours valables depuis le début",
        LIFETIME_CANCELLED: "Séjours annulés depuis le début",
        LIFETIME_VALID_NIGHTS: "Nuits valables depuis le début",
        CURRENT_STAY: "Séjour actuel",
        NO_STAYS: "Aucun séjour futur",
        CHECK_IN: "Arrivée",
        CHECK_OUT: "Départ",
        OF_NIGHTS: "# de nuitées ",
        BOOKING_REF: "Réf. de réservation",
        HOTEL: "Hôtel",
        ROOM_TYPE: "Type de chambre",
        RATE: "Tarif",
        CHANNEL: "Canal",
        GUESTS: "Clients",
        ROOM: "Chambre",
        BOOKING_DATE: "Date de réservation",
        NIGTH: "nuit",
        ALL_STAYS: "Tous les séjours",
        CANCELLED: "Annulé",
        IN_HOUSE: "Client hébergé",
        GUEST_MARKETING: "Marketing pour les clients",
        DATE_SENT: "Date d'envoi",
        EVENT_DATE: "Date de l'événement",
        CAMPAIGN: "Campagne",
        PENDING_REQUESTS: "Demandes en attente",
        DATE: 'Date',
        READ_ON: "Lire la suite",
        REQUESTS: "Demandes",
        TYPE: 'Type',
        READ: "Lu",
        UNREAD: "Non lu",
        ADD_BADGE: "Ajouter un badge",
        BADGE: 'Badge',
        CANCEL: "Annuler",
        SAVE: "Enregistrer",
        GUEST_EMAIL: "E-mail du client",
        ENTER_EMAIL: "Veuillez saisir la nouvelle adresse e-mail pour ce profil.",
        NOTE_PROFILE: "Notez qu'il peut s'écouler plusieurs minutes avant que le nouvel e-mail ne soit mis à jour dans le profil du client",
        EMAIL_REQUIRED: "L'e-mail est obligatoire.",
        INVALID_EMAIL: "Adresse e-mail non valide.",
        EDIT_STATUS: "Modifier le statut de l'abonnement",
        PLEASE_CONFIRM: "Veuillez confirmer la modification du statut de l'abonnement pour ce profil de client",
        UNSUBSCRIBE: "Se désabonner",
        NOTE_UNDO: "Remarque : cette action est irréversible. Seul le client peut s'abonner à nouveau.",
        PROFILE: "Profil",
        STAYS: "Séjours",
        ENGAGEMENT: 'Engagement',
        IMPLICIT: "Consentement implicite",
        EXPLICIT: "Consentement explicite",
        DOUBLE: "Double consentement",
        UNSUBSCRIBED: "Désabonné"
      },
      REPORTS: {
        ONE_TIME: "Unique",
        STATS: "Statistiques",
        REVENUE_DETAIL: "Détail des revenus",
        AUTOMATED: "Automatisé",
        SEARCH_CAMPAIGN_NAME: "Recherche par nom de campagne",
        SEARCH: "Rechercher",
        CLEAR: "Effacer",
        CAMPAIGN_NAME: "Nom de la campagne",
        BOOKINGS: "Réservations",
        SENT: "Envoyé",
        OPENED: "Ouvert",
        CLICKED: "Cliqué",
        LAST_MODIFIED: "Dernière modification",
        REPORT: "Rapport",
        PREVIEW: "Aperçu",
        NO_RESULTS: "Aucun résultat n'a été trouvé"
      },
      CREDITS: "Crédits e-mail",
      SUB_HEADER: {
        QUIT: "Quitter",
        SAVE_QUIT: "Enregistrer et quitter",
        SEND_TEST: "Envoyer un test",
        SCHEDULE: "Planifier",
        ACTIVATE: "Activer",
        CANCEL_SCHEDULE: "Annuler la planification",
        DEACTIVATE: "Désactiver",
        NEXT_STEP: "Étape suivante"
      },
      CAMPAIGNS: {
        REVENUE: "Revenus",
        SEARCH_PLACEHOLDER: "Recherche par nom, e-mail, référence",
        RESERVATIONS: "Réservations",
        SENT: "Envoyé",
        OPENED: "Ouvert",
        CLICKED: "Cliqué",
        DELIVERED: "Distribué",
        BOUNCES: "Rebonds",
        UNSUBSCRIBE: "Se désabonner",
        SPAM_REPORTS: "Rapports sur les spams",
        DELIVERY_REPORT: "Rapport de distribution",
        DOWNLOAD_REPORT: "Télécharger le rapport",
        DISPATCHED: "Distribué",
        GUEST: "Client",
        EMAIL: "E-mail",
        STATUS: "Statut",
        NO_RESULTS: "Aucun résultat n'a été trouvé",
        ROOM_NIGHTS: "Nombre de nuitées",
        OPENS: "Ouvertures",
        CLICKS: "Clics",
        TOP_CHANNELS: "Canaux principaux",
        TIME_SINCE_LAST_STAY: "Temps écoulé depuis le dernier séjour",
        TOP_RATE_CODE_BY_CHANNEL: "Code tarifaire principal par canal",
        RATE_CODE: "Code tarifaire",
        TOTAL: 'Total',
        BOOKING_LEAD_TIMES: "Délais d'exécution de la réservation",
        RESERVATION_REPORT: "Rapport de réservation",
        BOOK_DATE: "Réserver les dates",
        SEND_DATE: "Date d'envoi",
        NIGHTS: "Nuits",
        BOOKING_CHANNEL: "Canal de réservation",
        TOTAL_RATE: "Tarif total",
        REPORTS: "Rapport de campagne",
        ONE_TIME: "Unique",
        AUTOMATED: "Automatisé",
        CREATE_CAMPAIGN: "Créer une campagne",
        CREATE: "Créer",
        SEARCH_CAMPAIGN: "Recherche par nom de campagne",
        SEARCH: "Rechercher",
        CLEAR: "Effacer",
        ALL_STATUSES: "Tous les statuts",
        DRAFT: "Brouillon",
        SCHEDULED: "Planifié",
        INACTIVE: "Inactif",
        ACTIVE: "Actif",
        CAMPAIGN_NAME: "Nom de la campagne",
        TYPE: 'Type',
        DELIVERY: "Livraison",
        LAST_UPDATED: "Dernière mise à jour",
        REPORT: "Rapport",
        CANCEL_SCHEDULE: "Annuler la planification",
        DEACTIVATE: "Désactiver",
        ACTIVATE: 'Activate',
        VIEW: "Afficher",
        DUPLICATE: "Dupliquer",
        DELETE: "Supprimer",
        DELIVERING: "Distribution",
        SUSPENDED: "Suspendu",
        REMOVE_CAMPAIGN: "Supprimer la campagne",
        DO_YOU_REMOVE_CAMPAIGN: "Voulez-vous vraiment supprimer la campagne ?",
        CANCEL_CAMPAIGN: "Annuler la campagne",
        DO_YOU_CANCEL_CAMPAIGN: "Annulez-vous la campagne planifiée",
        DEACTIVATE_CAMPAIGN: "Désactiver la campagne",
        DO_YOU_DEACTIVATE_CAMPAIGN: "Désactivez-vous la campagne",
        ACTIVATE_CAMPAIGN: "Activer la campagne",
        DO_YOU_ACTIVATE_CAMPAIGN: "Activez-vous la campagne",
        SETUP: "Configuration",
        DESIGN: 'Design',
        SEGMENTS: 'Segments',
        SCHEDULE: "Planifier",
        CONFIRMATION: 'Confirmation',
        SELECT: "Sélectionner",
        ROOM_OFFERS: "Offres de la chambre",
        CAMPAIGNS_DESCR: "Campagnes visant à augmenter les revenus des chambres par le biais d'offres et de forfaits",
        EVENTS_ANNOUNCEMENTS: "Événements et annonces",
        NOTIFY_RECIPIENTS: "Informer les destinataires des événements spéciaux organisés dans la propriété",
        NEWSLETTER: 'Newsletter',
        CAMPAIGNS_UPDATE_DESCR: "Campagnes d'information sur les nouveautés ou les événements de l'hôtel, envoyées régulièrement",
        MISS_YOU: "Vous nous manquez",
        CAMPAGINS_SENT: "Campagnes envoyées aux clients après leur séjour pour les inciter à réserver à nouveau",
        OTHER: "Autre",
        CREATE_CUSTOM: "Créer une campagne personnalisée",
        PRE_ARRIVAL: "Avant l'arrivée",
        SEND_EMAIL: "Envoyer un e-mail aux clients avant leur arrivée",
        WELCOME: "Bienvenue",
        SEND_EMAIL_CHECK_IN: "Envoyer un e-mail aux clients lors de l'arrivée",
        QUALIFICATION: 'Qualification',
        SEND_EMAIL_SOON: "Envoyer un e-mail dès qu'un client pénètre dans un segment. Il ne recevra cet e-mail qu'une seule fois",
        SENT_TO_GUESTS: "Envoyé aux clients après leur séjour pour les encourager à revenir",
        OTA_WINBACK: "Winback OTA",
        SEND_CAMPAIGN: "Envoyer une campagne aux clients qui ont réservé auprès d'une agence de voyage en ligne dans les 30 jours suivant leur départ",
        BIRTHDAY: "Anniversaire",
        SEND_SPECIAL: "Envoyer une offre spéciale pour célébrer un anniversaire",
        DOUBLE_OPT: "Double consentement",
        AUTOMATICALLY_SEND: "Envoyer automatiquement un lien d'abonnement aux clients lorsqu'ils sont ajoutés à une liste de contacts",
        CAMPAIGN_NAME_HINT: "Donnez un nom interne à votre campagne afin de l'organiser et de la localiser facilement dans votre compte.",
        CAMPAIGN_NAME_REQUIRED: "Le nom de la campagne est obligatoire",
        SUBJECT_LINE: "Ligne d'objet",
        SUBJECT_LINE_HINT: "Rédigez un objet qui décrit clairement le contenu de votre e-mail. Il sera visible dans la boîte de réception de votre destinataire et sera le premier contenu qu'il verra.",
        SUBJECT_REQUIRED: "L'objet est obligatoire",
        PREVIEW_LINE: "Ligne de prévisualisation",
        PREVIEW_LINE_HINT: "Rédigez un texte court (environ 35 caractères) qui donne un aperçu de votre e-mail. Ce texte sera affiché dans la boîte de réception de votre destinataire, juste en dessous de l'objet.",
        FROM_EMAIL: "Adresse e-mail de l'expéditeur",
        FROM_EMAIL_HINT: "Choisissez l'adresse e-mail qui apparaîtra dans la boîte de réception de vos destinataires lorsqu'ils recevront votre campagne.",
        FROM_EMAIL_REQUIRED: "L'adresse e-mail de l'expéditeur est obligatoire",
        FROM_NAME: "Nom de l'expéditeur",
        FROM_NAME_HINT: "Saisissez un nom (par exemple le nom de votre entreprise) pour aider les destinataires de la campagne à vous reconnaître dans leur boîte de réception.",
        FROM_NAME_REQUIRED: "Le nom de l'expéditeur est obligatoire",
        MY_TEMPLATES: "Mes modèles",
        TEMPLATE_GALLERY: "Galerie de modèles",
        DESIGN_TOOLS: "Outils de conception",
        DRAG_DROP_EDITOR: "Éditeur par glisser-déposer",
        DRAG_DROP_EDITOR_DESC: "Créez un e-mail optimisé pour les mobiles qui s'affiche parfaitement sur n'importe quel appareil ou taille d'écran",
        RICH_TEXT_EDITOR: "Éditeur de texte enrichi",
        RICH_TEXT_EDITOR_DESC: "Utilisez l'éditeur de texte enrichi pour créer des e-mails simples",
        PASTE_YOUR_CODE: "Coller votre code",
        PASTE_YOUR_CODE_DESC: "Copiez et collez votre code HTML.",
        RESET_EMAIL_DESIGN: "Réinitialiser la conception de l'e-mail",
        EDIT_EMAIL_CONTENT: "Modifier le contenu de l'e-mail",
        LOAD_SAVED_VERSION: "Charger une version enregistrée",
        VERSION: 'Version',
        SAVE: "Enregistrer",
        SAVE_QUIT: "Enregistrer et quitter",
        QUIT_WITHOUT_SAVING: "Quitter sans enregistrer",
        HTML_EDITOR: "Éditeur HTML",
        ENTER_HTML: "Saisir un code HTML",
        CANCEL: "Annuler",
        CHOOSE_DIFFERENT_SEGMENT: "Choisir un autre segment",
        ADDITIONAL_OPTIONS: "Options supplémentaires",
        FILTER_PROPERTIES: "Filtrer les propriétés",
        ALL_PROPERTIES: "Toutes les propriétés",
        ENTER_PROPERTY_TO_FILTER: "Saisir la propriété pour filtrer...",
        ONLY_INCLUDE_GUESTS_WHO_HAVE: "N'inclure que les clients qui ont",
        RECEIVED: "reçu",
        NOT_RECEIVED: "non reçu",
        OPENED_LOWER: "ouvert",
        NOT_OPENED: "non ouvert",
        CLICKED_LOWER: "cliqué",
        NOT_CLICKED: "non cliqué",
        BEEN_SENT: "a été envoyé",
        NOT_SENT: "n'a pas été envoyé",
        ANY_EXISTING_CAMPAIGN_IN_LAST: "toute campagne existant au cours",
        DAY: "du dernier jour",
        WEEK: "de la semaine dernière",
        TWO_WEEKS: "des deux dernières semaines",
        MONTH: "du dernier mois",
        YEAR: "de la dernière année",
        TWO_YEARS: "des deux dernières années",
        ENABLE_SAFE_MODE: "Activer le mode sans échec",
        BEST_PRACTICE: "Bonnes pratiques RGPD",
        GUESTS_MUST_HAVE: "Les clients doivent avoir",
        IMPLICIT_CONSENT: "Consentement implicite",
        SAFE: "sûr",
        EXPLICIT_CONSENT: "Consentement explicite",
        SAFER: "sécurité renforcée",
        DOUBLE_OPT_IN_CONSENT: "Double consentement",
        SAFEST: "le plus sûr",
        EXCLUDE_EMAIL_FUTURE_STAYS: "Exclure des adresses e-mail des séjours futurs",
        REFRESH_STATISTICS: "Actualiser les statistiques",
        SELECT_SCHEDULE_OPTION: "Veuillez sélectionner une option de planification pour votre campagne :",
        SPECIFIC_DATE_TIME: "Une date et une heure précises",
        IMMEDIATELY: "Immédiatement",
        BASED_ON_GUEST_EVENT: "Basé sur l'événement d'un client",
        AT: "à",
        HH: 'HH',
        MM: 'MM',
        ON: "le",
        HOUR_REQUIRED: "L'heure est obligatoire",
        MINUTES_REQUIRED: "Les minutes sont obligatoires",
        SELECT_DATE: "Sélectionner une date",
        DATE_REQUIRED: "La date est obligatoire",
        DATE_MUST_BE_FUTURE: "La date doit se situer dans le futur",
        SELECT_DAYS: "Sélectionner les jours",
        DAYS_REQUIRED: "Les jours sont obligatoires",
        DAYS: "des derniers jours",
        SELECT_EVENT: "Sélectionner un événement",
        EVENT_REQUIRED: "L'événement est obligatoire",
        SELECT_LIST: "Sélectionner une liste",
        LIST_REQUIRED: "La liste est obligatoire",
        CAMPAIGN_READY: "Votre campagne est prête à être envoyée !",
        REVIEW_REPORT_BEFORE_SENDING: "Consultez le rapport ci-dessous avant d'envoyer votre campagne",
        SUBJECT: "Objet",
        FROM: "De",
        ROOM_REVENUE_TRACKING: "Suivi des revenus de la chambre",
        DOUBLE_OPT_IN_URL: "URL du double consentement",
        DOUBLE_OPT_IN_URL_HINT: "Il s'agit de l'URL vers laquelle l'utilisateur sera redirigé lors d'un flux de double consentement.",
        DOUBLE_OPT_IN_URL_REQUIRED: "Une URL de double consentement est obligatoire",
        VALID_URL_REQUIRED: "Veuillez saisir une adresse URL valide",
        YOUR_DESIGN: "Votre conception",
        USED: "Vous avez utilisé le",
        CREATE_DESIGN: "pour créer votre design",
        EDIT_CONTENT: "Modifier le contenu",
        ADDITIONAL_FILTERS: "Filtres supplémentaires",
        SEND_IMMEDIATELY: "Envoyer immédiatement",
        SEND_TEST_EMAIL: "Envoyer l'e-mail de test",
        ENTER_EMAILS: "Saisir des e-mails",
        ENTER_EMAILS_DESC: "Saisissez une liste d'adresses e-mail, une par ligne",
        SEND_TEST: "Envoyer le test",
        SCHEDULE_CAMPAIGN: "Planifier la campagne",
        SURE_DELIVERY: "Voulez-vous vraiment planifier la distribution de votre campagne ?",
        CAMPAIGN_IN_LAST: " toute campagne au cours ",
        SEARCH_TEMPLATE_NAME: "Recherche par nom de modèle",
        CREATE_TEMPLATE: "Créer un modèle",
        EDIT_CAMPAIGN: "Modifier la campagne",
        TEMPLATE_NAME: "Nom du modèle",
        EDIT_TEMPLATE: "Modifier le modèle",
        MORE_OPTIONS: "Plus d'options",
        TEMPLATE_NAME_HINT: "Donnez un nom interne à votre modèle afin de l'organiser et de le localiser facilement dans votre compte.",
        TEMPLATE_NAME_REQUIRED: "Le nom du modèle est obligatoire",
        LIST_SELECTED: "Liste sélectionnée",
        LISTS_SELECTED: " Listes sélectionnées",
        ALL_LISTS_SELECTED: "Toutes les listes sélectionnées",
        BEFORE_CHECK_IN: "avant l'arrivée",
        AFTER_CHECK_IN: "après l'arrivée",
        BEFORE_CHECK_OUT: "avant le départ",
        AFTER_LAST_CHECK_OUT: "après le dernier départ",
        ON_CHECK_OUT: "au départ",
        ON_CHECK_IN: "à l'arrivée",
        ON_DAY_OF_CHECK_IN: "le jour de l'arrivée",
        BEFORE_BIRTHDAY: "avant l'anniversaire",
        BEFORE_ANNIVERSARY: "avant la célébration",
        ON_LIST_JOINED: "lors de l'inscription sur la liste",
        AT_SPASE: "à ",
        UNLAYER_LANGUAGE: 'en-US',
        EDITOR_LANGUAGE: 'en'
      }
    },
    MENU: {
      NEW: "nouveau",
      ACTIONS: 'Actions',
      CREATE_POST: "Créer une nouvelle publication",
      PAGES: 'Pages',
      FEATURES: "Fonctionnalités",
      APPS: "Applications",
      HOME: "Accueil"
    },
    AUTH: {
      GENERAL: {
        OR: "Ou",
        SUBMIT_BUTTON: "Envoyer",
        NO_ACCOUNT: "Vous n'avez pas de compte ?",
        SIGNUP_BUTTON: "S'inscrire",
        FORGOT_BUTTON: "Mot de passe oublié",
        BACK_BUTTON: "Retour",
        PRIVACY: "Confidentialité",
        LEGAL: "Juridique",
        CONTACT: 'Contact'
      },
      LOGIN: {
        TITLE: "Compte de connexion",
        BUTTON: "Se connecter"
      },
      FORGOT: {
        TITLE: "Mot de passe oublié ?",
        DESC: "Saisissez votre e-mail pour réinitialiser votre mot de passe",
        SUCCESS: "Votre compte a été réinitialisé avec succès."
      },
      REGISTER: {
        TITLE: "S'inscrire",
        DESC: "Saisissez vos informations pour créer votre compte",
        SUCCESS: "Votre compte a été enregistré avec succès."
      },
      INPUT: {
        EMAIL: 'E-mail',
        FULLNAME: "Nom",
        PASSWORD: "Mot de passe",
        CONFIRM_PASSWORD: "Confirmer le mot de passe",
        USERNAME: "Nom d'utilisateur"
      },
      VALIDATION: {
        INVALID: "{{name}} n'est pas valide",
        REQUIRED: "{{name}} est obligatoire",
        MIN_LENGTH: "La longueur minimale de {{name}} est de {{min}}",
        AGREEMENT_REQUIRED: "L'acceptation des conditions générales est obligatoire",
        NOT_FOUND: "Aucun résultat pour {{name}}",
        INVALID_LOGIN: "Les informations de connexion sont incorrectes",
        REQUIRED_FIELD: "Champ obligatoire",
        MIN_LENGTH_FIELD: "Longueur minimale du champ :",
        MAX_LENGTH_FIELD: "Longueur maximale du champ :",
        INVALID_FIELD: "Le champ n'est pas valide"
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés : ",
        ALL: "Tout",
        SUSPENDED: "Suspendu",
        ACTIVE: "Actif",
        FILTER: "Filtrer",
        BY_STATUS: "par le statut",
        BY_TYPE: "par type",
        BUSINESS: "Entreprise",
        INDIVIDUAL: "Particulier",
        SEARCH: "Rechercher",
        IN_ALL_FIELDS: "dans tous les champs"
      },
      ECOMMERCE: "e-commerce",
      CUSTOMERS: {
        CUSTOMERS: "Clients",
        CUSTOMERS_LIST: "Liste de clients",
        NEW_CUSTOMER: "Nouveau client",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Supprimer un client",
          DESCRIPTION: "Voulez-vous vraiment supprimer définitivement ce client ?",
          WAIT_DESCRIPTION: "Suppression du client...",
          MESSAGE: "Le client a été supprimé"
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Suppression des clients",
          DESCRIPTION: "Voulez-vous vraiment supprimer définitivement les clients sélectionnés ?",
          WAIT_DESCRIPTION: "Suppression des clients...",
          MESSAGE: "Les clients sélectionnés ont été supprimés"
        },
        UPDATE_STATUS: {
          TITLE: "Le statut a été mis à jour pour les clients sélectionnés",
          MESSAGE: "Les statuts des clients sélectionnés ont été mis à jour avec succès"
        },
        EDIT: {
          UPDATE_MESSAGE: "Le client a été mis à jour",
          ADD_MESSAGE: "Le client a été créé"
        }
      }
    },
    Jan: 'Jan',
    Feb: "Fév",
    Mar: "Mars",
    Apr: "Avr",
    May: "Mai",
    Jun: "Juin",
    Jul: "Juillet",
    Aug: "Août",
    Sep: 'Sept',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: "Déc",
    Clicks: "Clics",
    Opens: "Ouvertures",
    '< 7 days': "< 7 jours",
    '7-14 Days': "7-14 jours",
    '15-30 Days': "15-30 jours",
    '31-60 Days': "31-60 jours",
    '61-90 Days': "61-90 jours",
    '> 90 Days': "> 90 jours",
    '< 1 month': "< 1 mois",
    '1-2 Months': "1-2 mois",
    '2-6 Months': "2-6 mois",
    '6-12 Months': "6-12 mois",
    '> 1 Year': "> 1 année",
    Sent: "Envoyé",
    Delivered: "Distribué",
    Open: "Ouvrir",
    Spam: 'Spam',
    Click: "Clic",
    HardBounce: "Échec permanent de l'envoi",
    SoftBounce: "Échec temporaire de l'envoi",
    Unsubscribe: "Se désabonner",
    InvalidEmail: "E-mail non valide",
    Blocked: "Bloqué",
    Complaint: "Réclamation",
    Deferred: "Différé",
    ListAddition: "Ajout à la liste",
    Overview: "Aperçu",
    "Guest List": "Liste des clients",
    "Guest Lists": "Listes des clients",
    "Guest Segments": "Segments de clients",
    Campaigns: "Campagnes",
    Templates: "Modèles"
  }
};
