	import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of} from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { ProfileBasicList } from '../_models/crm/profile-basic-list.model';
import { ProfileFull } from '../_models/crm/profile-full.model';
import { ProfileStatus } from '../_models/crm/profile-status.enum';
import { SegmentFull } from '../_models/crm/segment-full.model';
import { SegmentBasic } from '../_models/crm/segment-basic.model';
import { SegmentStatistic } from '../_models/crm/segment-statistic.model';
import { SortOrder } from '../_models/sort-order.enum';
import { CrmCredit } from '../_models/crm/crm-credit.model';
import { CrmList } from '../_models/crm/crm-list.model';
import { CrmSender } from '../_models/crm/crm-sender.model';
import { HotelCrmSetting } from '../_models/crm/hotel-crm-setting.model';
import { SubscriptionStatus } from '../_models/crm/subscription-status.enum';
import { CampaignIncludeType } from '../_models/crm/campaign/campaign-include-type.enum';
import { CampaignIncludeDurationType } from '../_models/crm/campaign/campaign-include-duration-type.enum';
import { CampaignFullStatistic } from '../_models/crm/statistics/campaign-full-statistic.model';
import { CampaignStatisticChannel } from '../_models/crm/statistics/campaign-statistic-channel.model';
import { CampaignStatisticSegment } from '../_models/crm/statistics/campaign-statistic-segment.model';
import { CsvImport } from '../_models/crm/import/csv-import.model';
import { CsvImportConfirm } from '../_models/crm/import/csv-import-confirm.model';
import { ProfileCampaign } from '../_models/crm/statistics/profile-campaign.model';
import { PagedResult } from '../_models/paged-result.model';
import { DatePeriod } from '../_models/date-period.enum';
import { CompanyGuestSummary } from '../_models';

@Injectable({
	providedIn: 'root'
})
export class CrmService {

	constructor(private httpClient: HttpClient) { }

	getStatistics(hotelIds: number[] = null, dateFrom: Date, dateTo: Date, campaignIds: number[] = null, groupCampaign = false, rateCode = null, sourceOfBusiness = null, datePeriod = DatePeriod.Daily): Observable<CampaignFullStatistic[]> {

		let params = new HttpParams();

		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}

		if (campaignIds && campaignIds.length > 0) {
			campaignIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('campaignIds', id.toString());
				}
			});
		}
		if (dateFrom && dateTo) {
			params = params.set('dateFrom', formatDate(dateFrom, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			params = params.set('dateTo', formatDate(dateTo, 'yyyy-MM-dd', 'en-GB', 'UTC'));
		}
		if (groupCampaign) {
			params = params.append('groupCampaign', "true");
		}
		if (rateCode) {
			params = params.append('rateCode', rateCode);
		}
		if (sourceOfBusiness) {
			params = params.append('sourceOfBusiness', sourceOfBusiness);
		}
		if (datePeriod) {
			params = params.append('datePeriod', datePeriod.toString());
		}
		let apiUrl = `${environment.api.crm}/report`;
		return this.httpClient.get<CampaignFullStatistic[]>(apiUrl, { params }).pipe(retry(2));
	}

	getStatisticsSegments(hotelIds: number[] = null, dateFrom: Date, dateTo: Date, campaignIds: number[] = null, rateCode = null, sourceOfBusiness = null): Observable<CampaignStatisticSegment[]> {

		let params = new HttpParams();
		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}

		if (campaignIds && campaignIds.length > 0) {
			campaignIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('campaignIds', id.toString());
				}
			});
		}
		if (dateFrom && dateTo) {
			params = params.set('dateFrom', formatDate(dateFrom, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			params = params.set('dateTo', formatDate(dateTo, 'yyyy-MM-dd', 'en-GB', 'UTC'));
		}
		if (rateCode) {
			params = params.append('rateCode', rateCode);
		}
		if (sourceOfBusiness) {
			params = params.append('sourceOfBusiness', sourceOfBusiness);
		}
		let apiUrl = `${environment.api.crm}/report/segments`;
		return this.httpClient.get<CampaignStatisticSegment[]>(apiUrl, { params }).pipe(retry(2));
	}

	getStatisticsChannels(hotelIds: number[] = null, dateFrom: Date, dateTo: Date, campaignIds: number[] = null, rateCode = null, sourceOfBusiness = null): Observable<CampaignStatisticChannel[]> {

		let params = new HttpParams();
		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}

		if (campaignIds && campaignIds.length > 0) {
			campaignIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('campaignIds', id.toString());
				}
			});
		}
		if (dateFrom && dateTo) {
			params = params.set('dateFrom', formatDate(dateFrom, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			params = params.set('dateTo', formatDate(dateTo, 'yyyy-MM-dd', 'en-GB', 'UTC'));
		}
		if (rateCode) {
			params = params.append('rateCode', rateCode);
		}
		if (sourceOfBusiness) {
			params = params.append('sourceOfBusiness', sourceOfBusiness);
		}
		let apiUrl = `${environment.api.crm}/report/channels`;
		return this.httpClient.get<CampaignStatisticChannel[]>(apiUrl, { params }).pipe(retry(2));
	}

	public getCompanyGuestSummary(
		hotelIds: number[]
	): Observable<CompanyGuestSummary> {
		return this.httpClient
			.get<CompanyGuestSummary>(
				`${environment.api.crm}/report/companyGuestSummary`,
				{
					params: {
						hotelIds,
					},
				}
			)
			.pipe(retry(2));
	}

	getProfileList(hotelIds: number[], searchText: string, dateFrom: Date, dateTo: Date, profileStatus: ProfileStatus, pageNumber: number, pageSize: number, sortField: string, sortOrder: SortOrder): Observable<ProfileBasicList> {

		let params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString());

		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}
		if (dateFrom && dateTo) {
			params = params.set('dateFrom', formatDate(dateFrom, 'yyyy-MM-dd', 'en-GB', 'UTC'))
			params = params.set('dateTo', formatDate(dateTo, 'yyyy-MM-dd', 'en-GB', 'UTC'));
		}
		if (searchText) {
			params = params.set('searchText', searchText)
		}
		if (profileStatus) {
			params = params.set('profileStatus', profileStatus.toString())
		}
		if (sortField) {
			params = params.set('sortField', sortField)
		}
		if (sortOrder) {
			params = params.set('sortOrder', sortOrder.toString())
		}

		let apiUrl = `${environment.api.crm}/guest`;
		return this.httpClient.get<ProfileBasicList>(apiUrl, { params }).pipe(retry(2));
	}

	getProfileDetail(profileId: number): Observable<ProfileFull> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}`;
		return this.httpClient.get<ProfileFull>(apiUrl).pipe(retry(2));
	}

	getProfileCampaigns(profileId: number): Observable<ProfileCampaign[]> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}/campaigns`;
		return this.httpClient.get<ProfileCampaign[]>(apiUrl);
	}

	getBadges(): Observable<string[]> {
		let apiUrl = `${environment.api.crm}/badge`;
		return this.httpClient.get<string[]>(apiUrl).pipe(retry(2));
	}

	addBadge(profileId: number, badge: string): Observable<any> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}/badge?badge=${badge}`;
		return this.httpClient.post(apiUrl, null);
	}

	deleteBadge(profileId: number, badge: string): Observable<any> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}/badge?badge=${badge}`;
		return this.httpClient.delete(apiUrl);
	}

	getLists(): Observable<CrmList[]> {
		let apiUrl = `${environment.api.crm}/list`;
		return this.httpClient.get<CrmList[]>(apiUrl);
	}

	getCredits(): Observable<CrmCredit> {
		let apiUrl = `${environment.api.crm}/credit`;
		return this.httpClient.get<CrmCredit>(apiUrl);
	}

	getSenders(hotelIds: number[] = null, pageNumber: number, pageSize: number): Observable<PagedResult<CrmSender>> {

		let params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString());

		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}

		let apiUrl = `${environment.api.crm}/sender`;
		return this.httpClient.get<PagedResult<CrmSender>>(apiUrl, { params });
	}

	insertSender(crmSender: CrmSender): Observable<number> {
		let apiUrl = `${environment.api.crm}/sender`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post<number>(apiUrl, crmSender, httpOptions);
	}

	updateSender(crmSender: CrmSender): Observable<void> {
		let apiUrl = `${environment.api.crm}/sender`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.put<void>(apiUrl, crmSender, httpOptions);
	}

	deleteSender(crmSenderId: number): Observable<void> {
		let apiUrl = `${environment.api.crm}/sender?crmSenderId=${crmSenderId}`;
		return this.httpClient.delete<void>(apiUrl);
	}

	sendVerifySender(crmSenderId: number): Observable<number> {
		let apiUrl = `${environment.api.crm}/sender/verify?crmSenderId=${crmSenderId}`;
		return this.httpClient.post<number>(apiUrl,null);
	}

	sendVerifyCodeSender(crmSenderId: number, verifyCode: string): Observable<void> {
		let apiUrl = `${environment.api.crm}/sender/verify?crmSenderId=${crmSenderId}&verifyCode=${verifyCode}`;
		return this.httpClient.put<void>(apiUrl, null);
	}

	getListsSearch(hotelIds: number[] = null, pageNumber: number, pageSize: number, sortField: string, sortOrder: SortOrder): Observable<PagedResult<CrmList>> {

		let params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString());

		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}

		if (sortField) {
			params = params.set('sortField', sortField)
		}
		if (sortOrder) {
			params = params.set('sortOrder', sortOrder.toString())
		}

		let apiUrl = `${environment.api.crm}/list/search`;
		return this.httpClient.get<PagedResult<CrmList>>(apiUrl, { params }).pipe(retry(2));
	}

	insertList(crmList: CrmList): Observable<number> {
		let apiUrl = `${environment.api.crm}/list`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post<number>(apiUrl, crmList, httpOptions);
	}

	updateList(crmList: CrmList): Observable<number> {
		let apiUrl = `${environment.api.crm}/list`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.put<number>(apiUrl, crmList, httpOptions);
	}

	getOptionLists(): Observable<{ [key: string]: string }> {
		let apiUrl = `${environment.api.crm}/segment/options`;
		return this.httpClient.get<{ [key: string]: string }>(apiUrl);
	}

	getSegments(update = false, hotelIds: number[] = null): Observable<SegmentFull[]> {
		let params = new HttpParams();
		if (update)
			params = params.set('update', 'true');

		if (hotelIds && hotelIds.length > 0) {
			hotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('hotelIds', id.toString());
				}
			});
		}

		let apiUrl = `${environment.api.crm}/segment`;
		return this.httpClient.get<SegmentFull[]>(apiUrl, {params});
	}

	insertSegment(segment: SegmentBasic): Observable<SegmentFull> {
		let apiUrl = `${environment.api.crm}/segment`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post<SegmentFull>(apiUrl, segment, httpOptions);
	}

	updateSegment(segment: SegmentBasic): Observable<SegmentFull> {
		let apiUrl = `${environment.api.crm}/segment`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.put<SegmentFull>(apiUrl, segment, httpOptions);
	}

	deleteSegment(segmentId: number): Observable<any> {
		let apiUrl = `${environment.api.crm}/segment?segmentid=${segmentId}`;
		return this.httpClient.delete(apiUrl);
	}

	unsubscribeProfile(profileId: number): Observable<any> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}/unsubscribe`;
		return this.httpClient.post(apiUrl, null);
	}

	deleteProfile(profileId: number): Observable<any> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}`;
		return this.httpClient.delete(apiUrl);
	}

	updateProfileEmail(profileId: number, email: string): Observable<any> {
		let apiUrl = `${environment.api.crm}/guest/${profileId}/email?email=${email}`;
		return this.httpClient.put(apiUrl, null);
	}

	getStatistic(segment: SegmentBasic): Observable<SegmentStatistic> {
		let apiUrl = `${environment.api.crm}/segment/statistic`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post<SegmentStatistic>(apiUrl, segment, httpOptions);
	}

	getStatisticByHotel(filterHotelIds: number[] = null): Observable<SegmentStatistic> {
		let apiUrl = `${environment.api.crm}/segment/hotelstatistic`;

		let params = new HttpParams();
		if (filterHotelIds && filterHotelIds.length > 0) {
			filterHotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('filterHotelIds', id.toString());
				}
			});
		}
		return this.httpClient.get<SegmentStatistic>(apiUrl, { params });
	}

	getStatisticById(segmentId: number, excludeFutureStays: boolean = false, minSubscriptionStatus: SubscriptionStatus = null, filterHotelIds: number[] = null, campaignInclude: CampaignIncludeType = null, campaignIncludeDuration: CampaignIncludeDurationType = null): Observable<SegmentStatistic> {
		let apiUrl = `${environment.api.crm}/segment/${segmentId}/statistic`;

		let params = new HttpParams()
			.set('excludeFutureStays', excludeFutureStays ? "true" : "false");

		if (minSubscriptionStatus)
			params = params.set('minSubscriptionStatus', minSubscriptionStatus.toString());
		if (campaignInclude && (campaignIncludeDuration!==null)) {
			params = params.set('campaignInclude', campaignInclude.toString());
			params = params.set('campaignIncludeDuration', campaignIncludeDuration.toString());
		}

		if (filterHotelIds && filterHotelIds.length > 0) {
			filterHotelIds.forEach(id => {
				if ((id != null) && (Number.isInteger(+id))) {
					params = params.append('filterHotelIds', id.toString());
				}
			});
		}
		return this.httpClient.get<SegmentStatistic>(apiUrl, {params});
	}

	getSegmentProfiles(segmentId: number, searchText: string, pageNumber: number, pageSize: number, sortField: string, sortOrder: SortOrder): Observable<ProfileBasicList> {

		let params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString());


		if (searchText) {
			params = params.set('searchText', searchText)
		}
		if (sortField) {
			params = params.set('sortField', sortField)
		}
		if (sortOrder) {
			params = params.set('sortOrder', sortOrder.toString())
		}

		let apiUrl = `${environment.api.crm}/segment/${segmentId}/profile`;
		return this.httpClient.get<ProfileBasicList>(apiUrl, { params }).pipe(retry(2));
	}

	uploadContacts(listId: number, file: File): Observable<CsvImport> {
		let apiUrl = `${environment.api.crm}/list/${listId}/import`;
		const formData = new FormData();
		formData.append('formFile', file, file.name);
		return this.httpClient.post<CsvImport>(apiUrl, formData);
	}

	uploadContactsStepTwo(listId:number, subscriptionStatus: SubscriptionStatus, csvImport: CsvImportConfirm): Observable<void> {
		let apiUrl = `${environment.api.crm}/list/${listId}/import2?status=${subscriptionStatus.toString()}`;
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return this.httpClient.post<void>(apiUrl, csvImport, httpOptions);
	}

	getHotelCrmSetting(hotelId: number) {
		const apiUrl = `${environment.api.crm}/hotel/${hotelId}/setting`;
		return this.httpClient.get<HotelCrmSetting>(apiUrl);
	}

	updateHotelCrmSetting(hotelCrmSetting: HotelCrmSetting) {
		const apiUrl = `${environment.api.crm}/hotel/${hotelCrmSetting.hotelId}/setting`;
		return this.httpClient.put<any>(apiUrl, hotelCrmSetting);
	}

	uploadDefaultImage(hotelId: number, file: File): Observable<any> {
		let apiUrl = `${environment.api.crm}/hotel/${hotelId}/setting/DefaultImage`;
		const formData = new FormData();
		formData.append('formFile', file, file.name);

		return this.httpClient.post(apiUrl, formData);
	}

	deleteDefaultImage(hotelId: number): Observable<any> {
		let apiUrl = `${environment.api.crm}/hotel/${hotelId}/setting/DefaultImage`;
		return this.httpClient.delete(apiUrl);
	}

	uploadLogo(hotelId: number, file: File): Observable<any> {
		let apiUrl = `${environment.api.crm}/hotel/${hotelId}/setting/Logo`;
		const formData = new FormData();
		formData.append('formFile', file, file.name);

		return this.httpClient.post(apiUrl, formData);
	}

	deleteLogo(hotelId: number): Observable<any> {
		let apiUrl = `${environment.api.crm}/hotel/${hotelId}/setting/Logo`;
		return this.httpClient.delete(apiUrl);
	}
}
